// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { RefObject, useEffect, useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components/macro"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { useActiveSpeakerContext } from "../context/ActiveSpeakerContext"
import Size from "../enums/Size"
import VideoNameplate from "./VideoNameplate"

const RemoteVideoRoot = styled.div<{ isSpeaking: boolean }>`
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 100%;
    max-height: 100%;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: ${(props) => props.isSpeaking && "inset 0 0 0 3px #00f"};
    border: 1px solid #fff;
    color: #fff;
    background: ${branding.videoBackground ?? "#000"};
    margin-right: -1px;

    & video {
        width: 100%;
        height: 100%;
    }

    &:hover > div {
        opacity: 1;
    }

    @media (max-width: 1480px) {
        font-size: 16px;
    }
`
const Name = styled.div`
    text-align: center;
    margin-top: 15px;
    padding: 0 15px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 22px;

    & svg {
        margin-top: -2px;
    }
`

const Title = styled(Name)`
    font-size: 0.9em;
    margin-top: 10px;
`

const AvatarWithDefaultStyled = styled(AvatarWithDefault)<{ isSpeaking?: boolean }>`
    border: 2px solid ${(props) => (props.isSpeaking ? "#00f" : "#000")};
    @media (max-width: 1480px) {
        width: 65px;
        height: 65px;
        font-size: 20px;
        line-height: 65px;
    }
`

type Props = {
    enabled?: boolean
    videoElementRef?: RefObject<HTMLVideoElement>
    size?: Size
    attendeeId: null | string
    attendeeName?: string
    attendeeImg?: string
    attendeeCompany?: string
    attendeePosition?: string
    avatarSize?: number
    isMeetingPage?: boolean
}

export default function RemoteVideo(props: Props) {
    const strings = useLanguageState().getStrings()
    const activeSpeakerContext = useActiveSpeakerContext()
    const history = useHistory()
    const [isMeetingRoom, setIsMeetingRoom] = useState<any>(history.location.pathname.includes("/meeting/"))

    useEffect(() => {
        setIsMeetingRoom(history.location.pathname.includes("/meeting/"))
    }, [history])

    const { videoElementRef, attendeeId, attendeeName, attendeeImg, attendeePosition, attendeeCompany, avatarSize } = props

    const personPosition: string = [attendeePosition, attendeeCompany]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")
    const isSpeaking = activeSpeakerContext.isActiveSpeaker(attendeeId)
    return (
        // If the the remote user is speaking and we are in the meeting room,
        // show a blue border around the remote users tile
        <RemoteVideoRoot isSpeaking={isSpeaking && isMeetingRoom}>
            {videoElementRef && (
                <video style={{ width: isMeetingRoom ? "calc(100% - 8px)" : "100%", objectFit: isMeetingRoom? "contain" : "cover" }} muted ref={videoElementRef} />
            )}
            {videoElementRef && attendeeId && <VideoNameplate attendeeId={attendeeId} />}
            {!videoElementRef && attendeeName && (
                <>
                    <AvatarWithDefaultStyled
                        alt={attendeeName}
                        size={avatarSize ? avatarSize : 100}
                        src={attendeeImg}
                        backgroundSize="cover"
                    />
                    <Name>{attendeeName}</Name>
                    <Title>{personPosition}</Title>
                </>
            )}
        </RemoteVideoRoot>
    )
}
