import { SyntheticEvent, useState } from "react"
import useWindowDimensions from "../../ui/WindowDimensionsHook"

const CustomLogoImage = () => {
    const logo = process.env.PUBLIC_URL + "/branding/logo.png"

    const [height, setHeight] = useState(200)
    const [width, setWidth] = useState(300)
    const { isMobile } = useWindowDimensions()

    const onImgLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        // example corussoft logo dimensions: 600 x 116px
        const mobileSmushifier = isMobile ? 0.4 : 1
        const target = event.target as HTMLImageElement
        // preserve original image ratio
        if (target.naturalHeight > target.naturalWidth) {
            // portrait format logo
            const ratio = target.naturalWidth / target.naturalHeight // 600 / 116
            const customHeight = target.parentElement?.clientHeight ? target.parentElement?.clientHeight * 0.6 : 100
            setHeight(customHeight * mobileSmushifier)
            setWidth(customHeight * ratio * mobileSmushifier)
        } else {
            // landscape format logo
            const ratio = target.naturalHeight / target.naturalWidth // 116 / 600
            const customWidth = target.parentElement?.clientWidth ? target.parentElement?.clientWidth * 0.8 : 300
            setHeight(customWidth * ratio * mobileSmushifier)
            setWidth(customWidth * mobileSmushifier)
        }
    }

    return (
        <img
            src={logo}
            onLoad={onImgLoad}
            className="vjs-button vjs-logo"
            alt="Logo"
            style={{
                width: width,
                height: height
            }}
        ></img>
    )
}

export default CustomLogoImage
