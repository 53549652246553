import { useLoggedInState } from "./LoggedInUser"
import { createState, State, useState } from "@hookstate/core"
import {
    getUserRestrictedAreaAccess,
    RestrictedAreaTypes,
    UserRestrictedAreaAccess,
    getAccessStatusForAllEventDates,
    sendRequestForRestrictedAreaAccess,
    RestrictedAreaType,
    getAccessStatusForAllVirtualCafes,
    getAccessStatusForAllConferenceRooms
} from "../backendServices/RestrictedAreaServices"
import {
    AccessProvider,
    ConferenceRoom,
    EventDateBase,
    RestrictedArea,
    UserConferenceRoomAccessType
} from "../backendServices/Types"
import { MeetingRoomGroupType } from "../conference/AudioVideoBranding"
import _ from "lodash"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"

const DEBOUNCE_MS = 10 * 1000 // 10 seconds

export enum RestrictedAreaAccessType {
    "GRANTED" = "GRANTED",
    "REQUESTED" = "REQUESTED",
    "UNAUTHORIZED" = "UNAUTHORIZED"
}

export interface UserRestrictedAreaAccessContext {
    requestAccess: (restrictedArea: RestrictedArea, requestAccessMessage: string) => void
    isLoaded: (restrictedArea: RestrictedArea | null) => boolean
    isUnlocked: (restrictedArea: RestrictedArea | null | undefined) => boolean // for example for locking components like VideoPlayer (BACKOFFICE or TICKET)
    getStatus: (restrictedArea: RestrictedArea) => RestrictedAreaAccessType // for example for making button state signify that request was sent (BACKOFFICE)
    // userRestrictedAreaAccessList?: UserRestrictedAreaAccess[]  // only expose this, if absolutely necessary
    fetchUserAccessForSingleRestrictedArea: (restrictedArea: RestrictedArea) => void
    fetchUserAccessForAllEventDates: () => void
    fetchUserAccessForAllVirtualCafes: () => void
    fetchUserAccessForAllConferenceRooms: () => void
    fetchUserAccessForAllRestrictedAreaTypes: () => void
}

export interface UserRestrictedAreaAccessState {
    isLoadedArray: string[] // keeps track of which eventDateAccesses have been loaded yet
    userRestrictedAreaAccessList: UserRestrictedAreaAccess[] // if an access is in this list, access is either requested or granted,
    // public event dates and event dates where no access has been requested yet are not in this list
}

const state = createState<UserRestrictedAreaAccessState>({
    isLoadedArray: [],
    userRestrictedAreaAccessList: []
})

const checkIsPublic = (restrictedArea: RestrictedArea) => {
    let isPublic = false
    if (
        (restrictedArea as EventDateBase).accessProvider === AccessProvider.PUBLIC ||
        (restrictedArea as MeetingRoomGroupType).isPrivate === false ||
        (restrictedArea as ConferenceRoom).accessType === UserConferenceRoomAccessType.PUBLIC
    ) {
        isPublic = true
    }
    return isPublic
}

function addTypePrefixToId(restrictedArea: RestrictedArea | null): string
function addTypePrefixToId(restrictedArea: RestrictedArea | null, restrictedAreaId: string, restrictedAreaType: string): string
function addTypePrefixToId(
    restrictedArea: RestrictedArea | null,
    restrictedAreaId?: string,
    restrictedAreaType?: string
): string {
    let idWithPrefix = ""
    if (restrictedAreaId && restrictedAreaType) {
        // mostly for UserRestrictedAreaAccess from backend response
        switch (restrictedAreaType) {
            case "privateEvent":
                idWithPrefix = "pe_" + restrictedAreaId
                break
            case "virtualCafe":
                idWithPrefix = "vc_" + restrictedAreaId
                break
            case "conferenceroom":
                idWithPrefix = "cr_" + restrictedAreaId
                break
            default:
                idWithPrefix = restrictedAreaId
        }
    } else if (restrictedArea) {
        // mostly for RestrictedArea that has previously been loaded into the frontend in another component
        switch (determineType(restrictedArea)) {
            case RestrictedAreaTypes.PrivateEvent:
                idWithPrefix = "pe_" + restrictedArea.id
                break
            case RestrictedAreaTypes.VirtualCafe:
                idWithPrefix = "vc_" + restrictedArea.id
                break
            case RestrictedAreaTypes.ConferenceRoom:
                idWithPrefix = "cr_" + restrictedArea.id
                break
        }
    }
    return idWithPrefix
}

const useStateWrapper = (state: State<UserRestrictedAreaAccessState>) => {
    const loggedInUser = useLoggedInState().user()

    const checkStaffAccess = (restrictedArea: RestrictedArea) => {
        let organizationId: string | undefined = ""
        switch (determineType(restrictedArea)) {
            case RestrictedAreaTypes.PrivateEvent:
                organizationId = (restrictedArea as EventDateBase).organizationId
                break
            case RestrictedAreaTypes.VirtualCafe:
                organizationId = (restrictedArea as MeetingRoomGroupType).organizationId
                break
            case RestrictedAreaTypes.ConferenceRoom:
                organizationId = (restrictedArea as ConferenceRoom).organization
                break
        }
        return (loggedInUser?.organizations?.find((o) => o.id === organizationId) !== undefined)!
    }

    const checkUserEventDates = (eventDateId: string) => {
        return (loggedInUser?.eventDates?.find((o) => o.id === eventDateId) !== undefined)!
    }

    function checkAccessInList(accessFromList: UserRestrictedAreaAccess, restrictedAreaToCheck: RestrictedArea): boolean {
        let type: string = determineType(restrictedAreaToCheck).value
        return accessFromList.restrictedAreaId === restrictedAreaToCheck.id && accessFromList.restrictedAreaType === type
    }

    const checkUserHasExplicitAccess = (restrictedArea: RestrictedArea) => {
        // possible values for type: "virtualCafe", "privateEvent", "conferenceroom"
        const foundAccess = state.userRestrictedAreaAccessList.value?.find((access) => checkAccessInList(access, restrictedArea))
        const hasAccess = foundAccess?.status === "GRANTED"
        return hasAccess
    }

    function setLoaded(isLoaded: boolean, restrictedArea: RestrictedArea, prevState: UserRestrictedAreaAccessState): void {
        const restrictedAreaId = addTypePrefixToId(restrictedArea)
        if (isLoaded) {
            // setLoaded(true)
            if (!prevState.isLoadedArray.includes(restrictedAreaId)) {
                prevState.isLoadedArray.push(restrictedAreaId)
            }
        } else {
            // setLoaded(false)
            const index = prevState.isLoadedArray.indexOf(restrictedAreaId)
            if (index > -1) {
                prevState.isLoadedArray.splice(index, 1) // delete element at index
            }
        }
    }

    // userRestrictedAreaAccessList: if an access is in this list, it is considered either requested or granted, public events are not in this list
    function setAccess(
        restrictedArea: RestrictedArea,
        prevState: UserRestrictedAreaAccessState,
        userAccess: UserRestrictedAreaAccess | undefined | null
    ): void {
        if (userAccess === undefined) return

        const index = prevState.userRestrictedAreaAccessList.findIndex((access) => checkAccessInList(access, restrictedArea))
        // if found
        if (index !== -1) {
            // if userAccess not null, update element at index
            if (userAccess) prevState.userRestrictedAreaAccessList[index] = userAccess
            // else delete one element at index
            else prevState.userRestrictedAreaAccessList.splice(index, 1)
        } else {
            if (userAccess) prevState.userRestrictedAreaAccessList.push(userAccess) // add element
        }
    }

    function mergeArraysWithoutDuplicates(newArray: string[], oldArray: string[]): string[] {
        let array = newArray.concat(oldArray)
        for (let i = 0; i < array.length; ++i) {
            for (let j = i + 1; j < array.length; ++j) {
                if (array[i] === array[j]) array.splice(j--, 1)
            }
        }
        return array
    }

    function mergeAccessListsWithoutDuplicates(
        newArray: UserRestrictedAreaAccess[],
        oldArray: UserRestrictedAreaAccess[]
    ): UserRestrictedAreaAccess[] {
        let array = newArray.concat(oldArray)
        for (let i = 0; i < array.length; ++i) {
            for (let j = i + 1; j < array.length; ++j) {
                if (
                    array[i].restrictedAreaId === array[j].restrictedAreaId &&
                    array[i].restrictedAreaType === array[j].restrictedAreaType
                ) {
                    array.splice(j--, 1)
                }
            }
        }
        return array
    }

    // order of params matters!
    // values from newArray overwrite values in oldArray where id and type match
    function mergeOldAndNewList(
        oldArray: UserRestrictedAreaAccess[] | null,
        newArray: UserRestrictedAreaAccess[] | null
    ): UserRestrictedAreaAccess[] {
        let oldArrayTmp = oldArray as UserRestrictedAreaAccess[]
        let newArrayTmp = newArray as UserRestrictedAreaAccess[]
        if (oldArrayTmp.length === 0) return newArrayTmp
        else if (newArrayTmp.length === 0) return oldArrayTmp
        // update element from old list with element from new list
        for (let o = 0; o < oldArrayTmp.length; ++o) {
            for (let n = 0; n < newArrayTmp.length; ++n) {
                if (
                    oldArrayTmp[o].restrictedAreaId === newArrayTmp[n].restrictedAreaId &&
                    oldArrayTmp[o].restrictedAreaType === newArrayTmp[n].restrictedAreaType
                ) {
                    oldArrayTmp[o] = newArrayTmp[n]
                }
            }
        }
        // concat lists and remove duplicates
        return mergeAccessListsWithoutDuplicates(oldArrayTmp, newArrayTmp)
    }

    function process(fn: () => Promise<UserRestrictedAreaAccess[] | BackendServiceError>) {
        if (loggedInUser) {
            let oldIsLoadedArray: string[] = []
            let oldUserAccessList: UserRestrictedAreaAccess[] = []
            state.set((prevState) => {
                oldIsLoadedArray = prevState.isLoadedArray.splice(0, prevState.isLoadedArray.length) // delete all elements and save them in variable oldIsLoadedArray
                oldUserAccessList = prevState.userRestrictedAreaAccessList // save all elements in userRestrictedAreaAccessList in variable oldUserAccessList
                return prevState
            })
            getUserAccessListDebouncedRequest(fn)
                ?.then((resp) => {
                    state.set((prevState) => {
                        // if an entry has been saved in the meantime by fetchUserAccessForSingleRestrictedArea() or fetch all for other RestrictedAreaTypes
                        // we also want that in the list
                        const tmpUserAccessList = mergeOldAndNewList(oldUserAccessList, prevState.userRestrictedAreaAccessList)
                        prevState.userRestrictedAreaAccessList = mergeOldAndNewList(tmpUserAccessList, resp)
                        // if an entry has been saved in the meantime by fetchUserAccessForSingleRestrictedArea() we also want that in the array
                        const newIsLoadedArray = (resp as Array<UserRestrictedAreaAccess | never>).map((o) =>
                            addTypePrefixToId(null, o.restrictedAreaId, o.restrictedAreaType)
                        )
                        const tmpIsLoadedArray = mergeArraysWithoutDuplicates(newIsLoadedArray, oldIsLoadedArray)
                        const currentIsLoadedArray = prevState.isLoadedArray
                        prevState.isLoadedArray = mergeArraysWithoutDuplicates(tmpIsLoadedArray, currentIsLoadedArray)
                        return prevState
                    })
                })
                .catch((err) => {
                    state.set((prevState) => {
                        // if an entry has been saved in the meantime by fetchUserAccessForSingleRestrictedArea() we also want that in the list
                        const currentIsLoadedArray = prevState.isLoadedArray
                        prevState.isLoadedArray = mergeArraysWithoutDuplicates(currentIsLoadedArray, oldIsLoadedArray)
                        return prevState
                    })
                })
        }
    }
    function processAllTypes() {
        if (loggedInUser) {
            let oldIsLoadedArray: string[] = []
            let oldUserAccessList: UserRestrictedAreaAccess[] = []
            state.set((prevState) => {
                oldIsLoadedArray = prevState.isLoadedArray.splice(0, prevState.isLoadedArray.length) // delete all elements and save them in variable oldIsLoadedArray
                oldUserAccessList = prevState.userRestrictedAreaAccessList // save all elements in userRestrictedAreaAccessList in variable oldUserAccessList
                return prevState
            })
            getUserAccessListForAllRestrictedAreaTypesDebouncedRequest()
                ?.then((resp) => {
                    state.set((prevState) => {
                        // if an entry has been saved in the meantime by fetchUserAccessForSingleRestrictedArea() or fetch all for other RestrictedAreaTypes
                        // we also want that in the list
                        const tmpUserAccessList = mergeOldAndNewList(oldUserAccessList, prevState.userRestrictedAreaAccessList)
                        prevState.userRestrictedAreaAccessList = mergeOldAndNewList(tmpUserAccessList, resp)
                        // if an entry has been saved in the meantime by fetchUserAccessForSingleRestrictedArea() we also want that in the array
                        const newIsLoadedArray = (resp as Array<UserRestrictedAreaAccess | never>).map((o) =>
                            addTypePrefixToId(null, o.restrictedAreaId, o.restrictedAreaType)
                        )
                        const tmpIsLoadedArray = mergeArraysWithoutDuplicates(newIsLoadedArray, oldIsLoadedArray)
                        const currentIsLoadedArray = prevState.isLoadedArray
                        prevState.isLoadedArray = mergeArraysWithoutDuplicates(tmpIsLoadedArray, currentIsLoadedArray)
                        return prevState
                    })
                })
                .catch((err) => {
                    state.set((prevState) => {
                        // if an entry has been saved in the meantime by fetchUserAccessForSingleRestrictedArea() we also want that in the list
                        const currentIsLoadedArray = prevState.isLoadedArray
                        prevState.isLoadedArray = mergeArraysWithoutDuplicates(currentIsLoadedArray, oldIsLoadedArray)
                        return prevState
                    })
                })
        }
    }

    return {
        requestAccess: (restrictedArea: RestrictedArea, requestAccessMessage: string): void => {
            state.set((prevState) => {
                setLoaded(false, restrictedArea, prevState)
                return prevState
            })
            sendRequestForRestrictedAreaAccess(
                loggedInUser!.profileId,
                restrictedArea.id,
                requestAccessMessage,
                determineType(restrictedArea)
            )
                .then((data) => {
                    let userAccessTemp: any = null
                    const errorStatus = (data as BackendServiceError).httpStatus
                    if (errorStatus) {
                        if (errorStatus !== 404) userAccessTemp = undefined
                    } else {
                        userAccessTemp = data as UserRestrictedAreaAccess
                    }
                    state.set((prevState) => {
                        setLoaded(true, restrictedArea, prevState)
                        setAccess(restrictedArea, prevState, userAccessTemp)
                        return prevState
                    })
                })
                .catch((err) => {
                    state.set((prevState) => {
                        setLoaded(true, restrictedArea, prevState)
                        return prevState
                    })
                })
        },
        isLoaded: (restrictedArea: RestrictedArea | null | undefined): boolean => {
            let loaded = false
            if (restrictedArea) {
                loaded = checkIsPublic(restrictedArea) || state.isLoadedArray.value.includes(addTypePrefixToId(restrictedArea))
            }
            return loaded
        },
        isUnlocked: (restrictedArea: RestrictedArea | null | undefined): boolean => {
            if (!restrictedArea) {
                return true
            }
            return (
                checkIsPublic(restrictedArea) ||
                checkStaffAccess(restrictedArea) ||
                checkUserEventDates((restrictedArea as EventDateBase).id) ||
                checkUserHasExplicitAccess(restrictedArea)
            )
        },
        getStatus: (restrictedArea: RestrictedArea): RestrictedAreaAccessType => {
            const status = state.userRestrictedAreaAccessList.value?.find((access) =>
                checkAccessInList(access, restrictedArea)
            )?.status
            let type = RestrictedAreaAccessType.UNAUTHORIZED
            if (status === "REQUESTED") type = RestrictedAreaAccessType.REQUESTED
            if (status === "GRANTED") type = RestrictedAreaAccessType.GRANTED
            return type
        },
        fetchUserAccessForSingleRestrictedArea: (restrictedArea: RestrictedArea): void => {
            if (!checkIsPublic(restrictedArea) && loggedInUser?.profileId) {
                state.set((prevState) => {
                    setLoaded(false, restrictedArea, prevState)
                    return prevState
                })
                getUserAccessSingleDebouncedRequest(restrictedArea, loggedInUser?.profileId)
                    ?.then((resp) => {
                        let userAccessTemp: UserRestrictedAreaAccess | null = resp
                        userAccessTemp = resp as UserRestrictedAreaAccess
                        state.set((prevState) => {
                            setLoaded(true, restrictedArea, prevState)
                            setAccess(restrictedArea, prevState, userAccessTemp)
                            return prevState
                        })
                    })
                    .catch((err) => {
                        state.set((prevState) => {
                            setAccess(restrictedArea, prevState, null)
                            setLoaded(true, restrictedArea, prevState)
                            return prevState
                        })
                    })
            } else {
                state.set((prevState) => {
                    // public event dates are not added to userRestrictedAreaAccessList, because it is checked in isUnlocked(eventDate),
                    // but they need to be added to isLoadedArray
                    setLoaded(true, restrictedArea, prevState)
                    return prevState
                })
            }
        },
        userRestrictedAreaAccessList: state.userRestrictedAreaAccessList.value,
        fetchUserAccessForAllEventDates: (): void => {
            process(getAccessStatusForAllEventDates)
        },
        fetchUserAccessForAllVirtualCafes: (): void => {
            process(getAccessStatusForAllVirtualCafes)
        },
        fetchUserAccessForAllConferenceRooms: (): void => {
            process(getAccessStatusForAllConferenceRooms)
        },
        fetchUserAccessForAllRestrictedAreaTypes: (): void => {
            processAllTypes()
        }
    }
}

export const useUserRestrictedAreaAccess = (): UserRestrictedAreaAccessContext => useStateWrapper(useState(state))

// throttling backend requests was causing some of the requests not be fetched, i.e. when they were called in the same throttle interval
// so we use memoized debounce, which calls all requests attempted in one debounce interval, but each one only once
// even if it was called several times within that interval
// https://css-tricks.com/debouncing-throttling-explained-examples/
// https://docs.actuallycolab.org/engineering-blog/memoize-debounce/

const getUserAccessSingleDebouncedRequest = memoizeDebounceForSingle(
    async (restrictedArea: RestrictedArea, profileId: string | undefined): Promise<UserRestrictedAreaAccess | null> => {
        try {
            // maybe restricted area access has been loaded meanwhile
            let loaded = false
            if (restrictedArea) {
                loaded = checkIsPublic(restrictedArea) || state.isLoadedArray.value.includes(addTypePrefixToId(restrictedArea))
            }
            if (!loaded) {
                const data = await getUserRestrictedAreaAccess(profileId!, restrictedArea.id, determineType(restrictedArea))
                const errorStatus = (data as BackendServiceError).httpStatus
                if (errorStatus) {
                    return null
                } else {
                    return data as UserRestrictedAreaAccess
                }
            } else return null
        } catch (err) {
            return null
        }
    },
    DEBOUNCE_MS,
    {
        leading: true,
        trailing: false
    }
)

const getUserAccessListDebouncedRequest = memoizeDebounceForList(
    async (fn: () => Promise<UserRestrictedAreaAccess[] | BackendServiceError>): Promise<UserRestrictedAreaAccess[] | null> => {
        try {
            const resp = await fn()
            if ((resp as BackendServiceError).httpStatus) {
                return []
            } else {
                return resp as UserRestrictedAreaAccess[]
            }
        } catch (err) {
            return []
        }
    },
    DEBOUNCE_MS,
    {
        leading: true,
        trailing: false
    }
)

const getUserAccessListForAllRestrictedAreaTypesDebouncedRequest = memoizeDebounceForList(
    (): Promise<UserRestrictedAreaAccess[] | null> => {
        return Promise.all([
            getAccessStatusForAllEventDates(),
            getAccessStatusForAllVirtualCafes(),
            getAccessStatusForAllConferenceRooms()
        ])
            .then(([eventDateAccesses, virtualCafeAccesses, conferenceRoomAccesses]) => {
                let eventDateAccesslist: UserRestrictedAreaAccess[] = []
                let virtualCafeAccesslist: UserRestrictedAreaAccess[] = []
                let conferenceRoomAccesslist: UserRestrictedAreaAccess[] = []

                if (!(eventDateAccesses as BackendServiceError).httpStatus) {
                    eventDateAccesslist = eventDateAccesses as UserRestrictedAreaAccess[]
                }

                if (!(virtualCafeAccesses as BackendServiceError).httpStatus) {
                    virtualCafeAccesslist = virtualCafeAccesses as UserRestrictedAreaAccess[]
                }
                if (!(conferenceRoomAccesses as BackendServiceError).httpStatus) {
                    conferenceRoomAccesslist = conferenceRoomAccesses as UserRestrictedAreaAccess[]
                }
                return eventDateAccesslist.concat(virtualCafeAccesslist.concat(conferenceRoomAccesslist))
            })
            .catch((err) => {
                return []
            })
    },
    DEBOUNCE_MS,
    {
        leading: true,
        trailing: false
    }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface MemoizeDebouncedFunctionForList<F extends (...args: any[]) => any> {
    (...args: Parameters<F>): Promise<UserRestrictedAreaAccess[] | null>
    flush: (...args: Parameters<F>) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoizeDebounceForList<F extends (...args: any[]) => any>(
    func: F,
    wait = 0,
    options: _.DebounceSettings = {},
    resolver?: (...args: Parameters<F>) => unknown
): MemoizeDebouncedFunctionForList<F> {
    const debounceMemo = _.memoize<(...args: Parameters<F>) => _.DebouncedFunc<F>>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (..._args: Parameters<F>) => _.debounce(func, wait, options),
        resolver
    )

    function wrappedFunction(this: MemoizeDebouncedFunctionForList<F>, ...args: Parameters<F>): ReturnType<F> | undefined {
        return debounceMemo(...args)(...args)
    }

    wrappedFunction.flush = (...args: Parameters<F>): void => {
        debounceMemo(...args).flush()
    }

    return wrappedFunction as unknown as MemoizeDebouncedFunctionForList<F>
}

export interface MemoizeDebouncedFunctionForSingle<F extends (...args: any[]) => any> {
    (...args: Parameters<F>): Promise<UserRestrictedAreaAccess | null>
    flush: (...args: Parameters<F>) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoizeDebounceForSingle<F extends (...args: any[]) => any>(
    func: F,
    wait = 0,
    options: _.DebounceSettings = {},
    resolver?: (...args: Parameters<F>) => unknown
): MemoizeDebouncedFunctionForSingle<F> {
    const debounceMemo = _.memoize<(...args: Parameters<F>) => _.DebouncedFunc<F>>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (..._args: Parameters<F>) => _.debounce(func, wait, options),
        resolver
    )

    function wrappedFunction(this: MemoizeDebouncedFunctionForSingle<F>, ...args: Parameters<F>): ReturnType<F> | undefined {
        return debounceMemo(...args)(...args)
    }

    wrappedFunction.flush = (...args: Parameters<F>): void => {
        debounceMemo(...args).flush()
    }

    return wrappedFunction as unknown as MemoizeDebouncedFunctionForSingle<F>
}

// fail-safe way to find out which RestrictedAreaType a RestrictedArea has
export const determineType = (restrictedArea: RestrictedArea): RestrictedAreaType => {
    switch (restrictedArea.restrictedAreaType) {
        case "privateEvent":
            return RestrictedAreaTypes.PrivateEvent

        case "virtualCafe":
            return RestrictedAreaTypes.VirtualCafe

        case "conferenceroom":
            return RestrictedAreaTypes.ConferenceRoom

        default:
            // safety fallback
            if ((restrictedArea as EventDateBase).accessProvider) {
                return RestrictedAreaTypes.PrivateEvent
            }
            if ((restrictedArea as MeetingRoomGroupType).meetingRooms) {
                return RestrictedAreaTypes.VirtualCafe
            }
            if ((restrictedArea as ConferenceRoom).accessType) {
                return RestrictedAreaTypes.ConferenceRoom
            }
            // this should never happen, but it's better than having empty string or undefined,
            // because that could potentially be matched with anything and grant access where it shouldn't
            return RestrictedAreaTypes.PrivateEvent
    }
}
