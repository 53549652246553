import { useEffect, useState } from "react"
import * as React from "react"
import ReactDOM from "react-dom"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { trackVisit } from "../../../backendServices/TrackingServices"
import { Attachment, Company } from "../../../backendServices/Types"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { IconPlayFilled } from "../../../ui/Icons"
import { usePrivacyPolicyModal } from "../../../ui/PrivacyPolicyModal"
import TileRow from "../../../ui/TileRow"
import FocusImage from "./FocusImage"
import { MediaSlider } from "./Media"

const FocusImageMediaRoot = styled.div``

const SliderImage = styled.div`
    width: 230px;
    height: 150px;
    background-color: white;
    margin: 10px 10px 15px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 5px;
`
const VideoPlayerContainer = styled.div`
    position: relative;
    width: 230px;
    height: 150px;
    margin: 10px 10px 15px 0;
    border-radius: 5px;
    cursor: pointer;
`

const PlayIcon = styled.div`
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`

interface ThumbnailVideoPlayerProps {
    attachment: Attachment
    setSelectedAttachment: (value: React.SetStateAction<Attachment | null>) => void
}

export const ThumbnailVideoPlayer = (props: ThumbnailVideoPlayerProps) => {
    return (
        <VideoPlayerContainer onClick={() => props.setSelectedAttachment(props.attachment)}>
            <PlayIcon>{IconPlayFilled({ width: "35", height: "35", stroke: "white" })}</PlayIcon>
            <ReactPlayer
                controls={false}
                playing={false}
                url={props.attachment.url}
                width="100%"
                height="100%"
                style={{ outline: "none" }}
                config={{
                    file: {
                        attributes: {
                            controlsList: "nodownload",
                            onContextMenu: (e: any) => e.preventDefault()
                        }
                    }
                }}
            ></ReactPlayer>
        </VideoPlayerContainer>
    )
}

interface FocusImageMediaProps {
    attachments?: Attachment[]
    imageInFocus: string
    organizationId: string | undefined
    organization?: Company
}

const FocusImageMedia = (props: FocusImageMediaProps) => {
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(
        props.attachments?.length ? props.attachments[0] : null
    )
    const [selectedIndex, setSelectedIndex] = useState(0)

    // Media slider
    const { PrivacyModal, showPrivacyPolicyModal } = usePrivacyPolicyModal()
    const [showMediaSlider, setShowMediaSlider] = useState(false)
    const [selectedAtt, setSelecedAtt] = useState<Attachment>()
    const user = useLoggedInState().user()

    useEffect(() => {
        let index = props.attachments?.findIndex((x) => x.url === selectedAttachment?.url)
        setSelectedIndex(index || 0)
        // eslint-disable-next-line
    }, [selectedAttachment])

    const onFocusImageClicked = () => {
        if (!selectedAttachment) return
        if (props.organizationId && props.organization)
            showPrivacyPolicyModal(props.organization, (accepted) => {
                trackVisit(user!.profileId, props.organizationId!, "MEDIA#PREVIEW", selectedAttachment?.id)
                setSelecedAtt(selectedAttachment)
                setShowMediaSlider(true)
            })
        else {
            setSelecedAtt(selectedAttachment)
            setShowMediaSlider(true)
        }
    }

    return (
        <FocusImageMediaRoot>
            <FocusImage
                imageInFocus={props.imageInFocus}
                selectedAttachment={selectedAttachment || undefined}
                organizationId={props.organizationId}
                onFocusImageClicked={onFocusImageClicked}
            />

            {props.attachments && props.attachments?.length > 1 && (
                <TileRow
                    title={""}
                    hideTitle={true}
                    childWidth={230 + 5}
                    childCount={props.attachments?.length || 0}
                    scrollBar={true}
                    tileRowMargin={"0px"}
                    selectedItem={selectedIndex}
                    hideShadows={true}
                >
                    {props.attachments?.map((attachment) => {
                        console.log(attachment)
                        return attachment.contentType.includes("video") ? (
                            <ThumbnailVideoPlayer attachment={attachment} setSelectedAttachment={setSelectedAttachment} />
                        ) : (
                            <SliderImage
                                style={{ backgroundImage: `url(${attachment.url})` }}
                                onClick={() => setSelectedAttachment(attachment)}
                            ></SliderImage>
                        )
                    })}
                </TileRow>
            )}
            <PrivacyModal />
            {props.attachments &&
                props.attachments.length > 0 &&
                ReactDOM.createPortal(
                    showMediaSlider && (
                        <MediaSlider
                            media={props.attachments}
                            selectedAtt={selectedAtt}
                            setShowMediaSlider={setShowMediaSlider}
                        />
                    ),
                    document.body
                )}
        </FocusImageMediaRoot>
    )
}

export default FocusImageMedia
