import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { ProfileResponseStatus } from "./LoginRegistrationSite"
import { CenterLoginStyleErrorMessage, ErrorMessagesContainer } from "./CenterLoginSharedComponents"
import { CreateNewTokenButton } from "./token/LoginWithToken"

interface LoginErrorProperties {
    profileResponseStatus: ProfileResponseStatus
    clickListener?: () => void
}

function LoginError(props: LoginErrorProperties) {
    const { profileResponseStatus } = props
    const strings = useLanguageState().getStrings()
    const localLogoutReason =
        localStorage.getItem("logoutReason") === null
            ? null
            : localStorage.getItem("logoutReason") === "logoutReasonSessionTimeout"
            ? strings.loginRegistrationSite.logoutReasonSessionTimeout
            : strings.loginRegistrationSite.logoutReasonForbidden

    const invalidEmail = branding.loginRegistrationSite.invalidEmailPanelWithLink.visible ? (
        <p>
            {strings.loginRegistrationSite.invalidEmailPanelWithLink.befLinkPart}
            <a
                className="invalid-email-link"
                href={strings.loginRegistrationSite.invalidEmailPanelWithLink.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                {strings.loginRegistrationSite.invalidEmailPanelWithLink.text}
            </a>
            {strings.loginRegistrationSite.invalidEmailPanelWithLink.aftLinkPart}
        </p>
    ) : (
        <p>{strings.loginRegistrationSite.invalidEmailPanel}</p>
    )

    if (
        !localLogoutReason &&
        (profileResponseStatus === ProfileResponseStatus.SUCCESS || profileResponseStatus === ProfileResponseStatus.NONE)
    )
        return null

    return (
        <ErrorMessagesContainer>
            <CenterLoginStyleErrorMessage>
                {localLogoutReason && <p>{localLogoutReason}</p>}

                {profileResponseStatus === ProfileResponseStatus.FAILURE && (
                    <p>{strings.loginRegistrationSite.registerEmailFailed}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.FAILURE_EMAIL_INVALID && invalidEmail}

                {profileResponseStatus === ProfileResponseStatus.LOGIN_BLOCKED && (
                    <div>
                        <p>{strings.loginRegistrationSite.codeResendLimit}</p>
                        <CreateNewTokenButton
                            variant="link"
                            onClick={() => window.open(`${strings.loginRegistrationSite.contactSupportUrl}`, "_blank")}
                        >
                            {strings.loginRegistrationSite.contactSupportText}
                        </CreateNewTokenButton>
                    </div>
                )}

                {profileResponseStatus === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED && (
                    <p>{strings.loginRegistrationSite.outdatedPrivacyDocsPanel}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND && (
                    <p>{strings.loginRegistrationSite.thirdPartyUserNotFound}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.USER_CREATION_NOT_ALLOWED && (
                    <p>{strings.loginRegistrationSite.userCreationNotAllowed}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.WRONG_PASSWORD && (
                    <p>{strings.loginRegistrationSite.wrongPasswordPanel}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.TICKET_FAILURE && (
                    <p>{branding.loginRegistrationSite.ticketCodeFailureMessage}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.TICKET_CONFIGURATION_ISSUE && (
                    <p>{strings.loginRegistrationSite.ticketConfigurationIssueMessage}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.TICKET_NOT_VALID && (
                    <p>{strings.loginRegistrationSite.ticketNotValidMessage}</p>
                )}

                {profileResponseStatus === ProfileResponseStatus.TICKET_ALREADY_REDEEMED && (
                    <p>{strings.loginRegistrationSite.ticketAlreadyRedeemedMessage}</p>
                )}
            </CenterLoginStyleErrorMessage>
        </ErrorMessagesContainer>
    )
}

export default LoginError
