import { fetchDataWebService } from "./BackendServicesUtils"
import { EntityType, SuggestGroup } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * SUGGESTION REQUEST
 **********************************************************************************************/
export interface SuggestResponse {
    searchString: string
    suggestGroups: SuggestGroup[]
}

export enum GroupConfigType {
    /**
     * @title Category group config type
     * @description Type name for category group config
     * @title_de Category group config type
     * @description_de Type name for category group config
     */
    cat = "cat",
    /**
     * @title Interest group config type
     * @description Type name for interest group config
     * @title_de Interest group config type
     * @description_de Type name for interest group config
     */
    interest = "interest",
    /**
     * @title City group config type
     * @description Type name for city group config
     * @title_de City group config type
     * @description_de Type name for city group config
     */
    city = "city",
    /**
     * @title Country group config type
     * @description Type name for country group config
     * @title_de Country group config type
     * @description_de Type name for country group config
     */
    country = "country",
    /**
     * @title Entity group config type
     * @description Type name for entity group config
     * @title_de Entity group config type
     * @description_de Type name for entity group config
     */
    entity = "entity",
    /**
     * @title Hall group config type
     * @description Type name for hall group config
     * @title_de Hall group config type
     * @description_de Type name for hall group config
     */
    hall = "hall",
    /**
     * @title Postcode group config type
     * @description Type name for postcode group config
     * @title_de Postcode group config type
     * @description_de Type name for postcode group config
     */
    postcode = "postcode",
    /**
     * @title Collections group config type
     * @description Type name for Collections group config
     * @title_de Collections group config type
     * @description_de Type name for Collections group config
     */
    collections = "collections",
    /**
     * @title Employment type group config type
     * @description Type name for employment type group config
     * @title_de Employment type group config type
     * @description_de Type name for employment type group config
     */
    employment_type = "employment_type",
    /**
     * @title Job location group config type
     * @description Type name for job location group config
     * @title_de Job location group config type
     * @description_de Type name for job location group config
     */
    job_location = "job_location"
}

export interface GroupConfig {
    /**
     * @title Suggest group id
     * @description Id provided for suggest group
     * @title_de Suggest group id
     * @description_de Id provided for suggest group
     */
    id: string
    /**
     * @title Suggest group types
     * @description Various types provided for suggest group
     * @title_de Suggest group types
     * @description_de Various types for suggest group
     */
    type: GroupConfigType
    /**
     * @title Suggest group entity types
     * @description Various of entity types provided for suggest group
     * @title_de Suggest group entity types
     * @description_de Various of entity types provided for suggest group
     */
    entityType: EntityType
    /**
     * @title Suggest group msc number
     * @description msc number provided for suggest group
     * @title_de Suggest group msc number
     * @description_de msc number provided for suggest group
     */
    msc: number
}

export async function loadSuggestions(query: string, groupConfig: GroupConfig[] | any[]): Promise<SuggestResponse> {
    try {
        const data = await fetchDataWebService("/suggest", {
            searchstring: query,
            groupconfig: JSON.stringify(groupConfig.map((value) => (value as any).jsonData))
        })
        return { searchString: data.query, suggestGroups: data.suggestionGroups }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/suggest",
            params: { query },
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}
