import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import TopBannerSharedState from "../contentArea/advertisingBanner/TopBannerSharedState"
import GuestUserBanner from "../contentArea/guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../contentArea/guestUserBanner/GuestUserBannerSharedState"
import Breadcrumb, { BreadcrumbItem } from "../navigationArea/Breadcrumb"
import TopBar from "../navigationArea/TopBar"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import TopBanner from "../contentArea/advertisingBanner/TopBanner"
import useWindowDimensions from "../ui/WindowDimensionsHook"
import { device, deviceSize } from "../utils/Device"
import ActionBar from "../contentArea/hallplan/HallplanActionBar"

const StandardPageRoot = styled.div`
    &.hallplan {
        background: #fff;
        @media ${device.mobile} {
            padding: 10px;
            padding-bottom: 200px;
            background: ${branding.myProfilePageContent.mobilePageBackgroundColor};

            .ScrollbarsCustom {
                height: 75vh !important;
            }

            .ScrollbarsCustom-Content {
                padding: 15px !important;
                padding-bottom: 60px !important;
            }
        }
    }
`

const MobileSectionWrapper = styled.div`
    &.hallplan {
        width: 100%;
        background: #fff;
        margin-bottom: 10px;
        border-radius: 5px;
    }
`

interface StandardPageProps {
    breadcrumb: BreadcrumbItem[]
    bannerPoolTitle?: string

    selectedHall?: string
    hallplan?: boolean
}
export const StandardPage: React.FC<StandardPageProps> = (props) => {
    const { breadcrumb } = props
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef, hideOnScroll, setHideOnScroll } = TopBannerSharedState()

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }
    }

    const windowSize = useWindowDimensions()

    const adjustHeight =
        windowSize.width <= deviceSize.tablet
            ? 25
            : (guestUserBannerRef && guestUserBannerRef?.current ? guestUserBannerRef.current.clientHeight : 0) + // Guestbanner Height
              (topBarRef && topBarRef?.current ? topBarRef.current.clientHeight : 0) +
              60 + // Topbar Height
              60 + // SearchBar Height
              (props.hallplan ? 60 : 0) // ActionBar height

    return (
        <StandardPageRoot className={props.hallplan ? "hallplan" : ""}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar />
            {props.bannerPoolTitle && <TopBanner setRef={setTopBarRef} type={props.bannerPoolTitle} onHideBanner={() => {}} />}
            <div>
                <Breadcrumb breadcrumb={breadcrumb} showShadow={props.hallplan ? false : hideOnScroll} />
            </div>
            <MobileSectionWrapper className={props.hallplan ? "hallplan" : ""}>
                {props.hallplan && (
                    <ActionBarWrapper showShadow={hideOnScroll}>
                        <ActionBar selectedHall={props.selectedHall} />
                    </ActionBarWrapper>
                )}
                <ContentWrapper
                    guestBannerHeight={
                        guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0
                    }
                >
                    <ContentScrollContainer adjustForHeaderWith={adjustHeight + "px"} handleScroll={onScroll}>
                        {props.children}
                    </ContentScrollContainer>
                </ContentWrapper>
            </MobileSectionWrapper>
        </StandardPageRoot>
    )
}

const ActionBarWrapper = styled.div<{ showShadow?: boolean }>`
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
    z-index: 10;
`

export const ContentWrapper = styled.div<{ guestBannerHeight: number }>`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-grow: 1;
    height: calc(100% - 110px - ${(props) => props.guestBannerHeight + "px"});
    max-height: calc(100% - 110px - ${(props) => props.guestBannerHeight + "px"});
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
`
