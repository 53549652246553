import * as React from "react"
import { ContactEntry, ContactEntryType } from "../communicationArea/ContactEntry"
import { IconRemoveFromChat } from "./Icons"
import branding from "../branding/branding"
import { ConversationParticipant } from "../communicationArea/ChatPage"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useState } from "react"
import RemoveFromChatModal from "./RemoveFromChatModal"

export interface Participants {
    id: string
    name: string
    pictureUrl?: string
    subtitle?: string
}

interface ParticipantListProps {
    participants: Participants[]
    admin?: boolean
    removeOpponent?: (opponent?: ConversationParticipant) => Promise<boolean>
}

const ParticipantList: React.FC<ParticipantListProps> = (props) => {
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId || ""
    const [showRemoveFromChatModal, setShowRemoveFromChatModal] = useState<boolean>(false)
    const [target, setTarget] = useState<ConversationParticipant>()
    const strings = useLanguageState().getStrings()

    return (
        <>
            {props.participants.map((participant) => (
                <ContactEntry
                    contact={participant}
                    key={participant.id}
                    entryType={ContactEntryType.GROUP_CHAT}
                    hideMenu={participant.id === loggedInUserId}
                    additionalActions={
                        props.admin
                            ? [
                                  {
                                      disabled: false,
                                      title: strings.chatBranding.removeParticipantButtonText,
                                      hint: strings.chatBranding.removeParticipantTitle,
                                      icon: IconRemoveFromChat({ fill: branding.sideIconBar.sideIconColorDark }),
                                      onClick: () => {
                                          setTarget(participant as ConversationParticipant)
                                          setShowRemoveFromChatModal(true)
                                      }
                                  }
                              ]
                            : []
                    }
                />
            ))}
            {showRemoveFromChatModal && target && (
                <RemoveFromChatModal
                    removeOpponent={() => {
                        if (props.removeOpponent) {
                            props.removeOpponent(target)
                        }
                    }}
                    onClose={() => setShowRemoveFromChatModal(false)}
                />
            )}
        </>
    )
}

export default ParticipantList
