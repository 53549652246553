import { useEffect, useRef, useState } from "react"
import * as React from "react"
import Autosuggest, {
    BlurEvent,
    ChangeEvent,
    RenderSuggestionParams,
    SuggestionsFetchRequestedParams,
    Theme
} from "react-autosuggest"
import styled from "styled-components"
import { loadSuggestions, GroupConfigType, GroupConfig } from "../backendServices/SuggestionServices"
import { SuggestGroup, Suggestion } from "../backendServices/Types"
import branding, { Branding } from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"
import { EntityType } from "../backendServices/Types"
import { useSuggestContext } from "./useSuggestContext"
import { IconChevronRight, IconClose, IconArrowRightChevron, IconSearch } from "../ui/Icons"
import { AvatarWithDefault } from "../ui/AvatarWithDefault"
import { useHistory } from "react-router-dom"
import { globalSearchResultPageRoute, collectionRoute, collectionOverviewRoute } from "./RoutePaths"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { device, DesktopVersionContainer, MobileVersionContainer } from "../utils/Device"

enum StaticSuggestionId {
    SHOWMORE_ENTITIES = "showMoreEntities",
    SEARCH_ALL = "searchAll",
    COLLECTION = "collectionSuggestion",
    SHOWMORE_COLLECTION = "showMoreCollection"
}

// Hack to allow for visibility of suggest container even if there is no suggestion from the backend. For "searchAll"
// Suggestion container is not displayed if there are no suggestions
const searchAllSuggestGroup = {
    id: StaticSuggestionId.SEARCH_ALL,
    maxSuggestions: 0,
    name: "",
    suggestions: [{ icon: "", id: StaticSuggestionId.SEARCH_ALL, value: "", subtitle: "", title: "" }],
    trackingName: ""
}

/* #region  Custom Input Component */
const SuggestionInputWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    height: 100%;

    &:last-child {
        flex-grow: 1;
    }
`

const SelectedSuggestion = styled.div`
    height: 35px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-left: 10px;
    background: #d9d9d9;
    border-radius: 5px;
    user-select: none;

    &:first-child {
        margin-left: 0;
    }

    &:nth-last-child(2) {
        margin-right: 15px;
    }
`

const SuggestionSearchValue = styled.div`
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    font-size: 16px;
    font-family: ${branding.font1};
`

const RemoveSuggestionIconWrapper = styled.div`
    cursor: pointer;

    & span,
    svg {
        pointer-events: none;
    }
`

function renderInputComponent(inputProps: any, suggestions: Suggestion[], removeSuggestion: (suggestion: Suggestion) => void) {
    return (
        <SuggestionInputWrapper>
            {suggestions.map((suggestion, index) => {
                const title = suggestion.id === StaticSuggestionId.SEARCH_ALL ? suggestion.value : suggestion.title
                return (
                    <SelectedSuggestion key={index}>
                        <SuggestionSearchValue>{title}</SuggestionSearchValue>
                        <RemoveSuggestionIconWrapper onClick={() => removeSuggestion(suggestion)}>
                            {IconClose({ fill: "currentColor", width: "10", height: "10" })}
                        </RemoveSuggestionIconWrapper>
                    </SelectedSuggestion>
                )
            })}
            <input {...inputProps} />
        </SuggestionInputWrapper>
    )
}
/* #endregion */

function renderSectionTitle(section: SuggestGroup, strings: any) {
    let name = section.name
    if (!name)
        switch (section.trackingName) {
            case "categories":
                name = strings.organizationDetailPageContent.sectionLineCategories
                break
            case "organization":
                name = strings.navigationArea.companiesItemTitle
                break
            case "person":
                name = strings.navigationArea.speakersItemTitle
                break
            case "news":
                name = strings.navigationArea.newsItemTitle
                break
            case "product":
                name = strings.navigationArea.productsItemTitle
                break
            case "trademark":
                name = strings.navigationArea.trademarksItemTitle
                break
            case "eventdate":
                name = strings.navigationArea.eventdateItemTitle
                break
            case "coupon":
                name = strings.navigationArea.couponItemTitle
                break
            case "networking_user":
                name = strings.sideIconBar.networkingParticipantsSearchDrawerText
                break
            case "countries":
                name = strings.suggestBoxContent.countriesGroupTitle
                break
            case "joboffer":
                name = strings.suggestBoxContent.joboffersGroupTitle
                break
        }
    return <span>{name}</span>
}

function getEntityTypeForTrackingName(trackingName: string): EntityType {
    return trackingName === "categories" ? "category" : trackingName === "countries" ? "country" : (trackingName as EntityType)
}

const getShowMoreTitleForEntityType = (suggestGroup: GroupConfig | undefined, strings: Branding): string => {
    const suggestBoxStrings = strings.suggestBoxContent
    if (suggestGroup) {
        if (suggestGroup.type === "entity") {
            switch (suggestGroup.entityType) {
                case "organization":
                    return suggestBoxStrings.showMoreTitleOrganizations
                case "product":
                    return suggestBoxStrings.showMoreTitleProducts
                case "trademark":
                    return suggestBoxStrings.showMoreTitleTrademarks
                case "news":
                    return suggestBoxStrings.showMoreTitleNews
                case "networking_user":
                    return suggestBoxStrings.showMoreTitleUsers
                case "person":
                    return suggestBoxStrings.showMoreTitlePersons
                case "eventdate":
                    return suggestBoxStrings.showMoreTitleEventDates
                case "coupon":
                    return suggestBoxStrings.showMoreTitleCoupons
            }
        } else if (suggestGroup.type === "collections") return suggestBoxStrings.showMoreTitleCollections
    }

    return strings.networkingArea.showMoreText
}

/* #region  Render Suggestions */
const SuggestionRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
`
const StyledAvatar = styled(AvatarWithDefault)`
    flex-shrink: 0;
    border-radius: 5px;
`

const SuggestionText = styled.div`
    flex-grow: 1;
    padding-left: 10px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;

    .react-autosuggest__suggestion--highlighted & {
        text-decoration: underline;
    }
`

const SuggestionTitle = styled.div`
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
`

const SuggestionSubtitle = styled.div`
    font-size: 10px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
`

const ShowMoreLine = styled.div`
    display: flex;
    font-weight: bold;
    font-size: 12px;

    .react-autosuggest__suggestion--highlighted & {
        text-decoration: underline;
    }
`

const ShowAllWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ShowAllTitle = styled.div`
    font-family: ${branding.font1};
    font-weight: bold;
    margin-right: 15px;
    color: ${branding.suggestBoxContent.showAllBtn.color ?? "#6DD400"};
    background-color: ${branding.suggestBoxContent.showAllBtn.bgColor ?? "#FFF"};
`

function renderSuggestion(suggestion: Suggestion, params: RenderSuggestionParams) {
    if (suggestion.id === StaticSuggestionId.SEARCH_ALL)
        return (
            <ShowAllWrapper>
                <ShowAllTitle>{suggestion.title}</ShowAllTitle>
                <div>{IconArrowRightChevron({ fill: branding.suggestBoxContent.showAllIconColor ?? "#6DD400" })}</div>
            </ShowAllWrapper>
        )
    else if (
        (suggestion.type && suggestion.id === StaticSuggestionId.SHOWMORE_ENTITIES) ||
        suggestion.id === StaticSuggestionId.SHOWMORE_COLLECTION
    )
        return (
            <SuggestionRow>
                <ShowMoreLine>
                    {suggestion.title}
                    <div className="ml-2">
                        <IconChevronRight width="12px" height="12px" />
                    </div>
                </ShowMoreLine>
            </SuggestionRow>
        )
    else
        return (
            <SuggestionRow>
                <StyledAvatar
                    alt={suggestion.title}
                    src={suggestion.icon}
                    size={40}
                    backgroundSize={
                        suggestion.type && (suggestion.type === "networking_user" || suggestion.type === "person")
                            ? "cover"
                            : "contain"
                    }
                />
                <SuggestionText>
                    <SuggestionTitle>{suggestion.title}</SuggestionTitle>
                    <SuggestionSubtitle>{suggestion.subtitle}</SuggestionSubtitle>
                </SuggestionText>
            </SuggestionRow>
        )
}
/* #endregion */

/**
 * wrapper style, because suggestbox, e.g. autosuggest component cannot be styled
 */
const StyledAutoSuggestWrapper = styled.div<{ emptySearch: boolean }>`
    display: flex;

    .react-autosuggest__container {
        font-size: 18px;
        background: #fff;
        color: ${branding.primaryColor};
        border-bottom: 1px solid ${branding.primaryColor};
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        top: 0;
        bottom: -1px;

        & > :first-child {
            padding-left: 60px;
            overflow-x: auto;
        }
    }

    .react-autosuggest__input {
        height: 100%;
        border: 0 none;
        outline: none !important;
        padding: 0;
        padding-left: ${(props) => (props.emptySearch ? "20px" : 0)};
        min-width: 200px;
        width: 100%;
        margin-right: 80px;

        @media ${device.tablet} {
            width: 100%;
            margin-right: 200px;
        }
    }

    .react-autosuggest__suggestions-container {
        display: none;
        background: #fff;
        flex-wrap: wrap;
        border-top: 1px solid ${branding.primaryColor};
        border-bottom: 1px solid ${branding.primaryColor};

        &.react-autosuggest__suggestions-container--open {
            display: flex;
            position: relative;
            max-height: 80vh;
            overflow: hidden auto;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
            border-bottom: none;

            @media ${device.tablet} {
                max-height: 50vh;
            }
        }
    }

    .react-autosuggest__section-container {
        width: calc(50% - 40px); /* -20px for padding left and right */
        padding: 20px 20px 50px 20px;
        flex: 0 50%;

        @media ${device.tablet} {
            width: calc(33% - 40px);
            flex: 0 33%;
        }

        @media ${device.laptopL} {
            width: calc(25% - 40px);
            flex: 0 25%;
        }

        @media ${device.desktop} {
            width: calc(20% - 40px);
            flex: 0 20%;
        }

        /* Show all button */
        &:last-child {
            width: 100%;
            height: 50px;
            flex: unset;
            display: flex;
            padding: 0;
            position: sticky;
            right: 0;
            bottom: 0;
            left: 0;
            border-top: 1px solid #c9c9c9;

            .react-autosuggest__section-title {
                display: none;
            }

            .react-autosuggest__suggestion {
                margin: 0;
                text-align: center;
                color: #6dd400;
                background-color: #fff;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .react-autosuggest__suggestions-list {
                width: 100%;
                height: 100%;
            }
        }
    }

    .react-autosuggest__section-title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .react-autosuggest__suggestion {
        margin-bottom: 15px;
    }

    .react-autosuggest__suggestion--highlighted {
        cursor: pointer;
    }
`

const DummySearchIconWrapper = styled.div`
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 10px;
    top: 0;
    z-index: 5;
`

const DummyCloseIconWrapper = styled.div`
    width: 50px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const ClearAndSearchAllWrapper = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    background-color: #fff;
    color: ${branding.primaryColor};
    z-index: 4;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: normal;
`

const ClearAndSearchAllBtnTemplate = styled.div`
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 40px;
    width: 120px;
    padding: 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
`

const ClearAllBtn = styled(ClearAndSearchAllBtnTemplate)<{ emptySearch: boolean }>`
    display: none;
    color: ${branding.suggestBoxContent.clearAllBtn.color ?? "#6DD400"};
    background-color: ${branding.suggestBoxContent.clearAllBtn.bgColor ?? "#FFF"};

    @media ${device.mobileL} {
        display: ${(props) => (props.emptySearch ? "none" : "flex")};
    }
`

export const SearchAllBtn = styled(ClearAndSearchAllBtnTemplate)`
    display: flex;
    color: ${branding.suggestBoxContent.searchAllBtn.color ?? "#FFF"};
    background: ${branding.suggestBoxContent.searchAllBtn.bgColor ?? "#6DD400"};
`

interface SuggestBoxProps {
    theme?: Theme
}
const SuggestBox: React.FunctionComponent<SuggestBoxProps> = React.memo((props: SuggestBoxProps) => {
    const { theme } = props
    const suggestState = useSuggestContext()
    const [suggestGroups, setSuggestGroups] = useState<SuggestGroup[]>([])
    const [inputValue, setInputValue] = useState<string>("")
    const strings = useLanguageState().getStrings()
    const wrapperRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const history = useHistory()

    const [tempSuggestions, setTempSuggestions] = useState(suggestState.suggestions)

    const inputProps = {
        placeholder: strings.sideIconBar.searchIconText,
        value: inputValue,
        onChange: (_event: React.FormEvent, params: ChangeEvent) => {
            setInputValue(params.newValue)
            if (!suggestState.isOverlayVisible) suggestState.setOverlayVisible(true)
        },
        onBlur: (_event: React.FocusEvent, _params?: BlurEvent<any>) => {
            // if (suggestState.suggestions?.length === 0 && inputRef.current?.value === "") suggestState.setVisible(false)
            suggestState.setVisible(false)
        },
        ref: inputRef
    }

    useEffect(() => inputRef.current?.focus(), [])

    useEffect(() => {
        if (!suggestState.isVisible || JSON.stringify(suggestState.suggestions) === JSON.stringify(tempSuggestions)) return

        setTempSuggestions(suggestState.suggestions)
        function handleOutsideClick(this: Document, ev: MouseEvent) {
            if (!suggestState.suggestions && wrapperRef.current && !wrapperRef.current.contains(ev.target as Node)) {
                suggestState.setVisible(false)
            }
            suggestState.setOverlayVisible(false)
        }
        document.addEventListener("mousedown", handleOutsideClick)
        return () => {
            if (!suggestState.isVisible) return
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [suggestState.isVisible, suggestState.suggestions]) // eslint-disable-line

    const startSearch = (searchValue: string, input: HTMLInputElement | null) => {
        if (!input) return

        const suggestion = {
            icon: "",
            id: StaticSuggestionId.SEARCH_ALL,
            value: searchValue,
            subtitle: "",
            title: strings.suggestBoxContent.showAllBtn.text,
            active: true
        }
        suggestState.addSuggestion(suggestion)
        setInputValue("")

        // redirecting user to result page (if he is not already there)
        if (window.location.href.indexOf(globalSearchResultPageRoute) === -1) history.push(globalSearchResultPageRoute)

        suggestState.setOverlayVisible(false)
    }

    // start searching all when "Enter" is pressed
    useEffect(() => {
        const input = inputProps.ref.current
        if (!input) return

        const handleEnterPress = (e: any) => {
            if (e.keyCode === 13 && input.value !== "") {
                // keyCode for "Enter"
                startSearch(e.target.value, input)
            }
        }

        input.addEventListener("keyup", handleEnterPress)
        return () => input?.removeEventListener("keyup", handleEnterPress)
    }, [inputProps.ref.current]) //eslint-disable-line

    async function fetchSuggestions(request: SuggestionsFetchRequestedParams) {
        const suggestionResponse = await loadSuggestions(request.value, branding.suggestBoxContent.suggestGroups)
        const newSuggestGroups: SuggestGroup[] = []

        // Iterate over configuration to preserve the order
        for (const suggestGroupConfig of branding.suggestBoxContent.suggestGroups) {
            switch (suggestGroupConfig.type) {
                case GroupConfigType.collections:
                    const collectionSuggestGroup = getCollectionSuggestGroup(suggestGroupConfig, request.value, strings)
                    if (collectionSuggestGroup) newSuggestGroups.push(collectionSuggestGroup)
                    break
                default:
                    if (suggestionResponse.suggestGroups) {
                        for (let suggestGroup of suggestionResponse.suggestGroups) {
                            if (suggestGroup.id !== suggestGroupConfig.id) continue
                            handleLoadedSuggestGroup(suggestGroup, strings, request)
                            newSuggestGroups.push(suggestGroup)
                        }
                    }
            }
        }

        const showAllTitle = strings.suggestBoxContent.showAllBtn.text
        searchAllSuggestGroup.suggestions[0].value = request.value
        searchAllSuggestGroup.suggestions[0].title = showAllTitle

        newSuggestGroups.push(searchAllSuggestGroup)
        setSuggestGroups(newSuggestGroups)
    }

    function onCloseButtonClick(e: any) {
        suggestState.clearSuggestions()
        suggestState.setVisible(false)
        e.stopPropagation()
    }

    return (
        <StyledAutoSuggestWrapper ref={wrapperRef} emptySearch={suggestState.suggestions?.length < 1}>
            <DummySearchIconWrapper>{IconSearch({ fill: "currentColor" })}</DummySearchIconWrapper>
            {
                // @ts-ignore
                <Autosuggest
                    theme={theme}
                    multiSection={true}
                    suggestions={suggestGroups}
                    onSuggestionsFetchRequested={fetchSuggestions}
                    onSuggestionsClearRequested={() => setSuggestGroups([])}
                    getSuggestionValue={(suggestion: any) => suggestion.title}
                    renderSectionTitle={(section: SuggestGroup) => renderSectionTitle(section, strings)}
                    renderSuggestion={renderSuggestion}
                    renderInputComponent={(inputProps: any) =>
                        renderInputComponent(inputProps, suggestState.suggestions, suggestState.removeSuggestion)
                    }
                    getSectionSuggestions={(section: SuggestGroup) => section.suggestions}
                    onSuggestionSelected={(_event, data) => {
                        suggestState.setOverlayVisible(false)

                        // if using original suggestion object, then old search value is overwritten by the new one (when clicks on search all button)
                        const suggestion: any = { ...data.suggestion }
                        let showMoreEntities = false
                        // redirecting user to entity detail page
                        switch (suggestion.id) {
                            case StaticSuggestionId.SHOWMORE_ENTITIES:
                                if (suggestion.type !== "category" || suggestion.type !== "country") {
                                    suggestion.title = suggestion.value
                                    showMoreEntities = true
                                }
                                break
                            case StaticSuggestionId.COLLECTION:
                                history.push({
                                    pathname: collectionRoute.replace(":id", suggestion.value),
                                    search: history.location.search
                                })
                                suggestState.setVisible(false)
                                return
                            case StaticSuggestionId.SHOWMORE_COLLECTION:
                                history.push({
                                    pathname: collectionOverviewRoute,
                                    search: history.location.search
                                })
                                suggestState.setVisible(false)
                                return
                            default:
                                if (suggestion.type) {
                                    history.push({
                                        pathname: buildDetailLink(suggestion.id, suggestion.title, suggestion.type),
                                        search: history.location.search
                                    })
                                    suggestState.setVisible(false)
                                    return
                                }
                        }

                        setInputValue("")
                        suggestion.active = true
                        suggestState.addSuggestion(suggestion, showMoreEntities)

                        // redirecting user to result page (if he is not already there)
                        if (window.location.href.indexOf(globalSearchResultPageRoute) === -1)
                            history.push(globalSearchResultPageRoute)
                    }}
                    inputProps={inputProps}
                />
            }

            <ClearAndSearchAllWrapper>
                <MobileVersionContainer>
                    <DummyCloseIconWrapper>
                        <div onTouchStartCapture={onCloseButtonClick} onClick={onCloseButtonClick}>
                            {IconClose({ fill: "currentColor" })}
                        </div>
                    </DummyCloseIconWrapper>
                </MobileVersionContainer>
                <DesktopVersionContainer>
                    <ClearAllBtn
                        emptySearch={suggestState.suggestions?.length < 1}
                        onClick={() => suggestState.clearSuggestions()}
                    >
                        {strings.suggestBoxContent.clearAllBtn.text}
                    </ClearAllBtn>
                </DesktopVersionContainer>

                <SearchAllBtn onClick={() => startSearch(inputProps.value, inputProps.ref.current)}>
                    {strings.suggestBoxContent.searchAllBtn.text}
                </SearchAllBtn>
            </ClearAndSearchAllWrapper>
        </StyledAutoSuggestWrapper>
    )
})

export default SuggestBox

function handleLoadedSuggestGroup(
    suggestGroup: SuggestGroup,
    strings: Branding,
    request: Autosuggest.SuggestionsFetchRequestedParams
) {
    const type = getEntityTypeForTrackingName(suggestGroup.trackingName)

    // If it's not a category and country, the suggestion are enriched by a show more button
    if (type !== null && type !== "category" && type !== "country") {
        for (let suggestion of suggestGroup.suggestions) {
            suggestion.type = type
        }
        const title = getShowMoreTitleForEntityType(
            strings.suggestBoxContent.suggestGroups.find((x) => x.entityType?.toLowerCase() === suggestGroup.trackingName),
            strings
        )
        suggestGroup.suggestions.push({
            id: StaticSuggestionId.SHOWMORE_ENTITIES,
            title: title,
            subtitle: "",
            value: request.value,
            icon: "",
            type: type
        })
    }
}

function getCollectionSuggestGroup(
    collectionSuggestGroupConfig: GroupConfig,
    searchTerm: string,
    strings: Branding
): SuggestGroup | undefined {
    let suggestGroup: SuggestGroup | undefined

    for (const collection of branding.collectionBranding.collections) {
        // If the collection title or description contains the search term, then we add this collection as a suggestion
        if (collection.title.indexOf(searchTerm) >= 0 || collection.description.indexOf(searchTerm) >= 0) {
            // If we didn't yet have a valid suggestion, create the suggestion group
            if (!suggestGroup) {
                suggestGroup = {
                    id: collectionSuggestGroupConfig.id,
                    name: strings.receptionPage.navbarCollectionsPageShortName,
                    maxSuggestions: collectionSuggestGroupConfig.msc,
                    trackingName: "collections",
                    suggestions: []
                }
            }
            suggestGroup.suggestions.push({
                id: StaticSuggestionId.COLLECTION,
                title: collection.title,
                subtitle: collection.description,
                value: collection.id,
                icon: ""
            })
        }
    }
    if (suggestGroup && suggestGroup.suggestions.length > 0) {
        suggestGroup.suggestions.push({
            id: StaticSuggestionId.SHOWMORE_COLLECTION,
            title: strings.suggestBoxContent.showMoreTitleCollections,
            subtitle: "",
            value: "allCollections",
            icon: ""
        })
    }

    return suggestGroup
}
