import { ButtonGroup, ToggleButton } from "react-bootstrap"
import styled from "styled-components"
import branding from "../../../../branding/branding"
import { useLanguageState } from "../../../../globalStates/LanguageState"
import { useVideoContext } from "../../../context/VideoContext"
import { Position } from "../../../processor/LogoProcessor"

export const ButtonWrapper = styled(ButtonGroup)`
    margin-top: 0.5em;

    .btn {
        font-size: 12px;
        line-height: 12px;
        background-color: ${branding.audioVideoSettings.radioLogoButtonBackgroundColor};
        color: ${branding.audioVideoSettings.radioLogoButtonTextColor};
        border-color: #d9d9d9;
        width: 64px;
        box-shadow: none;

        &:focus {
            outline: none;
        }

        &.active {
            background-color: ${branding.audioVideoSettings.radioLogoButtonBackgroundColorActive} !important;
            color: ${branding.audioVideoSettings.radioLogoButtonTextColorActive} !important;
            border-color: ${branding.audioVideoSettings.radioLogoButtonBackgroundColorActive} !important;
        }
    }
`

export function LogoSettingsPosition() {
    const videoContext = useVideoContext()
    const strings = useLanguageState().getStrings().audioVideoSettings

    const positions: { label: string; value: Position }[] = [
        { label: strings.radioLogoPositionTopLeft, value: "topleft" },
        { label: strings.radioLogoPositionTopRight, value: "topright" },
        { label: strings.radioLogoPositionBottomLeft, value: "bottomleft" },
        { label: strings.radioLogoPositionBottomRight, value: "bottomright" }
    ]

    return (
        <ButtonWrapper toggle>
            {positions.map((radio, idx) => (
                <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant="secondary"
                    name="logoPosition"
                    value={radio.value}
                    checked={videoContext.getLogoPosition() === radio.value}
                    onChange={(e) => videoContext.setLogoPosition(e.currentTarget.value as Position)}
                >
                    {radio.label}
                </ToggleButton>
            ))}
        </ButtonWrapper>
    )
}
