import styled from "styled-components"
import TreeMenu from "react-simple-tree-menu"
import "../../../../node_modules/react-simple-tree-menu/dist/main.css"
import branding from "../../../branding/branding"
import { useSuggestContext } from "../../../navigationArea/useSuggestContext"
import { useHistory } from "react-router-dom"
import { globalSearchResultPageRoute } from "../../../navigationArea/RoutePaths"
import { device } from "../../../utils/Device"

const SimpleTreeRoot = styled.div<{ horizontalPadding?: boolean }>`
    padding-bottom: 20px;
    padding-left: ${(props) => (props.horizontalPadding ? "7px" : "0")};
    padding-right: ${(props) => (props.horizontalPadding ? "7px" : "0")};
    & .rstm-tree-item-group {
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-bottom: unset;
        margin: 0;

        & li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }
        & li.rstm-tree-item--active, li.rstm-tree-item--focused {
            background-color: white;
            box-shadow: unset;
            font-weight: 700;
            color: ${branding.categoriesPageContent.selectedCategoryTextColor};
        }
    }

    
    & .rstm-tree-item-level0 {
        color: ${branding.categoriesPageContent.categoriesListInactiveColor};
        font-family: ${branding.categoriesPageContent.categoriesListFont};
    }

    


    @media ${device.mobile} {
        padding-right: 30px;

        & .rstm-tree-item-level0 {
        color: ${branding.categoriesPageContent.categoriesListInactiveColor};
        font-family: ${branding.categoriesPageContent.categoriesListFont};
        font-size: 14px !important;
        }

        & .rstm-tree-item-level1 {
        color: ${branding.categoriesPageContent.categoriesListInactiveColor};
        font-size: 14px !important;
        }   
    }
    }

`
export interface TreeData {
    key: string
    label: string
    nodes?: Array<TreeData>
    code?: string
}

interface SimpleTreeDataProps {
    treeData: Array<TreeData>
    horizontalPadding?: boolean
}

const SimpleTreeComponent = (props: SimpleTreeDataProps) => {
    const suggestState = useSuggestContext()
    const history = useHistory()

   const addCategoryToSearchFilter = (key: string, label: string, props: any) => {
        const formatedKey = key.replace(props.parent + "/", "")
        suggestState.addSuggestion({ icon: "", id: "", subtitle: "", title: label, value: "cat_" + formatedKey, active: true })
        history.push(globalSearchResultPageRoute)
    }

    return (
        <SimpleTreeRoot horizontalPadding={props.horizontalPadding} onClick={() => {}}>
            {props.treeData.length && (
                <TreeMenu
                    data={props.treeData}
                    hasSearch={false}
                    onClickItem={({ key, label , ...props }) => addCategoryToSearchFilter(key, label, props)}
                />
            )}
        </SimpleTreeRoot>
    )
}

export default SimpleTreeComponent
