import { useCallback, useEffect, useState } from "react"
import * as React from "react"
import Select from "react-select"
import styled from "styled-components"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useLanguageState } from "../../globalStates/LanguageState"
import CrsTabs from "../../ui/CrsTabs"
import { IconReset } from "../../ui/Icons"
import { SelectThemeCustom } from "../myprofile/EditMyProfileLayout"
import AutocompleteSearch from "./components/AutocompleteSearch"
import { personFilterType } from "./NetworkingPageContent"
import { viewType, Topic } from "./NetworkingPageContentBranding"
import { HideOnMobileAndIpadContainer } from "../../utils/Device"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { useLoggedInState } from "../../globalStates/LoggedInUser"

const FilterContainer = styled.div<{ showShadow?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 9;
    background-color: white;

    @media (max-width: 1600px) {
        justify-content: space-between;
    }
    .rs-input {
        padding: 10px 0px 8px 0px;
        font-family: ${branding.font1};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: ${branding.networkingArea.filterPlaceholderColor};
    }
`
const FilterContainerGradient = styled.div`
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`

const AutocompleteTopicFilterSection = styled.div`
    /* display: flex;
    align-items: flex-end;
    & .autocomplete-root {
        margin-right: 10px;
    } */

    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    font-size: 12px;
    & .autocomplete-root {
        margin-right: 0px;
    }
`
const FilterBarRoot = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    flex-wrap: wrap;
    margin-bottom: 30px;
`
export interface TopFilterAreaProps {
    currentViewType: viewType
    userAttributeFilter: string
    searchValue: string
    userAttributes: string[]
    topFilterRef: React.RefObject<HTMLDivElement>
    viewTypeData: { value: viewType; label: string }[]
    selectedTopic: number | null
    personTypeData: { value: personFilterType; label: string }[]
    showLoadNewSuggestionsBtn: boolean
    currentDataPage: number
    showShadow?: boolean
    selectedPersonType: personFilterType
    isMatchActive?: boolean
    onHandleSearch(searchValue: string): void
    onSetUserAttributeFilter: (value: React.SetStateAction<string>) => void
    onSetSearchString: (value: React.SetStateAction<string>) => void
    onGraphReset: () => void
    onTopicChanged: (value: number | null) => void
    setCurrentViewType: (value: React.SetStateAction<viewType>) => void
    setUsersWithLoadedConnections: (value: React.SetStateAction<string[]>) => void
    setSelectedPersonType: React.Dispatch<React.SetStateAction<personFilterType>>
    setCurrentDataPage: (value: React.SetStateAction<number>) => void
}

const styledSelectStyles = {
    // eslint-disable-next-line
    control: (provided: any, state: any) => ({
        border: state.isFocused ? "1px solid #727272" : "1px solid #d9d9d9",
        color: state.isFocused ? "red" : "#d9d9d9",
        // border: '1px solid #d9d9d9',
        borderRadius: "5px",
        display: "flex",
        height: "35px",
        marginRight: "0px"
    })
}

const ResetGraphBtn = styled.div<{ width?: string }>`
    display: flex;
    font-family: ${branding.font1};
    height: 35px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: ${"1px solid " + branding.networkingArea.resetGraphBackgroundColor};
    background-color: ${branding.networkingArea.resetGraphBackgroundColor};
    color: ${branding.networkingArea.resetGraphTextColor};
    font-size: 12px;
    cursor: pointer;
    user-select: none;
`

const BottomFilterActionBtn = styled.div`
    display: flex;
    font-family: ${branding.font1};
    height: 35px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: ${branding.networkingArea.loadMoreSuggestionsBorderColor};
    /* border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"}; */
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    /* margin-left: 170px; */
    &:hover {
        color: ${branding.networkingArea.loadMoreSuggestionsOnHoverTextColor};
        background-color: ${branding.networkingArea.loadMoreSuggestionsOnHoverBgColor};
        transition: 0.5s;
    }
    @media (max-width: 1500px) {
        font-size: 12px;
        padding: 0.3rem 1.5rem;
    }
`
const BottomFilterActionText = styled.span`
    color: ${branding.networkingArea.loadMoreSuggestionsTextColor};
    position: relative;
    width: auto;
    text-align: center;
    padding: 0.3rem 1rem;
    display: inline-block;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        color: ${branding.networkingArea.loadMoreSuggestionsOnHoverTextColor};
    }
`

const StyledSelect = styled(Select)`
    width: 230px;
    /* margin-right: 10px; */
    z-index: 10;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
`

const ResetIconSpan = styled.span`
    /* position: relative;
    display: inline-block;
    width: 9px;
    height: 6px; */
    margin-left: 10px;
    /* top: 3px;
    color: ${branding.primaryColor}; */
`

const ResetTextSpan = styled.span<{ width?: string }>`
    position: relative;
    width: ${(props) => props.width};
    text-align: center;
    padding: 0.3rem 1rem;
    display: inline-block;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${branding.networkingArea.resetGraphTextColor};
`

const TopFilterArea = (props: TopFilterAreaProps) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const strings = languageState.getStrings()
    // Topic filter
    let topicFilter = branding.networkingArea.topicFilterActive ? branding.networkingArea.topicFilter : null
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null)
    const [selectedPersonType, setSelectedPersonType] = useState<{ value: personFilterType; label: string } | null>()
    const isNotRelevantAndInterestView =
        props.currentViewType !== viewType.RELEVANT_TILES && props.currentViewType !== viewType.USERS_FILTERED_BY_INTEREST
    const appState = useAppState()
    const { isMobile } = useWindowDimensions()
    // eslint-disable-next-line
    const [selectedTopicStorage, setSelectedTopicStorage] = useState(
        JSON.parse(localStorage.getItem("selectedTopicStorage") || "{}")
    )

    useEffect(() => {
        if (props.selectedTopic && topicFilter?.length) {
            setSelectedTopic({
                value: props.selectedTopic!,
                label: topicFilter.find((x) => x.value === props.selectedTopic)?.label || ""
            })
        } else {
            setSelectedTopic(null)
        }

        // eslint-disable-next-line
    }, [props.selectedTopic])

    useEffect(() => {
        if (Object.keys(selectedTopicStorage).length > 0) {
            setSelectedTopic({ value: selectedTopicStorage?.value || "", label: selectedTopicStorage.label } || "")
            props.onTopicChanged(selectedTopicStorage?.value || null)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let selPersonType = props.personTypeData.find((x) => x.value === props.selectedPersonType)
        if (selPersonType) setSelectedPersonType({ label: selPersonType.label, value: selPersonType.value })
        else setSelectedPersonType(null)

        // eslint-disable-next-line
    }, [props.selectedPersonType, lang])

    const handleSwitcherClickDebounced = useCallback(
        (e: viewType) => {
            props.setCurrentViewType(e)
            props.setUsersWithLoadedConnections([])

            // eslint-disable-next-line
        },
        [props]
    )

    const handleSwitcherClick = (e: viewType) => {
        handleSwitcherClickDebounced(e)
    }

    useEffect(() => {
        if (isMobile) {
            props.setCurrentViewType(viewType.RELEVANT_TILES)
        } else if (appState.lastVisitedTab) {
            props.setCurrentViewType(appState.lastVisitedTab as viewType)
        }
    })

    let userState = useLoggedInState()
    function shouldCompleteProfile(): boolean {
        if (
            !userState.user()?.firstName ||
            !userState.user()?.lastName ||
            !userState.user()?.position ||
            !userState.user()?.company
        ) {
            return true
        }

        return false
    }

    return (
        <FilterContainer ref={props.topFilterRef} showShadow={props.showShadow}>
            {isNotRelevantAndInterestView && <FilterContainerGradient />}
            <HideOnMobileAndIpadContainer>
                <CrsTabs
                    tabItems={props.viewTypeData}
                    activeItem={appState.lastVisitedTab || props.currentViewType}
                    onTabItemClicked={(e: string) => {
                        handleSwitcherClick(e as viewType)
                        appState.setLastVisitedTab(e)
                    }}
                />
            </HideOnMobileAndIpadContainer>

            <AutocompleteTopicFilterSection>
                {(props.isMatchActive || props.currentViewType === viewType.EXPLORE_MY_CON) && !shouldCompleteProfile() && (
                    <FilterBarRoot>
                        <AutocompleteSearch
                            currentViewType={props.currentViewType}
                            searchValue={props.searchValue}
                            userAttributes={props.userAttributes}
                            userAttributeFilter={props.userAttributeFilter}
                            onHandleSearch={props.onHandleSearch}
                            onSetSearchString={props.onSetSearchString}
                            onSetUserAttributeFilter={props.onSetUserAttributeFilter}
                        />

                        {topicFilter?.length && (
                            <StyledSelect
                                styles={styledSelectStyles}
                                placeholder={strings.networkingArea.topicFilterPlaceholder}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                options={topicFilter}
                                value={selectedTopic}
                                onChange={(selectedTopic: Topic) => {
                                    if (selectedTopic) {
                                        props.onTopicChanged(selectedTopic?.value || null)
                                        localStorage.setItem(
                                            "selectedTopicStorage",
                                            JSON.stringify({ value: selectedTopic?.value, label: selectedTopic?.label })
                                        )
                                    } else {
                                        localStorage.removeItem("selectedTopicStorage")
                                        setSelectedTopic(null)
                                        props.onTopicChanged(null)
                                    }
                                }}
                                theme={SelectThemeCustom}
                            />
                        )}

                        {isNotRelevantAndInterestView && (
                            <StyledSelect
                                styles={styledSelectStyles}
                                placeholder={branding.networkingArea.selectedPersonTypeFilterPlaceholder}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                options={props.personTypeData}
                                value={selectedPersonType}
                                onChange={(item: any) => {
                                    props.setSelectedPersonType(item?.value)
                                }}
                                theme={SelectThemeCustom}
                            />
                        )}

                        {isNotRelevantAndInterestView && (
                            <ResetGraphBtn onClick={props.onGraphReset}>
                                <ResetIconSpan>{IconReset({ fill: branding.sideIconBar.sideIconColorLight })}</ResetIconSpan>
                                <ResetTextSpan width={"auto"}>{strings.networkingArea.resetGraphText}</ResetTextSpan>
                            </ResetGraphBtn>
                        )}

                        {props.currentViewType === viewType.RELEVANT_PPL && props.showLoadNewSuggestionsBtn && (
                            <BottomFilterActionBtn onClick={() => props.setCurrentDataPage(props.currentDataPage + 1)}>
                                <BottomFilterActionText>{strings.networkingArea.loadMoreSuggestionsText}</BottomFilterActionText>
                            </BottomFilterActionBtn>
                        )}
                    </FilterBarRoot>
                )}
            </AutocompleteTopicFilterSection>
            {/* Should be a component to be reusable? */}
            <div style={{ height: "5px", backgroundColor: "#fff", position: "relative", width: "100%", marginTop: "-5px" }}></div>
            <div
                style={{
                    transition: "box-shadow .3s ease-in",
                    backgroundColor: "#fff",
                    boxShadow: props.showShadow ? branding.primaryScrollDarkShadowTTB : "none",
                    height: "5px",
                    marginTop: "-1px",
                    width: "100%"
                }}
            ></div>
        </FilterContainer>
    )
}

export default TopFilterArea
