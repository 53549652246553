import { createState, State, useState } from "@hookstate/core"
import { Suggestion } from "../backendServices/Types"
import { useSearchContext } from "../contentArea/useSearchContext"
import { Persistence } from "@hookstate/persistence"
import { useEffect } from "react"
import { globalSearchResultPageRoute } from "./RoutePaths"

const localStorageKey = "suggest-state"

interface SuggestContext {
    addSuggestion: (suggestion: Suggestion, showMoreEntities?: boolean) => void
    clearSuggestions: () => void
    removeSuggestion: (suggestion: Suggestion) => void
    activateSuggestion: (suggestion: Suggestion, active: boolean) => void
    suggestions: Suggestion[]
    setVisible: (visible: boolean) => void
    isVisible: boolean
    setOverlayVisible: (visible: boolean) => void
    isOverlayVisible: boolean
}

interface StateValues {
    visible: boolean
    overlayVisible: boolean
    suggestions: Suggestion[]
}

const useWrapState = (suggestState: State<StateValues>) => {
    const search = useSearchContext()
    state.attach(Persistence(localStorageKey))

    useEffect(() => {
        if (window.location.href.indexOf(globalSearchResultPageRoute) === -1 && suggestState.suggestions.length > 0) {
            clearSuggestion()
        }
        // eslint-disable-next-line
    }, [window.location.href])

    const addSuggestion = (suggestion: Suggestion, showMoreEntities?: boolean) => {
        // Don't want empty suggestions
        if (suggestion.value === "" && !suggestion.type) return

        suggestState.set((prev) => {
            for (const suggestionElem of prev.suggestions) {
                // Don't want multiple suggestions with the same value or empty suggestions
                if (suggestion.value === suggestionElem.value) return prev
            }
            prev.suggestions.push(suggestion)
            return prev
        })

        search.searchFunctions.setSugestions(suggestState.suggestions.value, showMoreEntities)
    }

    const clearSuggestion = () => {
        suggestState.set({ visible: false, suggestions: [], overlayVisible: false })
        search.searchFunctions.setSugestions(suggestState.suggestions.value)
    }

    const removeSuggestion = (suggestion: Suggestion) => {
        suggestState.set((prev) => {
            for (let i = 0; i < prev.suggestions.length; i++) {
                const suggestionElem = prev.suggestions[i]
                if (suggestion.value === suggestionElem.value) {
                    prev.suggestions.splice(i, 1)
                    break
                }
            }
            return prev
        })
        search.searchFunctions.setSugestions(suggestState.suggestions.value)
    }

    const activateSuggestion = (suggestion: Suggestion, active: boolean) => {
        suggestState.set((prev) => {
            for (let i = 0; i < prev.suggestions.length; i++) {
                const suggestionElem = prev.suggestions[i]
                // Don't want multiple suggestions with the same value
                if (suggestion.value === suggestionElem.value) {
                    prev.suggestions[i].active = active
                    break
                }
            }
            return prev
        })
        search.searchFunctions.setSugestions(suggestState.suggestions.value)
    }

    const setVisible = (visible: boolean) => {
        suggestState.set((prev) => {
            prev.visible = visible
            return prev
        })
    }

    const setOverlayVisible = (visible: boolean) => {
        suggestState.set((prev) => {
            prev.overlayVisible = visible
            return prev
        })
    }

    return {
        addSuggestion: addSuggestion,
        removeSuggestion: removeSuggestion,
        activateSuggestion: activateSuggestion,
        clearSuggestions: clearSuggestion,
        suggestions: suggestState.value.suggestions,
        setVisible: setVisible,
        isVisible: suggestState.value.visible || suggestState.value.suggestions.length > 0,
        setOverlayVisible: setOverlayVisible,
        isOverlayVisible: suggestState.value.overlayVisible
    }
}
const state = createState<StateValues>({ visible: false, suggestions: [], overlayVisible: false })
export const useSuggestContext = (): SuggestContext => useWrapState(useState(state))
