/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://tsncs73owzelbcs7dmizmzpff4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_2lQWIOTpj",
    "aws_user_pools_web_client_id": "2kd5t3aar0nhabu92mk7f0ajpa",
    "oauth": {}
};


export default awsmobile;
