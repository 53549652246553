import { useHistory } from "react-router"
import styled from "styled-components"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { systemCheckPageRoute } from "../../navigationArea/RoutePaths"
import LanguageToggler from "../../ui/LanguageToggle"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../../utils/Device"
import { CenterLoginStyleTicketBtn } from "./CenterLoginSharedComponents"

const version = document.getElementById("version")?.getAttribute("data-value")

const SystemCheckContainer = styled.div`
    position: fixed;
    bottom: 70px;
    width: 100%;
    font-family: ${branding.font1};
    text-align: center;

    .btn:focus,
    .btn:active {
        outline: none !important;
        box-shadow: none !important;
    }

    .btn-dark {
        font-size: 0.9rem;
        border-radius: 2rem;
        background-color: ${branding.loginRegistrationSite.centerLoginSystemCheckBackgroundColor ?? "rgba(0,0,0,.5)"};
    }
`

const LoginFooterRoot = styled.div`
    position: fixed;
    display: flex;
    flex-flow: row;
    color: #fff;
    height: 50px;
    width: 100%;
    margin-left: auto;
    right: 0;
    bottom: 0;
`

const LoginFooterLeftArea = styled.div`
    flex-grow: 1;
    padding-top: 10px;
    width: 33.33%;
`

const LoginFooterCenterArea = styled.div`
    width: 33.33%;
    padding-top: 10px;
    text-align: center;
`

const LoginFooterRightArea = styled.div`
    display: flex;
    align-self: flex-end;
    text-align: right;
    white-space: nowrap;
    align-items: center;
    height: 100%;
    width: 33.33%;
    text-align: right;
    justify-content: flex-end;
`

const FooterAppVersionContainer = styled.div`
    color: ${branding.loginRegistrationSite.footerTextColor};
    font-size: 14px;
    padding-top: 5px;
`

const FooterAppVersionContainerMobile = styled.div`
    color: #000;
    width: 100%;
    position: fixed;
    bottom: 8px;
    text-align: center;
    font-size: 12px;
`

const LoginFooterDiv = styled.div`
    padding-right: 20px;

    & a,
    & a:link,
    & a:hover,
    & a:active {
        color: ${branding.loginRegistrationSite.mobileLoginTextColor};
        font-size: 14px;

        @media ${device.tablet} {
            color: ${branding.loginRegistrationSite.footerTextColor};
            font-size: inherit;
        }
    }

    & a:focus {
        outline: none;
        text-decoration: underline;
    }
`

function LoginFooter() {
    const strings = useLanguageState().getStrings()
    const history = useHistory()

    return (
        <>
            <DesktopVersionContainer>
                <SystemCheckContainer>
                    <CenterLoginStyleTicketBtn onClick={() => history.push(systemCheckPageRoute)}>
                        {strings.ticketSale.checkYourSystemButton}
                    </CenterLoginStyleTicketBtn>
                </SystemCheckContainer>
                <LoginFooterRoot>
                    <LoginFooterLeftArea>
                        <LanguageToggler color={branding.loginRegistrationSite.footerTextColor} />
                    </LoginFooterLeftArea>
                    <LoginFooterCenterArea>
                        <FooterAppVersionContainer>Version {version}</FooterAppVersionContainer>
                    </LoginFooterCenterArea>
                    <LoginFooterRightArea>
                        {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter && (
                            <>
                                <LoginFooterDiv>
                                    {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter &&
                                        branding.loginRegistrationSite.showTermsOfUseInFooter && (
                                            <a
                                                tabIndex={0}
                                                href={strings.loginRegistrationSite.termsOfUseLinkUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {strings.loginRegistrationSite.termsOfUseLink}
                                            </a>
                                        )}
                                </LoginFooterDiv>
                                <LoginFooterDiv>
                                    {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter &&
                                        branding.loginRegistrationSite.showPrivacyPolicyInFooter && (
                                            <a
                                                tabIndex={0}
                                                href={strings.loginRegistrationSite.privacyPolicyLinkUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {strings.loginRegistrationSite.privacyPolicyLink}
                                            </a>
                                        )}
                                </LoginFooterDiv>
                                <LoginFooterDiv>
                                    {branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter &&
                                        branding.loginRegistrationSite.showImprintInFooter && (
                                            <a
                                                tabIndex={0}
                                                href={strings.loginRegistrationSite.imprintLinkUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {strings.loginRegistrationSite.imprintLink}
                                            </a>
                                        )}
                                </LoginFooterDiv>
                            </>
                        )}
                    </LoginFooterRightArea>
                </LoginFooterRoot>
            </DesktopVersionContainer>
            <MobileVersionContainer>
                <MobileLoginFooterRoot>
                    <MobileLoginFooterContainer onClick={() => history.push(systemCheckPageRoute)}>
                        {strings.ticketSale.checkYourSystemButton}
                    </MobileLoginFooterContainer>
                    <FooterAppVersionContainerMobile>Version {version}</FooterAppVersionContainerMobile>
                </MobileLoginFooterRoot>
            </MobileVersionContainer>
        </>
    )
}

const MobileLoginFooterRoot = styled.div`
    bottom: 10px;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fff !important;
`

const MobileLoginFooterContainer = styled.div`
    text-decoration: underline;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
    font-family: ${branding.font1};
    font-size: 14px;
    text-align: center;
    margin-bottom: 14px;
    cursor: pointer;
`

export default LoginFooter
