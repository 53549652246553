import { useState, useRef, useEffect } from "react"
import * as React from "react"
import {
    CenterLoginTextFieldContainer,
    CenterLoginStyleSubmitButton,
    CenterLoginStyleHeader,
    CenterLoginStyleHeaderTitle,
    CenterLoginStyleHeaderSubitle,
    CenterLoginStyleInputContainer,
    CenterLoginStyleLoaderAttributes,
    MobileCenterLoginStyleHeaderTitle,
    CenterLoginStyleTicketBtn
} from "./CenterLoginSharedComponents"
import { TextField } from "../../ui/TextField"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import CenteredLoader from "../../ui/CenteredLoader"
import { personalizeTicketHelper } from "./LoginHelper"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { ProfileResponseStatus } from "./LoginRegistrationSite"
import LoginError from "./LoginError"
import styled from "styled-components"
import { device, DesktopVersionContainer, MobileVersionContainer } from "../../utils/Device"
import { useHistory } from "react-router-dom"
import { useAppState } from "../../globalStates/AppState"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { useFavoriteState } from "../../globalStates/Favorites"
import { logoutUser } from "../../communicationArea/settings/SettingsTab"

const TicketShopUrlsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    position: relative;
    top: 200px;
    flex-wrap: wrap;
    row-gap: 5px;

    @media ${device.tablet} {
        top: 220px;
        width: 600px;
        padding: 0px;
        top: 300px;
        flex-direction: column;
        align-items: center;
    }

    @media ${device.laptop} {
        top: 220px;
        width: 800px;
        padding: 0px;
        top: 320px;
    }

    // temp. solution, whole LoginRegistrationSite needs design refactoring
    @media (max-height: 1050px) and (min-width: 768px) {
        flex-direction: row;
        top: 220px;
    }
`

const TicketShopUrl = styled.div`
    font-family: ${branding.font1};
    font-size: 16px;
    width: 100%;

    & a {
        color: ${branding.loginRegistrationSite.ticketShopUrlColor ?? "#000"};
    }

    @media ${device.tablet} {
        width: auto;
    }
`

const TicketCodeComponent: React.FC = (props) => {
    const strings = useLanguageState().getStrings()
    const form = useRef<HTMLFormElement>(null)
    const ticketShopUrls = strings.loginRegistrationSite.ticketShopUrls.slice(0, 6) // max. allowed number of links are 6, to prevent unexpected layout issues
    const [isLoaded, setIsLoaded] = useState(true)

    return (
        <>
            <form ref={form} autoComplete="on">
                <DesktopVersionContainer>
                    <CenterLoginStyleHeader>
                        <CenterLoginStyleHeaderTitle>
                            {strings.loginRegistrationSite.loginRegistrationTitle}
                        </CenterLoginStyleHeaderTitle>
                        <CenterLoginStyleHeaderSubitle>
                            {strings.loginRegistrationSite.loginRegistrationSubtitle}
                        </CenterLoginStyleHeaderSubitle>
                    </CenterLoginStyleHeader>
                </DesktopVersionContainer>
                <CenterLoginStyleInputContainer className={!isLoaded ? "loading" : ""}>
                    {!isLoaded && <CenteredLoader loaderWrapperStyle={CenterLoginStyleLoaderAttributes}></CenteredLoader>}
                    <p style={{ textAlign: "left", order: 1 }}>
                        {strings.loginRegistrationSite.centerLoginStyleTicketInputTitle}
                    </p>

                    <MobileVersionContainer>
                        <MobileCenterLoginStyleHeaderTitle>
                            {strings.loginRegistrationSite.loginRegistrationTitle}
                        </MobileCenterLoginStyleHeaderTitle>
                    </MobileVersionContainer>

                    <TicketInput setIsLoaded={setIsLoaded} form={form} />
                </CenterLoginStyleInputContainer>
            </form>

            <TicketShopUrlsWrapper>
                {ticketShopUrls.map((item, index) => {
                    return (
                        <TicketShopUrl key={index}>
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                {item.label}
                            </a>
                        </TicketShopUrl>
                    )
                })}
            </TicketShopUrlsWrapper>
        </>
    )
}

const LogoutButton = styled(CenterLoginStyleTicketBtn)`
    width: 100%;
    margin: 10px 0;
    background-color: ${branding.loginRegistrationSite.ticketBarrierPageLogoutBtnBgColor};
    border: ${branding.loginRegistrationSite.ticketBarrierPageLogoutBtnBorder};
    color: ${branding.loginRegistrationSite.ticketBarrierPageLogoutBtnTextColor};

    &:hover,
    &:active,
    &:focus {
        background-color: ${branding.loginRegistrationSite.ticketBarrierPageLogoutBtnOnHoverBgColor} !important;
        border: ${branding.loginRegistrationSite.ticketBarrierPageLogoutBtnBorder} !important;
        color: ${branding.loginRegistrationSite.ticketBarrierPageLogoutBtnOnHoverTextColor} !important;
        outline: none;
        box-shadow: none;
    }

    @media ${device.tablet} {
        width: 250px;
        margin: 1rem auto;
    }
`

interface TicketInputProps {
    setIsLoaded: (value: boolean) => void
    form: React.RefObject<HTMLFormElement>
}
const TicketInput: React.FC<TicketInputProps> = (props) => {
    const loggedInContext = useLoggedInState()
    const history = useHistory()
    const appState = useAppState()
    const chime = useChimeContext()
    const favoriteState = useFavoriteState()
    const strings = useLanguageState().getStrings()
    const loginAbortController = new AbortController()
    const [ticketCode, setTicketCode] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [response, setResponse] = useState(ProfileResponseStatus.NONE)

    useEffect(() => {
        return () => {
            loginAbortController.abort()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (props.form.current) {
            const onSubmitButtonClicked = (ev: Event) => {
                ev.preventDefault()
                if (submitDisabled) return

                props.setIsLoaded(false)
                setSubmitDisabled(true)
                const ticketCodeResponse = personalizeTicketHelper(loggedInContext, ticketCode)
                ticketCodeResponse.then((resp) => {
                    if (!loginAbortController.signal.aborted) {
                        setSubmitDisabled(false)
                        props.setIsLoaded(true)
                        setResponse(resp)
                    }
                    localStorage.removeItem("logoutReason")
                })
            }

            props.form.current.addEventListener("submit", onSubmitButtonClicked as any)

            return () => {
                if (props.form.current) {
                    props.form.current.removeEventListener("submit", onSubmitButtonClicked as any)
                }
            }
        }
    }, [props.form, submitDisabled, ticketCode]) // eslint-disable-line

    useEffect(() => {
        const disabled = !ticketCode || ticketCode === ""
        if (disabled !== submitDisabled)
            // Prevent rerenders
            setSubmitDisabled(disabled)
    }, [ticketCode]) // eslint-disable-line

    return (
        <>
            <CenterLoginTextFieldContainer style={{ order: 1 }}>
                <TextField
                    placeholder={strings.loginRegistrationSite.ticketCodeInputPlaceholder}
                    setValue={setTicketCode}
                    name="ticketCode"
                    width="100%"
                    fontSize="16px"
                    paddingLeft="8px"
                    borderRadius="5px"
                    height="45px"
                    backgroundColor={branding.loginRegistrationSite.loginInputMailBackgroundColor}
                    borderAround={"1px solid " + branding.loginRegistrationSite.loginInputMailTextColor}
                    outline="1px solid"
                    textColor={branding.loginRegistrationSite.loginInputMailTextColor}
                    fontFamily={branding.font1}
                />
            </CenterLoginTextFieldContainer>

            <div style={{ order: 2 }}>
                <LoginError profileResponseStatus={response} />
            </div>

            <CenterLoginStyleSubmitButton id="loginSubmit" type="submit" style={{ order: 3 }} disabled={submitDisabled}>
                {strings.loginRegistrationSite.submitTokenButton}
            </CenterLoginStyleSubmitButton>

            <LogoutButton
                style={{ order: 4 }}
                onClick={() => logoutUser(loggedInContext, history, appState, favoriteState, chime)}
            >
                {strings.loginRegistrationSite.ticketBarrierPageLogoutBtnLabel}
            </LogoutButton>
        </>
    )
}

export default TicketCodeComponent
