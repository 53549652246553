import { useEffect, useState } from "react"
import * as React from "react"
import { Tooltip } from "react-bootstrap"
import styled from "styled-components"
import { isUserEdited } from "../App"
import { syncFavorites } from "../backendServices/FavoritesServices"
import { Category, ModalType, ShareTargetType } from "../backendServices/Types"
import branding from "../branding/branding"
import { ChatConversationParam } from "../communicationArea/ChatPage"
import ConfirmCall, { createActions } from "../communicationArea/CommunicationOptions"
import { useContactState } from "../communicationArea/ContactState"
import { useChimeContext } from "../conference/context/ChimeContext"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { buildDetailLink, DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../globalStates/AppState"
import { useFavoriteState } from "../globalStates/Favorites"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../utils/Device"
import { isExplorationOrPostEventPhase, isPostEventPhase } from "../utils/EventPhaseChecker"
import { AvatarWithDefault } from "./AvatarWithDefault"
import AvatarWithPresenceState from "./AvatarWithPresenceState"
import BadgeArea, { CategoryBadge } from "./BadgeArea"
import BookmarkWithToggle from "./BookmarkWithToggle"
import { useCalendarEntryModal, CalendarEntryModalViewMode } from "./CalendarEntryModal"
import ContextMenu from "./ContextMenu"
import {
    IconCamera,
    IconCameraOff,
    IconChat,
    IconHide,
    IconMeetingSchedule,
    IconRemoveStaff,
    IconShare,
    IconShow,
    IconThreeDotsMenuVertical,
    IconBookmarkFilled,
    IconBookmarkToggle
} from "./Icons"
import { useRecommendModal } from "./RecommendModal"
import moment from "moment"
import { CustomOverlayTrigger } from "./CustomOverlayTrigger"
import { useRosterContext } from "../conference/context/RosterContext"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"
import { loadUserData, setOrgaPersonSettings, Settings, UserResponse } from "../backendServices/SeriesOfTopicsUserServices"

export enum StaffType {
    PERSON,
    USER
}

export interface Staff {
    firstName: string
    lastName: string
    id: string
    type: StaffType
    logoUrl: string
    position: string
    company: string
    sotUserId?: string
    visible: boolean
    showroomStandby: boolean
    title: string
    categories: Category[]
}

const StaffEntryRoot = styled.div`
    border-bottom: 1px solid ${branding.listDividerColor};

    &.lastEntry {
        border-bottom: none;
    }
`

const StaffEntryContainer = styled.div<{ marginBottom: string }>`
    padding: 25px 0;
    display: flex;

    &.disabled {
        cursor: auto;
        color: gray;
        pointer-events: none;
    }

    @media ${device.mobile} {
        margin-bottom: ${(props) => props.marginBottom};
    }
`

const StyledDetailNaviLink = styled(DetailNavLink)`
    margin-top: 7px;
    margin-bottom: 8px;
`

const StaffContent = styled.div`
    padding-left: 25px;
    margin-left: 2px;
`

const OrganizationNameLabel = styled.div<{ leadFinder?: boolean }>`
    color: ${(props) =>
        props.leadFinder
            ? branding.businessAreaPageContent.listViewTitleColor
            : branding.organizationDetailPageContent.staffListViewTitleColor} !important;
    font-family: ${branding.organizationDetailPageContent.staffListViewTitleFont} !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    font-weight: bold;
    margin-bottom: 10px;

    @media ${device.mobile} {
        margin-bottom: 3px;
        margin-top: 6px;
    }
`

const OrganizationPositionLabel = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: ${branding.mainInfoColor};
    line-height: 0.8rem;

    @media ${device.mobile} {
        font-size: 12px;
        line-height: 16px;
    }
`

export const MenuPanel = styled.div`
    display: flex;
    margin-left: auto;
    color: ${branding.primaryColor};
`

export const MenuButton = styled.div`
    cursor: pointer;
    margin-bottom: auto;
    margin-left: 20px;
    height: 25px;
    width: 25px;

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }
`

interface StaffEntryProps {
    staff: Staff
    organizationId: string
    organizationName?: string
    backOffice: boolean
    removeStaffFromOrga?: (staff: Staff) => void //only for backoffice
    onAccessDenied?: () => void
    leadFinder?: boolean // only for lead finder
    speaker?: boolean // for event & eventdate detail pages
    setModalType?: (type: ModalType) => void // only for lead finder page
    setSelectedUser?: (value: any) => void // only for lead finder page

    lastEntry?: boolean
}

export const StaffEntry: React.FunctionComponent<StaffEntryProps> = (props) => {
    const loggedInState = useLoggedInState()
    const user = loggedInState.user()
    const profileId = loggedInState.user()?.profileId || ""
    const appState = useAppState()
    const meeting = useMeetingContext()
    const strings = useLanguageState().getStrings()
    const [staff, setStaff] = useState<Staff>(props.staff)
    const [showMeAsContactToggled, setShowMeAsContactToggled] = useState<boolean>(props.staff.visible)
    const [showShowroomStandby, setShowShowroomStandby] = useState<boolean>(props.staff.showroomStandby)
    const [showCall, setShowCall] = useState(false)
    const { showRecommendModal: showRecommendOrganizationModal, RecommendModal: OrganizationModal } = useRecommendModal()
    const { showCalendarEntryModal, CalendarModal } = useCalendarEntryModal()
    const [isBlocked, setIsBlocked] = useState<boolean>(false)

    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const [contextMenuOpened, setContextMenuOpened] = useState<boolean>(false)

    const chime = useChimeContext()
    const roster = useRosterContext()
    const contactState = useContactState()
    var connectionStatus = props.staff.sotUserId ? contactState.getConnectionStatus(props.staff.sotUserId) : "UNRELATED"

    const unrelated: boolean = connectionStatus === "UNRELATED" || connectionStatus === undefined

    const favoriteState = useFavoriteState()

    const isBookmarked =
        favoriteState.is("person", props.staff.id) ||
        favoriteState.is("person", props.staff.sotUserId ?? props.staff.id) ||
        favoriteState.is("sotuser", props.staff.sotUserId ?? props.staff.id) ||
        favoriteState.is("sotuser", props.staff.id)

    async function loadConnectionStatus() {
        const response = await loadUserData({
            targetProfileId: props.staff.sotUserId ?? props.staff.id,
            loggedInUserId: profileId
        })

        if ((response as BackendServiceError).httpStatus) {
            return
        }

        setIsBlocked(
            (response as UserResponse).content.myConnectionStatus === "BLOCKING" ||
                (response as UserResponse).content.myConnectionStatus === "BLOCKED"
        )
    }

    const link = buildDetailLink(
        props.staff.id,
        [props.staff.firstName, props.staff.lastName].filter(Boolean).join(" "),
        "person"
    )

    useEffect(() => {
        loadConnectionStatus()
        // eslint-disable-next-line
    }, [])

    const menuCallback: (param: { modalType?: ModalType }) => void = ({ modalType }) => {
        if (modalType && props.setModalType) {
            props.setModalType(modalType!)
        }
    }

    const staffName = [staff.title, staff.firstName, staff.lastName].filter(Boolean).join(" ")
    const staffPosition: string = [staff.position, staff.company]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")

    function chatWithUser() {
        if (
            loggedInState.user() &&
            loggedInState.user()?.invitingOrganization &&
            loggedInState.user()?.invitingOrganization?.id
        ) {
            if (props.onAccessDenied) {
                props.onAccessDenied()
            }
            return
        }

        appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(staff.id))
    }

    function callUser() {
        if (
            loggedInState.user() &&
            loggedInState.user()?.invitingOrganization &&
            loggedInState.user()?.invitingOrganization?.id
        ) {
            if (props.onAccessDenied) {
                props.onAccessDenied()
            }
            return
        }

        meeting.sendInvite(staff.id)
    }

    function setSettingVisible() {
        ;(async () => {
            const profileId = staff.sotUserId

            if (profileId === undefined) {
                return
            }

            const response = await setOrgaPersonSettings(profileId as string, props.organizationId, {
                visibleAsContact: !showMeAsContactToggled,
                showroomStandby: showShowroomStandby
            })

            if ((response as BackendServiceError).httpStatus) {
            } else {
                setShowMeAsContactToggled((response as Settings).visibleAsContact)
            }
        })()
    }

    function setSettingShowroomStandby() {
        ;(async () => {
            const profileId = staff.sotUserId

            if (profileId === undefined) {
                return
            }

            const response = await setOrgaPersonSettings(profileId as string, props.organizationId, {
                visibleAsContact: showMeAsContactToggled,
                showroomStandby: !showShowroomStandby
            })

            if ((response as BackendServiceError).httpStatus) {
            } else {
                setShowShowroomStandby((response as Settings).showroomStandby)
            }
        })()
    }

    async function loadUser() {
        if (props.staff.type === StaffType.PERSON && props.staff.sotUserId && profileId) {
            const mergedStaff = {
                ...props.staff,
                type: StaffType.USER,
                id: props.staff.sotUserId
            }
            setStaff(mergedStaff)
        } else {
            setStaff(props.staff)
        }
    }

    useEffect(() => {
        loadUser()
    }, [props.staff.id]) // eslint-disable-line react-hooks/exhaustive-deps

    function onBookmark() {
        if (props.staff && user) {
            let date = new Date()
            date.setMinutes(date.getMinutes() - 1)
            favoriteState.toggle("person", props.staff.id)
            const isFavorite = favoriteState.is("person", props.staff.id)
            syncFavorites({
                profileId: user.profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [
                        {
                            id: props.staff.id,
                            kind: "PERSON",
                            deleted: !isFavorite,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }
                    ]
                }
            }).then(() => {
                favoriteState.setLastSyncTime(new Date())
            })
        }
    }

    function getSpeakerMenuItems() {
        return [
            {
                title: strings.organizationDetailPageContent.bookmark,
                icon: isBookmarked
                    ? branding.defaultToggleIcon
                        ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark })
                        : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark })
                    : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }),
                onClick: () => onBookmark()
            },
            {
                title: strings.organizationDetailPageContent.recommend,
                icon: IconShare({ fill: branding.sideIconBar.sideIconColorDark }),
                onClick: () => showRecommendModal(ShareTargetType.PERSON, props.staff.id, link)
            }
        ]
    }

    return (
        <StaffEntryRoot className={props.lastEntry ? "lastEntry" : ""}>
            <StaffEntryContainer className={isBlocked ? "disabled" : ""} marginBottom={contextMenuOpened ? "-20px" : "-40px"}>
                <div style={{ display: "flex", width: "100%" }}>
                    <MobileVersionContainer>
                        <div style={{ width: "60px" }}>
                            <StyledDetailNaviLink
                                id={staff.id}
                                type={staff.type === StaffType.USER ? "user" : "person"}
                                name={`/person/${staff.firstName}_${staff.lastName}`}
                            >
                                <AvatarWithDefault
                                    size={60}
                                    src={staff.logoUrl}
                                    alt={[staff.firstName, staff.lastName].filter(Boolean).join(" ") ?? "#"}
                                    backgroundSize="cover"
                                />
                            </StyledDetailNaviLink>
                        </div>
                    </MobileVersionContainer>

                    <DesktopVersionContainer>
                        <div style={{ width: "95px" }}>
                            <StyledDetailNaviLink
                                id={staff.id}
                                type={staff.type === StaffType.USER ? "user" : "person"}
                                name={`/person/${staff.firstName}_${staff.lastName}`}
                            >
                                <AvatarWithPresenceState
                                    userId={staff.id}
                                    avatarSize={101}
                                    showAvatarBadge={true}
                                    badgeSize={20}
                                    badgeRight={-40}
                                    badgeTop={70}
                                    content={{
                                        pictureUrl: staff.logoUrl,
                                        alt: [staff.firstName, staff.lastName].filter(Boolean).join(" ")
                                    }}
                                />
                            </StyledDetailNaviLink>
                        </div>
                    </DesktopVersionContainer>

                    <div>
                        <StaffContent>
                            <DetailNavLink
                                id={staff.id}
                                type={staff.type === StaffType.USER ? "user" : "person"}
                                name={`/person/${staff.firstName}_${staff.lastName}`}
                            >
                                <OrganizationNameLabel
                                    leadFinder={props.leadFinder}
                                    style={{
                                        color: isBlocked
                                            ? branding.organizationDetailPageContent.blockedStaffIconColor
                                            : branding.businessAreaPageContent.listViewTitleColor,
                                        fontFamily: branding.businessAreaPageContent.listViewTitleFont
                                    }}
                                >
                                    {staffName}
                                </OrganizationNameLabel>
                                <OrganizationPositionLabel
                                    style={{
                                        color: isBlocked
                                            ? branding.organizationDetailPageContent.blockedStaffIconColor
                                            : branding.mainInfoColor
                                    }}
                                >
                                    {staffPosition}
                                </OrganizationPositionLabel>
                                {staff.categories && <BadgeArea categories={staff.categories} />}
                            </DetailNavLink>
                        </StaffContent>
                    </div>
                </div>
                <MobileVersionContainer>
                    {isUserEdited(loggedInState) && (
                        <MenuPanel>
                            <MenuButton
                                style={{ marginTop: "15px", paddingLeft: "13px" }}
                                onClick={() => setContextMenuOpened(!contextMenuOpened)}
                            >
                                {IconThreeDotsMenuVertical({ fill: branding.sideIconBar.sideIconColorDark })}
                            </MenuButton>
                        </MenuPanel>
                    )}
                </MobileVersionContainer>
                <DesktopVersionContainer>
                    <MenuPanel>
                        {props.backOffice && staff.type === StaffType.USER && profileId === staff.id && (
                            <div style={{ width: "100px", fontSize: "12px", display: "flex", justifyContent: "flex-end" }}>
                                <CategoryBadge
                                    marginTop="0px"
                                    bgImage=""
                                    bgColor="#fff"
                                    borderColor={branding.listDividerColor}
                                    textColor={branding.mainInfoColor}
                                >
                                    {strings.backofficeStaff.myBadgeText}
                                </CategoryBadge>
                            </div>
                        )}
                        {props.backOffice && staff.type === StaffType.USER && profileId !== staff.id && (
                            <CustomOverlayTrigger
                                trigger={["click", "focus", "hover"]}
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip-visible" style={{ fontFamily: branding.font1 }}>
                                        {showMeAsContactToggled
                                            ? strings.backofficeStaff.showMeAsContactDeactivateHoverText
                                            : strings.backofficeStaff.showMeAsContactHoverText}
                                    </Tooltip>
                                }
                            >
                                <MenuButton onClick={() => setSettingVisible()}>
                                    {showMeAsContactToggled
                                        ? IconShow({
                                              fill: isBlocked
                                                  ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                  : branding.sideIconBar.sideIconColorDark,
                                              width: "23",
                                              height: "23"
                                          })
                                        : IconHide({
                                              fill: isBlocked
                                                  ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                  : branding.sideIconBar.sideIconColorDark,
                                              width: "23",
                                              height: "23"
                                          })}
                                </MenuButton>
                            </CustomOverlayTrigger>
                        )}
                        {props.backOffice && staff.type === StaffType.USER && profileId !== staff.id && (
                            <CustomOverlayTrigger
                                trigger={["click", "focus", "hover"]}
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip-showroomStandby" style={{ fontFamily: branding.font1 }}>
                                        {showShowroomStandby
                                            ? strings.backofficeStaff.showShoowroomStandbyDeactivateHoverText
                                            : strings.backofficeStaff.showShoowroomStandbyHoverText}
                                    </Tooltip>
                                }
                            >
                                <MenuButton onClick={() => setSettingShowroomStandby()}>
                                    {showShowroomStandby
                                        ? IconCamera({
                                              fill: isBlocked
                                                  ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                  : branding.sideIconBar.sideIconColorDark,
                                              width: "25",
                                              height: "25"
                                          })
                                        : IconCameraOff({
                                              fill: isBlocked
                                                  ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                  : branding.sideIconBar.sideIconColorDark,
                                              width: "25",
                                              height: "25"
                                          })}
                                </MenuButton>
                            </CustomOverlayTrigger>
                        )}
                        {staff.type === StaffType.USER &&
                            profileId !== staff.id &&
                            isUserEdited(loggedInState) &&
                            (!unrelated || (unrelated && loggedInState.isMatchActive())) && (
                                <CustomOverlayTrigger
                                    trigger={["click", "focus", "hover"]}
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip-chat" style={{ fontFamily: branding.font1 }}>
                                            {strings.backofficeStaff.startChat}
                                        </Tooltip>
                                    }
                                >
                                    <MenuButton
                                        onClick={() => {
                                            if (props.backOffice) chatWithUser()
                                            else
                                                appState.setShowChatsTab(
                                                    ChatConversationParam.privateConversationByOpponentId(staff.sotUserId!)
                                                )
                                        }}
                                    >
                                        {IconChat({
                                            fill: isBlocked
                                                ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                : branding.sideIconBar.sideIconColorDark
                                        })}
                                    </MenuButton>
                                </CustomOverlayTrigger>
                            )}
                        {staff.type === StaffType.USER &&
                            profileId !== staff.id &&
                            isUserEdited(loggedInState) &&
                            (!unrelated || (unrelated && loggedInState.isMatchActive())) && (
                                <CustomOverlayTrigger
                                    trigger={["click", "focus", "hover"]}
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip-call" style={{ fontFamily: branding.font1 }}>
                                            {strings.backofficeStaff.startCall}
                                        </Tooltip>
                                    }
                                >
                                    <MenuButton
                                        className={isExplorationOrPostEventPhase ? "disabled" : ""}
                                        onClick={() => {
                                            const meetingParam = meeting.getCurrentMeetingParam()

                                            if (meetingParam) {
                                                setShowCall(true)
                                            } else {
                                                callUser()
                                            }
                                        }}
                                    >
                                        {IconCamera({
                                            fill: isBlocked
                                                ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                : branding.sideIconBar.sideIconColorDark,
                                            width: "25",
                                            height: "25"
                                        })}
                                    </MenuButton>
                                </CustomOverlayTrigger>
                            )}
                        {!props.backOffice &&
                            staff.type === StaffType.USER &&
                            profileId !== staff.id &&
                            (!unrelated || (unrelated && loggedInState.isMatchActive())) && (
                                <CustomOverlayTrigger
                                    trigger={["click", "focus", "hover"]}
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip-meeting" style={{ fontFamily: branding.font1 }}>
                                            {strings.backofficeStaff.requestMeeting}
                                        </Tooltip>
                                    }
                                >
                                    <MenuButton
                                        className={isPostEventPhase ? "disabled" : ""}
                                        onClick={() => {
                                            if (props.leadFinder && props.setModalType && props.setSelectedUser) {
                                                props.setModalType("meeting")
                                                props.setSelectedUser(staff)
                                            } else
                                                showCalendarEntryModal({
                                                    organizationId: props.organizationId,
                                                    organizationName: props.organizationName,
                                                    sotUser: [
                                                        {
                                                            id: staff.sotUserId ? staff.sotUserId : "",
                                                            firstName: staff.firstName,
                                                            lastName: staff.lastName,
                                                            logoUrl: staff.logoUrl
                                                        }
                                                    ],
                                                    viewMode: CalendarEntryModalViewMode.CREATE
                                                })
                                        }}
                                    >
                                        {IconMeetingSchedule({
                                            fill: isBlocked
                                                ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                : branding.sideIconBar.sideIconColorDark
                                        })}
                                    </MenuButton>
                                </CustomOverlayTrigger>
                            )}
                        {!props.backOffice && (
                            <CustomOverlayTrigger
                                trigger={["click", "focus", "hover"]}
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>
                                        {strings.backofficeStaff.share}
                                    </Tooltip>
                                }
                            >
                                <MenuButton
                                    onClick={() =>
                                        showRecommendOrganizationModal(
                                            ShareTargetType.PERSON,
                                            props.staff.id,
                                            buildDetailLink(
                                                props.staff.id,
                                                `/person/${props.staff.firstName}_${props.staff.lastName}`,
                                                "person"
                                            )
                                        )
                                    }
                                >
                                    {IconShare({
                                        fill: isBlocked
                                            ? branding.organizationDetailPageContent.blockedStaffIconColor
                                            : branding.sideIconBar.sideIconColorDark
                                    })}
                                </MenuButton>
                            </CustomOverlayTrigger>
                        )}
                        {!props.backOffice && (
                            <CustomOverlayTrigger
                                trigger={["click", "focus", "hover"]}
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>
                                        {isBookmarked
                                            ? strings.organizationDetailPageContent.removeFavoritePerson
                                            : strings.organizationDetailPageContent.favoritePerson}
                                    </Tooltip>
                                }
                            >
                                <MenuButton>
                                    <BookmarkWithToggle
                                        newBookmarkItem={true}
                                        fontSize={"25px"}
                                        favouriteButton={true}
                                        color={
                                            isBlocked
                                                ? branding.organizationDetailPageContent.blockedStaffIconColor
                                                : branding.sideIconBar.sideIconColorDark
                                        }
                                        type={props.leadFinder ? "sotuser" : "person"}
                                        id={staff.id}
                                        name={[staff.firstName, staff.lastName].join(" ")}
                                    />
                                </MenuButton>
                            </CustomOverlayTrigger>
                        )}
                        {props.backOffice && (
                            <CustomOverlayTrigger
                                trigger={["click", "focus", "hover"]}
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip-remove" style={{ fontFamily: branding.font1 }}>
                                        {strings.backofficeStaff.removeStaff}
                                    </Tooltip>
                                }
                            >
                                <MenuButton onClick={() => props.removeStaffFromOrga!(staff)}>
                                    {IconRemoveStaff({
                                        fill: isBlocked
                                            ? branding.organizationDetailPageContent.blockedStaffIconColor
                                            : branding.sideIconBar.sideIconColorDark
                                    })}
                                </MenuButton>
                            </CustomOverlayTrigger>
                        )}
                    </MenuPanel>
                </DesktopVersionContainer>

                {showCall && <ConfirmCall show={true} onHide={() => setShowCall(false)} sotUserId={staff.sotUserId || ""} />}
                <OrganizationModal />
                <CalendarModal />
            </StaffEntryContainer>
            <div style={{ flexGrow: 0, flexShrink: 0 }}>
                <ContextMenu
                    itemsPerRow={3}
                    collapsed={!contextMenuOpened}
                    mobile={true}
                    className="staffEntry"
                    items={() =>
                        props.speaker
                            ? getSpeakerMenuItems()
                            : createActions(
                                  loggedInState.user(),
                                  props.staff,
                                  favoriteState,
                                  contactState,
                                  appState,
                                  meeting,
                                  chime,
                                  roster,
                                  strings,
                                  favoriteState.is("sotuser", props.staff.sotUserId || props.staff.id),
                                  connectionStatus,
                                  menuCallback,
                                  undefined,
                                  undefined,
                                  true
                              )
                    }
                />
            </div>
            <RecommendModal />
        </StaffEntryRoot>
    )
}
