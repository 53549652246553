import { useState, useEffect } from "react"
import * as React from "react"
import { Notification as AppNotification, useNotificationContext } from "../conference/context/NotificationContext"
import styled from "styled-components"
import branding from "../branding/branding"
import Highlighter from "react-highlight-words"
import { Toast } from "react-bootstrap"
import { useAppState } from "../globalStates/AppState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useLanguageState } from "../globalStates/LanguageState"
import { IconCloseNotification } from "../ui/Icons"
import { NotificationType, CalendarEntryParticipationStatus } from "../API"
import { useChimeContext } from "../conference/context/ChimeContext"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { doCall } from "../communicationArea/CommunicationOptions"
import moment from "moment"
// @ts-ignore
import Notification from "react-web-notification"
import { usePresenceState } from "./PresenceIndicator"
import { ModalType } from "../backendServices/Types"
import {
    getCalendarEntries,
    CalendarEntrySortType,
    PresenceType,
    CalendarEntry,
    CalendarEntryParticipation
} from "../backendServices/GraphQLServices"

interface NotificationCenterProps {
    receptionPageToggle: (route: string) => void
    setTrigger: (trigger: number) => void
}

const NotificationCenter: React.FunctionComponent<NotificationCenterProps> = (props: NotificationCenterProps) => {
    const userState = useLoggedInState()
    const profileId = userState.user()?.profileId || ""
    const notificationContext = useNotificationContext()
    const strings = useLanguageState().getStrings()
    const appState = useAppState()
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const myPresence = usePresenceState()
    const currentUserName: string = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
    var callback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void

    function getParticipantsNames(calendarEntry: CalendarEntry): string {
        let listOfParticipants: CalendarEntryParticipation[] = calendarEntry.participants.items

        let participantsNames: string = ""

        listOfParticipants = listOfParticipants.filter((i: CalendarEntryParticipation) => i.userId !== profileId)

        if (listOfParticipants.length === 1) {
            participantsNames = listOfParticipants[0].user.name
        } else {
            listOfParticipants.forEach(function (item, index, array) {
                if (index === array.length - 1) {
                    participantsNames += strings.notification.meetingReminderNameLink + " " + item.user.name
                } else if (index === array.length - 2) {
                    participantsNames += item.user.name + " "
                } else {
                    participantsNames += item.user.name + ", "
                }
            })
        }

        return participantsNames
    }

    async function loadScheduleData() {
        const profileId = userState.user()?.profileId

        if (profileId === undefined) {
            return
        }

        const data = await getCalendarEntries(profileId, CalendarEntrySortType.FUTURE, CalendarEntryParticipationStatus.ACCEPTED)

        if (data == null) {
            // TODO ERROR
        } else {
            const calendarEntriesResp: CalendarEntry[] = data.items.map((item) => item.calendarEntry)

            const calendarEntriesToday = calendarEntriesResp.filter((calendarEntry) => {
                const startDate = new Date(calendarEntry.start)
                const currentDate = new Date()
                return currentDate.getDate() === startDate.getDate()
            })

            let date: Date = new Date()

            for (let item of calendarEntriesToday) {
                let timeDifference = moment(item.start).diff(moment(date))

                if (timeDifference < 930000 && timeDifference > 870000) {
                    appState.setMeetingReminder(true, getParticipantsNames(item), "15")
                } else if (timeDifference < 630000 && timeDifference > 570000) {
                    appState.setMeetingReminder(true, getParticipantsNames(item), "10")
                } else if (timeDifference < 330000 && timeDifference > 270000) {
                    appState.setMeetingReminder(true, getParticipantsNames(item), "5")
                }
            }
        }
    }

    const replacePlaceholderText = (template: string, name: string) => {
        var contentString = template.split("{$name}").join(name)

        return contentString
    }

    useEffect(() => {
        if (myPresence.getMyPresence() === PresenceType.DONOTDISTURB) {
            notificationContext.unsubscribe()
        } else {
            notificationContext.init(profileId)
        }
    }, [myPresence.getMyPresence()]) // eslint-disable-line

    useEffect(() => {
        loadScheduleData()
        const interval = setInterval(() => loadScheduleData(), 50000)
        return () => {
            clearInterval(interval)
        }

        // eslint-disable-next-line
    }, [])

    const [localNotifications, setLocalNotifications] = useState<AppNotification[]>([])

    const handleTourNotificationClick = () => {
        appState.setShowTourNotification(false)
    }

    useEffect(() => {
        if (appState.showTourNotification) {
            let test: AppNotification = {
                userId: "",
                type: NotificationType.PLAINTEXT,
                title: strings.notification.announcementTitle,
                text: strings.notification.announcementText.split("{$name}").join(currentUserName),
                userName: "",
                onClick: () => handleTourNotificationClick(),
                onClose: () => handleTourNotificationClick()
            }

            let tempAdd = localNotifications
            tempAdd.push(test)

            setLocalNotifications(tempAdd)
        } else {
            let tempRemove = localNotifications.filter((i: AppNotification) => i.title !== strings.notification.announcementTitle)

            setLocalNotifications(tempRemove)
        }

        // eslint-disable-next-line
    }, [appState.showTourNotification])

    const handleMissedCallNotificationClick = () => {
        //chime.createOrJoinMeeting(appState.missedCallNotificationId, "call")
        //meeting.sendInvite(appState.missedCallNotificationId)

        doCall(appState.missedCallNotificationId, meeting, chime, () => callback({ modalType: "call" }))

        let tempRemove = localNotifications.filter((i: AppNotification) => i.userId !== appState.missedCallNotificationId)

        setLocalNotifications(tempRemove)
        appState.setMissedCallNotification(false, "", "")
    }

    const handleMissedCallNotificationClose = () => {
        let tempRemove = localNotifications.filter((i: AppNotification) => i.userName !== appState.missedCallNotificationName)

        setLocalNotifications(tempRemove)
        appState.setMissedCallNotification(false, "", "")
    }

    useEffect(() => {
        if (
            appState.missedCallNotificationName === "" ||
            appState.missedCallNotificationName === "," ||
            appState.showMissedCallNotification === false
        ) {
            return
        }

        let test: AppNotification = {
            userId: appState.missedCallNotificationId,
            type: NotificationType.PLAINTEXT,
            title: strings.notification.missedCallTitle,
            text: replacePlaceholderText(strings.notification.missedCallTextTemplate, appState.missedCallNotificationName),
            userName: appState.missedCallNotificationName,
            onClick: () => handleMissedCallNotificationClick(),
            onClose: () => handleMissedCallNotificationClose()
        }

        let tempAdd = localNotifications
        tempAdd.push(test)

        setLocalNotifications(tempAdd)

        // eslint-disable-next-line
    }, [appState.showMissedCallNotification])

    const handleMeetingReminderClick = () => {
        let tempRemove = localNotifications.filter((i: AppNotification) => i.userName !== appState.meetingReminderName)

        setLocalNotifications(tempRemove)
        appState.setMeetingReminder(false, "", "")
    }

    useEffect(() => {
        if (
            appState.meetingReminderName === "" ||
            appState.meetingReminderName === "," ||
            appState.showMeetingReminder === false
        ) {
            return
        }
        const tmpText = strings.notification.meetingReminderTextTemplate.split("{$time}").join(appState.meetingTimeBefore)
        let test: AppNotification = {
            userId: "",
            type: NotificationType.PLAINTEXT,
            title: strings.notification.meetingReminderTitle,
            text: replacePlaceholderText(tmpText, appState.meetingReminderName),
            userName: appState.meetingReminderName,
            onClick: () => handleMeetingReminderClick(),
            onClose: () => handleMeetingReminderClick()
        }

        let tempAdd = localNotifications
        tempAdd.push(test)

        setLocalNotifications(tempAdd)

        // eslint-disable-next-line
    }, [appState.showMeetingReminder])

    function onClose(notification: AppNotification) {
        notificationContext.removeNotification(notification)
    }

    return (
        <div
            style={{
                position: "relative",
                minHeight: "200px"
            }}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    right: 15
                }}
            >
                {notificationContext.getNotifications().map((notification: AppNotification, index) => (
                    <NotificationItem key={index} notification={notification} onClose={onClose} delay={8000} />
                ))}
                {myPresence.getMyPresence() !== PresenceType.DONOTDISTURB &&
                    localNotifications.map((notification, index) => (
                        <NotificationItem key={index} notification={notification} onClose={onClose} delay={8000} />
                    ))}
            </div>
        </div>
    )
}

interface NotificationItemProps {
    notification: AppNotification
    onClose: (notification: AppNotification) => void
    delay: number
}

export const NotificationItem: React.FunctionComponent<NotificationItemProps> = (props) => {
    const [show, setShow] = useState(true)

    function onClick() {
        onClose()
        props.notification?.onClick()
    }

    function onClose() {
        setShow(false)
        props.onClose(props.notification!)

        if (props.notification.onClose) {
            props.notification.onClose()
        }
    }

    return (
        <>
            {show && (
                <NotificationPopup onClick={onClick} onClose={onClose} notification={props.notification} delay={props.delay} />
            )}
        </>
    )
}

export default NotificationCenter

const Title = styled.div`
    font-family: ${branding.font1};
    margin-left: 10px;
    font-size: 14px;
    line-height: 20px;
    color: ${branding.notification.notificationTitleTextColor ?? "#B3B3B3"};
    white-space: normal;
    overflow: hidden;
`

const Content = styled.div`
    color: ${branding.notification.notificationContentTextColor ?? "#000"};
    font-family: ${branding.font1};
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 16px;
    font-size: 14px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
`
interface NotificationPopupProps {
    onClick: () => void
    onClose: () => void
    notification: AppNotification
    delay: number
}

export const NotificationPopup: React.FunctionComponent<NotificationPopupProps> = (props: NotificationPopupProps) => {
    const [show, setShow] = useState(true)
    const strings = useLanguageState().getStrings().notification
    const options = { body: props.notification.text }

    function onClick() {
        props.onClick()
        onClose()
    }

    function onClose() {
        props.onClose()
        setShow(false)
    }

    useEffect(() => {
        let closeTimeout = setTimeout(() => {
            onClose()
        }, props.delay)

        return () => clearTimeout(closeTimeout)
        // eslint-disable-next-line
    }, [])

    return (
        <Toast
            animation={true}
            show={show}
            style={{
                zIndex: 1000,
                width: "350px",
                backgroundColor: "#fff",
                cursor: "pointer",
                top: 0,
                right: 0,
                pointerEvents: props.notification.title.includes(strings.announcementTitle) ? "none" : "auto"
            }}
            onClose={() => props.onClose()}
        >
            {document.hidden && <Notification title={props.notification.title} options={options} onClick={() => onClick()} />}
            <Toast.Body onClick={() => onClick()}>
                <Title>
                    <div style={{ marginTop: "11px", float: "left" }}>{props.notification.title || ""}</div>
                    <div
                        onClick={(event) => {
                            event.stopPropagation()
                            onClose()
                        }}
                        style={{ zIndex: 150, pointerEvents: "auto", marginTop: "9px", paddingLeft: "20px", float: "right" }}
                    >
                        {IconCloseNotification({ fill: branding.sideIconBar.sideIconColorDark })}
                    </div>
                </Title>
                <Content>
                    <Highlighter
                        activeIndex={-1}
                        searchWords={[
                            props.notification?.userName || "",
                            props.notification?.organizationName || "",
                            props.notification?.restrictedAreaId || ""
                        ]}
                        autoEscape={true}
                        highlightStyle={{
                            display: "inline",
                            backgroundColor: "transparent",
                            fontWeight: "bold",
                            padding: 0,
                            color: branding.notification.notificationContentTextColor
                        }}
                        textToHighlight={props.notification?.text || ""}
                    />
                </Content>
            </Toast.Body>
        </Toast>
    )
}
