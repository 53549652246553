import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"

const TextLimitRoot = styled.div<{ textAlign: string }>`
    text-align: ${(props) => props.textAlign};
    font-size: 9px;
    line-height: 20px;
    margin-top: 5px;
    margin-right: 5px;
    font-family: ${branding.font1};
    color: #727272;
`

interface TextLimitProps {
    textLength: number
    maxTextLength: number
    fontSize?: string
    fontFamily?: string
    textAlign?: string
}

const TextLimit: React.FunctionComponent<TextLimitProps> = React.memo((props: TextLimitProps) => {
    return (
        <TextLimitRoot textAlign={props.textAlign ?? "right"}>
            {props.textLength} / {props.maxTextLength}
        </TextLimitRoot>
    )
})

export default TextLimit
