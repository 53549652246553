import { IconPlay } from "../../ui/Icons"
import styled from "styled-components"
import branding from "../../branding/branding"
import { RemoteLivestream } from "../../backendServices/Types"

const OverlayRoot = styled.div<{ backgroundUrl?: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    pointer-events: none;
    padding: 20px;

    ${props => props.backgroundUrl ? `background: url(${props.backgroundUrl}) center no-repeat` : ''};
    background-size: contain;

    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, .5);
    }
`

const OverlayContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: ${branding.font1};
    z-index: 1;
`

const OverflowTemplate = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
`

const TitleRoot = styled(OverflowTemplate)`
    font-size: 22px;
`

const PlayIconRoot = styled.div`
    display: flex;
    justify-content: center;
`

const DescriptionRoot = styled(OverflowTemplate)`
    font-size: 18px;
`

interface CustomOverlayForLiveStreamsProps {
    remoteLivestream: RemoteLivestream
}

const CustomOverlayForLiveStreams = (props: CustomOverlayForLiveStreamsProps) => {

    return (
        <OverlayRoot className="remote-livestream-overlay" backgroundUrl={props.remoteLivestream.backgroundImageUrl}>
            <OverlayContent>
                <TitleRoot>{props.remoteLivestream.title}</TitleRoot>
                <PlayIconRoot>
                    {IconPlay({ width: "4vw", height: "4vw" })}
                </PlayIconRoot>
                <DescriptionRoot>{props.remoteLivestream.teaserText}</DescriptionRoot>
            </OverlayContent>
        </OverlayRoot>
    )
}

export default CustomOverlayForLiveStreams