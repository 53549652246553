import { Col, Row, ToggleButton } from "react-bootstrap"
import { useLanguageState } from "../../../../globalStates/LanguageState"
import { useVideoContext } from "../../../context/VideoContext"
import PreviewVideo from "../../PreviewVideo"
import { ButtonWrapper } from "../logo/Position"
import { SettingsContentContainer } from "../Settings"
import { ChooseBackground } from "./ChooseBackground"

export function BackgroundSettings() {
    const strings = useLanguageState().getStrings().audioVideoSettings
    const videoContext = useVideoContext()

    return (
        <SettingsContentContainer>
            <Row>
                <Col md={7}>
                    <PreviewVideo />
                    <ButtonWrapper toggle>
                        <ToggleButton
                            variant="secondary"
                            value="none"
                            onClick={() => videoContext.setBackground("none", null)}
                            checked={videoContext.getBackground() === "none"}
                            type="radio"
                            name="backgroundType"
                        >
                            {strings.radioNoBackground}
                        </ToggleButton>
                        <ToggleButton
                            variant="secondary"
                            value="blur"
                            onClick={() => videoContext.setBackground("blur", null)}
                            checked={videoContext.getBackground() === "blur"}
                            type="radio"
                            name="backgroundType"
                        >
                            {strings.radioBlurBackground}
                        </ToggleButton>
                    </ButtonWrapper>
                </Col>
                <Col md={5}>
                    <ChooseBackground />
                </Col>
            </Row>
        </SettingsContentContainer>
    )
}
