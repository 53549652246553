import { fetchDataRest, topic } from "./BackendServicesUtils"
import { GetConnectionsResponse } from "./SeriesOfTopicsUserServices"
import { defaultLogger as logger } from "../globalStates/AppState"
import { Category } from "./Types"

/*********************************************************************************************
 * GET CATEGORIES BY TOPIC
 **********************************************************************************************/
export interface GetCategoriesByTopicResponse {
    content: Array<Category>
}

export async function getCategoriesByTopic(): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/topic/${topic}/getcategoriesbytopic`

    try {
        const data = await fetchDataRest(defaultRoute, {}, "GET")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}

export async function getRootCategoriesByTopic(
    rootCategories: Array<String>,
    signal?: AbortSignal
): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/topic/${topic}/getrootcategoriesbytopic`

    try {
        const data = await fetchDataRest(
            defaultRoute,
            {},
            "PUT",
            { rootCategories: rootCategories.join(",") },
            undefined,
            false,
            undefined,
            signal
        )
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}
