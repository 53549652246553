import { useState, useEffect } from "react"

interface WindowDimensions {
    width: number
    height: number
    isMobile: boolean
    useMobileDesign: boolean
}

function getWindowDimensions(): WindowDimensions {
    const { innerWidth: width, innerHeight: height } = window
    const isMobile = width < 1024 || height < 576
    const useMobileDesign = width <= 768

    return {
        width,
        height,
        isMobile,
        useMobileDesign,
    }
}

/**
 * Tracking the current state of the window width and height
 */

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}
