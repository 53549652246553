import { Tooltip } from "react-bootstrap"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { Attachment } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { IconPlayFilled } from "../../../ui/Icons"

const ImageInFocus = styled.div<{ clickable: boolean }>`
    position: relative;
    width: 100%;
    height: 500px;
    padding-bottom: 66%;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};
    border-radius: 5px;
    border: 2px solid ${branding.listDividerColor};
`
const VideoPlayerRoot = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    border-radius: 5px;
    border: 2px solid ${branding.listDividerColor};
`

const VideoPlayerContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    cursor: pointer;
`

const PlayIcon = styled.div`
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface ThumbnailVideoPlayerProps {
    attachment: Attachment
    onVideoClick: () => void
}

export const ThumbnailVideoPlayer = (props: ThumbnailVideoPlayerProps) => {
    return (
        <VideoPlayerContainer onClick={() => props.onVideoClick()}>
            <PlayIcon>{IconPlayFilled({ width: "35", height: "35", stroke: "white" })}</PlayIcon>
            <ReactPlayer
                controls={false}
                playing={false}
                url={props.attachment.url}
                width="100%"
                height="100%"
                style={{ outline: "none" }}
                config={{
                    file: {
                        attributes: {
                            controlsList: "nodownload",
                            onContextMenu: (e: any) => e.preventDefault()
                        }
                    }
                }}
            ></ReactPlayer>
        </VideoPlayerContainer>
    )
}

interface FocusImageProps {
    selectedAttachment?: Attachment
    imageInFocus: string
    organizationId?: string
    onFocusImageClicked: () => void
}

const FocusImage = (props: FocusImageProps) => {
    const strings = useLanguageState().getStrings()

    return props.selectedAttachment ? (
        <CustomOverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                    {strings.productDetailPageContent.mediaFullScreenText}
                </Tooltip>
            }
        >
            {props.selectedAttachment.contentType.includes("video") ? (
                <VideoPlayerRoot>
                    <ThumbnailVideoPlayer attachment={props.selectedAttachment} onVideoClick={props.onFocusImageClicked} />
                </VideoPlayerRoot>
            ) : (
                <ImageInFocus
                    style={{
                        backgroundImage: `url(${props.selectedAttachment.url})`
                    }}
                    clickable={true}
                    onClick={props.onFocusImageClicked}
                ></ImageInFocus>
            )}
        </CustomOverlayTrigger>
    ) : (
        <ImageInFocus
            style={{ backgroundImage: `url(${props.imageInFocus})` }}
            clickable={false}
            onClick={props.onFocusImageClicked}
        />
    )
}

export default FocusImage
