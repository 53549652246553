import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { IconNetworking } from "../../ui/Icons"
import ToggleSwitch from "../../ui/ToggleSwitch"
import { SettingsAreaRow } from "../settings/SettingsTab"
import { updateMyProfileData } from "../../backendServices/SeriesOfTopicsUserServices"

interface NetworkingToggleProps {
    customEnabled?: boolean
    customFunction?: () => void
    alwaysShowToggle?: boolean
}

export default function NetworkingToggle(props: NetworkingToggleProps) {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    async function toggleNetworkingState() {
        if (userState.user()) {
            if (props.customFunction) {
                props.customFunction()
            }

            const res = await updateMyProfileData({
                profileId: userState.user()!.profileId,
                profileData: { ...userState.user(), matchActive: !userState.isMatchActive(), id: userState.user()?.profileId }
            })
            userState.setMatchActive(res.content.matchActive)
        }
    }

    if (!branding.configuration.networkingToggle && !props.alwaysShowToggle) return null
    return (
        <SettingsAreaRow>
            {IconNetworking({ fill: branding.sideIconBar.sideIconColorDark })}
            <p>{strings.navigationArea.networkingItemTitle}</p>
            <ToggleSwitch isEnabled={props.customEnabled || userState.isMatchActive()} onClick={toggleNetworkingState} />
        </SettingsAreaRow>
    )
}
