import { useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { Modal, Button as BootstrapButton } from "react-bootstrap"
import { IconClose, IconMeetings } from "./Icons"
import { useLanguageState } from "../globalStates/LanguageState"
import branding from "../branding/branding"
import TextLimit from "./TextLimit"
import { useUserRestrictedAreaAccess } from "../globalStates/UserRestrictedAreaAccess"
import { RestrictedArea } from "../backendServices/Types"

const RequestAccessModalRoot = styled(Modal)`
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    .btn-secondary {
        color: ${branding.primaryColor ?? "#000"} !important;
        display: inline-block;
        background-color: white;
        font-family: ${branding.font1};
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        border-width: initial;
        border-style: none;
        border-image: initial;
        border-color: white;
    }
`
const RequestAccessModalHeader = styled(Modal.Header)`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;
    svg {
        color: ${branding.primaryColor};
    }
`
const RequestAccessModalTitle = styled.span`
    display: inline-block;
    font-size: 1.3rem;
    font-weight: bold;
`

const RequestAccessModalBody = styled(Modal.Body)`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`
const CloseModalBtn = styled.div`
    display: inline-block;
    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }
`

const SubmitModalBtn = styled.div`
    display: inline-block;
    cursor: pointer;
    margin: 0;
    width: 40%;
    border-radius: 20px;
    background-color: ${branding.primaryColor};
    border: 1px solid transparent;
    transition: background-color 0.3s, border-color 0.3s;
    color: white;
    padding: 0.3rem;
    text-align: center;
    font-family: ${branding.font1};
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;

    &:hover {
        color: ${branding.primaryColor};
        background-color: white;
        border: 1px solid ${branding.primaryColor};
    }
`
const ReportModalFooter = styled(Modal.Footer)`
    justify-content: space-between;
    border: none;
    padding: 10px 0px 0px 0px;
`
const CancelButton = styled.div`
    width: 40%;
    justify-content: left;
    border: none;
    background: white;
    color: ${branding.primaryColor ?? "#fff"};
    border-radius: 25px;
    cursor: pointer;

    &:active {
        background-color: transparent;
        border: 1px solid transparent;
    }
`

interface RequestAccessModalProps {
    restrictedArea: RestrictedArea
    onHide: () => void
}

const RequestAccessModal: React.FC<RequestAccessModalProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const userAccessState = useUserRestrictedAreaAccess()

    const [requestAccessMessage, setRequestAccessMessage] = useState("")

    const onRequestAccessMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRequestAccessMessage(e.target.value)
    }

    const onRequestAccessSubmit = () => {
        userAccessState.requestAccess(props.restrictedArea, requestAccessMessage)
        props.onHide()
    }

    const handleEnterKeyPress = (event: any) => {
        if (event.key === "Enter") {
            onRequestAccessSubmit()
        }
    }

    let maxLength: number = 250

    return (
        <RequestAccessModalRoot show={true} onHide={props.onHide} backdrop="static" centered animation={false}>
            <RequestAccessModalHeader>
                <div className={"d-flex align-items-center"}>
                    {IconMeetings({ fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor })}
                    <RequestAccessModalTitle className={"ml-3"}>{strings.globalTexts.accessRequestTitle}</RequestAccessModalTitle>
                </div>
                <CloseModalBtn onClick={() => props.onHide()}>
                    {IconClose({ fill: branding.sideIconBar.sideIconColorDark })}
                </CloseModalBtn>
            </RequestAccessModalHeader>

            <RequestAccessModalBody>
                <textarea
                    style={{ height: "250px" }}
                    value={requestAccessMessage}
                    maxLength={maxLength}
                    name="RequestAccessDescription"
                    className="form-control"
                    onChange={onRequestAccessMessageChange}
                    placeholder={strings.globalTexts.requestReasonText}
                    onKeyPress={handleEnterKeyPress}
                ></textarea>

                <TextLimit textLength={requestAccessMessage.length || 0} maxTextLength={maxLength} />
                <ReportModalFooter>
                    <CancelButton>
                        <BootstrapButton variant="secondary" onClick={() => props.onHide()}>
                            {strings.globalTexts.cancel}
                        </BootstrapButton>
                    </CancelButton>
                    <SubmitModalBtn onClick={onRequestAccessSubmit}>{strings.globalTexts.requestAccess}</SubmitModalBtn>
                </ReportModalFooter>
            </RequestAccessModalBody>
        </RequestAccessModalRoot>
    )
}

export default RequestAccessModal
