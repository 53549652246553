import { EntityType, Suggestion } from "../backendServices/Types"
import { useFavoriteState } from "../globalStates/Favorites"
import { createState, State, useState, Downgraded } from "@hookstate/core"
import { fetchDataHelper, SearchParameters, Sections, sectionOrder, Section } from "../utils/searchUtils"
import { Persistence } from "@hookstate/persistence"
import { useEffect } from "react"
import { globalSearchResultPageRoute } from "../navigationArea/RoutePaths"

const localStorageKey = "global-search-state"

interface StateValues {
    searchParams: SearchParameters
    sections: Sections
    isLoading: boolean
    isInit: boolean
}

const useWrapState = (state: State<StateValues>) => {
    const favoriteState = useFavoriteState()
    const results = { ...state.sections.value }
    state.attach(Downgraded)
    state.attach(Persistence(localStorageKey))

    useEffect(() => {
        if (window.location.href.indexOf(globalSearchResultPageRoute) === -1) {
            state.searchParams.set((prev) => {
                prev.emptyCheckBoxes = true
                prev.entityTypes = []
                prev.searchValue = ""
                return prev
            })
        }
        // eslint-disable-next-line
    }, [window.location.href])

    const mergeFavorites = () => {
        let allFavorites: string[] = []
        state.searchParams.entityTypes.forEach((type: any) => {
            let favoritesType = type.value
            if (type.value === "networking_user") favoritesType = "sotuser"

            allFavorites.push(favoriteState.get(favoritesType))
        })
        return allFavorites.join(",")
    }

    const fetchData = async (searchParams: SearchParameters) => {
        // 1. If there are no favorites and we only want to see those, then we don't need to load anything.
        // 2. we don't need bookmarked coupons and categories
        // 3. If there are no selected entity type, we can return empty sections
        if (searchParams.showOnlyBookmarks && !searchParams.favorites) {
            state.sections.set({})
            return
        }

        // set isLoading to true to be able to display loader
        state.set((prev) => {
            prev.isLoading = true
            return prev
        })

        fetchDataHelper(searchParams, state.sections.value, true).then((resp) => {
            state.batch((s) => {
                s.set((prev) => {
                    prev.isLoading = false
                    prev.isInit = true
                    return prev
                })
                for (let sectionKey of sectionOrder) {
                    const oldSection = s.sections[sectionKey] as State<Section>
                    const newSection = resp[sectionKey]

                    if (!newSection || newSection.count === 0) continue

                    oldSection.merge({
                        type: newSection.type,
                        count: newSection.count,
                        hasMoreData: newSection.hasMoreData
                    })

                    if (oldSection.entities.value && oldSection.entities.value.length > 0 && state.searchParams.page.value !== 0)
                        oldSection.entities.merge(newSection.entities)
                    else oldSection.entities.set(newSection.entities)
                }
            })
        })
    }

    return {
        searchParams: state.searchParams.value,
        results: state.sections.value,
        isLoading: state.isLoading.value,
        searchFunctions: {
            init: (showOnlyBookmarks?: boolean) => {
                let categoryFilter: string | undefined
                state.searchParams.set((prev) => {
                    prev.page = 0
                    prev.showOnlyBookmarks = showOnlyBookmarks ?? false
                    prev.categoryFilter = categoryFilter
                    prev.favorites = showOnlyBookmarks ? mergeFavorites() : ""
                    return prev
                })
                fetchData(state.searchParams.value)
            },
            nextPage: () => {
                state.searchParams.set((prev) => {
                    prev.page = state.searchParams.value.page + 1
                    return prev
                })
                fetchData(state.searchParams.value)
            },
            setBookmarksOnly: (showOnlyBookmarks: boolean) => {
                state.set((prev) => {
                    prev.searchParams.page = 0
                    prev.searchParams.showOnlyBookmarks = showOnlyBookmarks
                    prev.searchParams.favorites = mergeFavorites()
                    prev.sections = {}
                    return prev
                })
                fetchData(state.searchParams.value)
            },
            setEntityFilter: (entityType: EntityType[] | "all") => {
                state.batch((s) => {
                    s.set((prev) => {
                        prev.searchParams.page = 0
                        prev.searchParams.entityTypes = entityType === "all" ? [] : entityType
                        prev.searchParams.favorites = mergeFavorites()
                        prev.searchParams.emptyCheckBoxes = prev.searchParams.entityTypes.length === 0
                        prev.sections = {}
                        return prev
                    })
                })
                fetchData(state.searchParams.value)
            },
            addEntityFilter: (entityType: EntityType) => {
                state.batch((s) => {
                    s.set((prev) => {
                        prev.searchParams.page = 0
                        prev.searchParams.entityTypes.push(entityType)
                        prev.searchParams.favorites = mergeFavorites()
                        prev.searchParams.emptyCheckBoxes = prev.searchParams.entityTypes.length === 0
                        prev.sections = {}
                        return prev
                    })
                })

                fetchData(state.searchParams.value)
            },
            removeEntityFilter: (entityType: EntityType) => {
                state.batch((s) => {
                    s.set((prev) => {
                        prev.searchParams.page = 0
                        prev.searchParams.entityTypes = prev.searchParams.entityTypes.filter((x) => x !== entityType)
                        prev.searchParams.favorites = mergeFavorites()
                        prev.searchParams.emptyCheckBoxes = prev.searchParams.entityTypes.length === 0
                        prev.sections = {}
                        return prev
                    })
                })

                fetchData(state.searchParams.value)
            },
            setAlpha: (alpha: string | null) => {
                state.set((prev) => {
                    prev.searchParams.page = 0
                    prev.searchParams.alpha = alpha
                    prev.sections = {}
                    return prev
                })
                fetchData(state.searchParams.value)
            },
            setSugestions: (suggestions: Suggestion[], showMoreEntities?: boolean) => {
                const searchValues: string[] = []
                for (const suggestion of suggestions) {
                    if (suggestion.active) searchValues.push(suggestion.value)
                }
                const emptySuggestions = (suggestions.length === 0 ||
                    suggestions.find((s) => s.type === null || s.type === undefined)) as boolean

                state.set((prev) => {
                    prev.searchParams.page = 0
                    prev.searchParams.searchValue = searchValues.join(",")
                    prev.searchParams.entityTypes = showMoreEntities
                        ? [suggestions[suggestions.length - 1].type!]
                        : emptySuggestions
                        ? []
                        : prev.searchParams.entityTypes.length
                        ? prev.searchParams.entityTypes
                        : suggestions.filter((s) => s.type).map((s) => s.type!)
                    prev.searchParams.emptyCheckBoxes = prev.searchParams.entityTypes.length === 0
                    prev.sections = {}
                    return prev
                })
                fetchData(state.searchParams.value)
            },
            hasMoreData: () => {
                if (!state.isInit.value) return false
                return Object.values(results).filter((result) => result && result.hasMoreData).length > 0
            },
            reset: () => {
                state.set((prev) => {
                    prev.searchParams.page = 0
                    prev.searchParams.emptyCheckBoxes = true
                    prev.searchParams.entityTypes = []
                    prev.sections = {}
                    return prev
                })
                fetchData(state.searchParams.value)
            }
        }
    }
}

const state = createState<StateValues>({
    isInit: false,
    isLoading: false,
    sections: {} as Sections,
    searchParams: {
        page: 0,
        order: "lexic",
        alpha: null,
        showOnlyBookmarks: false,
        emptyCheckBoxes: true,
        entityTypes: [],
        categoryFilter: undefined,
        favorites: "",
        searchValue: ""
    }
})

export const useSearchContext = () => useWrapState(useState(state))
