export enum MeetingStatusCode {
    Loading = 1,
    Succeeded = 2,
    Disconnected = 3,
    Failed = 4,
    Full = 5,
    TimeUp = 6,
    Kicked = 7,
    Banned = 8,
    Ended = 9,
    GreenroomLive = 10,
}