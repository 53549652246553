import { useState, useEffect } from "react"
import * as React from "react"
import { Row, Col, Modal } from "react-bootstrap"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useLanguageState } from "../globalStates/LanguageState"
import branding from "../branding/branding"
import styled from "styled-components"
import { Contact, ModalType } from "../backendServices/Types"
import { IconClose } from "./Icons"
import { loadPersonData, SpeakerResponse } from "../backendServices/PersonServices"
import ContextMenu from "./ContextMenu"
import { useFavoriteState } from "../globalStates/Favorites"
import { useContactState } from "../communicationArea/ContactState"
import { useAppState } from "../globalStates/AppState"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { useChimeContext } from "../conference/context/ChimeContext"
import { createActions, CommunicationModals } from "../communicationArea/CommunicationOptions"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { mergeWith, isEmpty } from "lodash"
import { GetConnectionDetails } from "../utils/SayHello"
import { AvatarWithDefault } from "./AvatarWithDefault"
import { isUserEdited } from "../App"
import { useRosterContext } from "../conference/context/RosterContext"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"
import { getInterest, loadUserData, UserResponse } from "../backendServices/SeriesOfTopicsUserServices"

const ContactModalRoot = styled(Modal)<{ hideModalOpacity: boolean }>`
    width: 450px;
    height: 600px;
    left: 50%;
    transform: translateX(-250px);
    overflow: hidden !important;

    & .modal-dialog {
        opacity: ${(props) => (props.hideModalOpacity ? 0 : 1)};
    }

    & .modal-body {
        overflow: hidden !important;
    }

    & .modal-content {
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
    }
`

interface ContactModalProps {
    userId: string
    close: () => void
    open?: () => void
    onHideModalOpacityChange?: Function
    personId?: string
}

const ContactModal = (props: ContactModalProps) => {
    const [hideModalOpacity, setHideModalOpacity] = useState<boolean>(false)

    return (
        <ContactModalRoot
            hideModalOpacity={hideModalOpacity}
            show={true}
            animation={false}
            backdrop="static"
            onHide={props.close}
            onShow={() => props.open}
        >
            <Modal.Body>
                <ContactModalContent
                    onHideModalOpacityChange={setHideModalOpacity}
                    userId={props.userId}
                    personId={props.personId}
                    close={props.close}
                />
            </Modal.Body>
        </ContactModalRoot>
    )
}

const ContactModalContentRoot = styled.div`
    margin: auto;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: white;
    max-width: 450px;
    min-width: 350px;
    transition: top 0.5s;
`

const ContactModalContentContent = styled.div`
    font-family: ${branding.font1};
    color: ${branding.primaryColor ?? "#000"};
    margin-left: -15px;
    & .user-name {
        font-size: 16px;
        line-height: 17px;
        font-weight: bold;
    }
    & .title {
        font-size: 12px;
        line-height: 18px;
        margin-top: 10px;
    }
`
const ContactModalContentActionRoot = styled.div`
    text-align: center;
    margin-top: 1rem;
    border-top: 1px solid #d9d9d9;
`

const ContactModalContentInterestsRoot = styled.div`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
    position: relative;
    border-top: ${branding.mainBorder ?? "1px solid #d9d9d9"};
    padding: 0.5rem 0;
    margin-top: 1rem;
`
const ContactModalContentInterestsContent = styled.div`
    max-height: 150px;
    overflow-y: auto;
`
const ContactModalContentInterestsItem = styled.div<{ bold: Boolean }>`
    display: inline-block;
    padding: 0.5rem;
    border: 1px solid ${branding.mainInfoColor ?? "#000"};
    font-family: ${(props) => (props.bold ? branding.font2 : branding.font1)};
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    text-transform: ${(props) => (props.bold ? "uppercase" : "unset")};
    margin: 0.3rem;
    font-size: 12px;
    line-height: 14px;
`
const ContactModalContentInterestsBottomGradient = styled.div`
    height: 30px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`
const ContactModalContentCloseBtn = styled.div`
    position: absolute;
    z-index: 105;
    right: 20px;
    top: 10px;
    cursor: pointer;
`

const ContactModalContent: React.FC<ContactModalProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId
    const favoriteState = useFavoriteState()
    const contactState = useContactState()
    const appState = useAppState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const roster = useRosterContext()

    const [user, setUser] = useState<Contact>()
    const [isUser, setIsUser] = useState(false)
    const [sotUserId, setSotUserId] = useState<string>("")

    const [bookmarked, setBookmarked] = useState<boolean>(false)

    const connectionStatusTemp = contactState.getConnectionStatus(sotUserId)

    const [connectionStatus, setConnectionStatus] = useState(contactState.getConnectionStatus(sotUserId))

    const [modalType, setModalType] = useState<ModalType>("none")

    const menuCallback: (param: { modalType?: ModalType }) => void = ({ modalType }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    useEffect(() => {
        loadUser()
        // eslint-disable-next-line
    }, [props.userId, connectionStatusTemp])

    useEffect(() => {
        if (modalType === "meeting" || modalType === "share" || modalType === "report") props.onHideModalOpacityChange!(true)
        // eslint-disable-next-line
    }, [modalType])

    useEffect(() => {
        setBookmarked(favoriteState.is("person", props.userId ? sotUserId : props.personId!))

        GetConnectionDetails(loggedInUserId || "", sotUserId)
            .then((res) => {
                setConnectionStatus(res as string)
            })
            .catch((err) => {
                setConnectionStatus("UNRELATED")
            })

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setConnectionStatus(contactState.getConnectionStatus(sotUserId))
        setBookmarked(favoriteState.is("person", props.userId ? sotUserId : props.personId!))
        // eslint-disable-next-line
    }, [props.userId, contactState, favoriteState])

    async function loadUser() {
        if (!loggedInUserId) {
            return
        }
        if (props.personId) {
            try {
                const personData: SpeakerResponse = await loadPersonData({ id: props.personId })
                const contactDataPerson = personData.content
                const connectedSotUserId = contactDataPerson.sotUserId

                if (connectedSotUserId) {
                    const response = await loadUserData({ targetProfileId: connectedSotUserId, loggedInUserId: loggedInUserId })
                    if ((response as BackendServiceError).httpStatus) {
                        return
                    }

                    setIsUser(true)
                    const userDataResponse = response as UserResponse
                    mergePersonData(userDataResponse, personData)
                } else {
                    setIsUser(false)
                    mergePersonData(personData)
                }
                setSotUserId(connectedSotUserId ? connectedSotUserId : props.personId)
            } catch (error: any) {}
        } else {
            try {
                const response = await loadUserData({ targetProfileId: props.userId, loggedInUserId: loggedInUserId })

                const userDataResponse = response as UserResponse
                setUser(userDataResponse.content)
                setIsUser(true)
                setSotUserId(props.userId)
            } catch (e) {}
        }
    }

    function mergePersonData(primaryData: UserResponse, secondaryData?: UserResponse) {
        var primaryContact = primaryData.content
        if (secondaryData) {
            const secondaryContact = secondaryData.content
            primaryContact = mergeWith({}, secondaryContact, primaryContact, (obj, src) =>
                src == null || isEmpty(src) ? obj : src
            )
        }
        setUser(primaryContact)
        contactState.setAll([
            { id: primaryContact.id, connectionStatus: primaryContact.myConnectionStatus, userType: primaryContact.type }
        ])
    }

    const personName = [user?.title, user?.firstName, user?.lastName].join(" ")

    const personPosition: string = [user?.position, user?.company]
        .filter(Boolean)
        .join(" " + strings.communicationArea.personCompanyLink + " ")

    return user ? (
        <ContactModalContentRoot>
            <CommunicationModals
                show={modalType}
                contact={user}
                onHide={() => {
                    setModalType("none")
                    props.onHideModalOpacityChange!(false)
                }}
            />
            <ContactModalContentCloseBtn onClick={() => props.close()}>
                {IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "15px", height: "15px" })}
            </ContactModalContentCloseBtn>
            <div style={{ marginTop: "10px" }}>
                <Row>
                    <Col xs={3}>
                        <DetailNavLink
                            type={isUser ? "user" : "person"}
                            id={isUser ? sotUserId : props.personId!}
                            name={`/person/${user?.firstName}_${user?.lastName}`}
                        >
                            <AvatarWithDefault
                                size={60}
                                src={user?.logoUrl}
                                alt={[user?.firstName, user?.lastName].join(" ") ?? "#"}
                                backgroundSize="cover"
                            />
                        </DetailNavLink>
                    </Col>
                    <Col xs={8}>
                        <DetailNavLink
                            type={isUser ? "user" : "person"}
                            id={isUser ? sotUserId : props.personId!}
                            name={`/person/${user?.firstName}_${user?.lastName}`}
                        >
                            <ContactModalContentContent>
                                <p className={"user-name m-0 w-85"}>{personName}</p>
                                <p className={"title w-85"}>{personPosition}</p>
                            </ContactModalContentContent>
                        </DetailNavLink>
                    </Col>
                </Row>
                {user?.interests?.length! > 0 && (
                    <Row className="m-0">
                        <ContactModalContentInterestsRoot>
                            <ContactInterests user={user} />
                            <ContactModalContentInterestsBottomGradient />
                        </ContactModalContentInterestsRoot>
                    </Row>
                )}
                {isUserEdited(userState) && (
                    <ContactModalContentActionRoot>
                        <ContextMenu
                            collapsed={false}
                            items={() =>
                                createActions(
                                    userState.user(),
                                    user,
                                    favoriteState,
                                    contactState,
                                    appState,
                                    meeting,
                                    chime,
                                    roster,
                                    strings,
                                    bookmarked,
                                    connectionStatus,
                                    menuCallback,
                                    undefined,
                                    !isUser,
                                    user.person !== undefined
                                )
                            }
                        />
                    </ContactModalContentActionRoot>
                )}
            </div>
        </ContactModalContentRoot>
    ) : (
        <div />
    )
}

export default ContactModal

interface ContactInterestsProps {
    user: Contact
}

const ContactInterests: React.FunctionComponent<ContactInterestsProps> = (props: ContactInterestsProps) => {
    const strings = useLanguageState().getStrings()
    const userState = useLoggedInState()

    const [uniqueInterests, setUniqueInterests] = useState<Array<any>>([])

    const [commonInterests, setCommonInterests] = useState<Array<any>>([])

    useEffect(() => {
        getInterest().then((res) => {
            if (res.content.interests) {
                let allInterests = res.content.interests

                let myInterestsTemp = userState.user()?.interests!
                let usersInterestsTemp = props.user.interests

                setCommonInterests(
                    allInterests.filter((i: any) => myInterestsTemp?.includes(i.id) && usersInterestsTemp?.includes(i.id))
                )
                setUniqueInterests(
                    allInterests.filter(
                        (i: any) => myInterestsTemp?.includes(i.id) === false && usersInterestsTemp?.includes(i.id)
                    )
                )
            }
        })
        // eslint-disable-next-line
    }, [])

    return props.user.interests ? (
        <ContactModalContentInterestsContent>
            <p style={{ fontSize: "16px" }}>{strings.personDetailPageContent.interestsTitle}</p>
            {commonInterests.map((interest) => {
                return (
                    <ContactModalContentInterestsItem key={interest.id} bold={true}>
                        {interest.name}
                    </ContactModalContentInterestsItem>
                )
            })}
            {uniqueInterests.map((interest) => {
                return (
                    <ContactModalContentInterestsItem key={interest.id} bold={false}>
                        {interest.name}
                    </ContactModalContentInterestsItem>
                )
            })}
        </ContactModalContentInterestsContent>
    ) : (
        <div />
    )
}
