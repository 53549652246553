import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { StandardPage } from "../../layoutPages/StandardPage"
import { collectionRoute } from "../../navigationArea/RoutePaths"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { CollectionBranding } from "./CollectionBranding"
import { useAppState } from "../../globalStates/AppState"
import { device } from "../../utils/Device"
import useWindowDimensions from "../../ui/WindowDimensionsHook"

export const CollectionContentWrapper = styled.div<{ guestBannerHeight: number }>`
    display: flex;
    flex-direction: row;
    position: relative;
    flex-grow: 1;
    height: calc(100% - 110px - ${(props) => props.guestBannerHeight + "px"});
    max-height: calc(100% - 110px - ${(props) => props.guestBannerHeight + "px"});
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
`

const TileWrapper = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`

const CollectionsContainer = styled.div`
    margin-top: 20px;

    @media ${device.mobile} {
        margin-left: 20px;
        margin-right: 5px;
    }

    &.isMobile  {
        margin-left: 20px;
        margin-right: 5px;
    }
`

export default function CollectionOverview() {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const appState = useAppState()
    const { isMobile } = useWindowDimensions()

    const locations = calcBreadcrumbLocations(strings)
    let breadcrumb = [{ to: locations[0], name: strings.sideIconBar.collectionsMenuText }]

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(strings.sideIconBar.collectionsMenuText)
        // eslint-disable-next-line
    }, [lang])

    return (
        <StandardPage breadcrumb={breadcrumb}>
            <CollectionsContainer className={isMobile ? "isMobile" : ""}>
                <TileWrapper>
                    {strings.collectionBranding.collections.map((collection) => (
                        <Tile key={collection.id} collection={collection} />
                    ))}
                </TileWrapper>
            </CollectionsContainer>
        </StandardPage>
    )
}

const TileRoot = styled(NavLink)<{ $pictureUrl?: string }>`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 500px;
    height: 280px;
    background: ${(props) => (props.$pictureUrl ? `url("${props.$pictureUrl}")` : null)};
    color: ${(props) => (props.$pictureUrl ? "#fff" : "#000")};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px !important;
    margin-bottom: 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;

    &.isMobile {
        height: 190px;
        width: 339px;

        &.lobby {
            height: 115px;
            width: 205px;
            padding: 10px !important;
        }
    }

    @media ${device.mobile} {
        height: 190px;
        width: 339px;

        &.lobby {
            height: 115px;
            width: 205px;
            padding: 10px !important;
        }
    }

    &.private {
        cursor: not-allowed;
    }

    &:hover,
    &:active,
    &:link,
    &:focus {
        text-decoration: none;
        color: #fff;
    }

    & h2 {
        font-family: ${branding.font2};
        font-style: normal;
        font-weight: bold;
        font-size: 18px !important;
        line-height: 23px !important;
    }

    &:after {
        border-radius: 5px;
    }

    &:before {
        border-radius: 5px;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${branding.collectionBranding.collectionTilesDarkenOverlay};
        z-index: 1;
    }
    & > * {
        z-index: 2;
    }

    .bottom-overlay {
        position: absolute;
        height: 30%;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
    }
    & .row:last-child {
        margin-top: auto;
    }
`

const Title = styled.h2`
    white-space: pre-line;
    font: ${branding.meetingRoomGroupsTitleFontStyle ? branding.meetingRoomGroupsTitleFontStyle : "normal bold 18px/23px"}
        ${branding.font1} !important;

    @media ${device.mobile} {
        &.lobby {
            font-size: 14px !important;
        }
    }

    &.lobby {
        font-size: 14px !important;

        @media ${device.mobile} {
            font-size: 14px !important;
        }

        &.isMobile {
            font-size: 14px !important;
        }
    }
`
const Description = styled.div`
    margin-top: auto;
    white-space: pre-line;
    max-width: 260px;
    font: normal 300 12px/17px ${branding.font1};
    font-size: 12px;
    width: unset;
`

const MobileTextsContainer = styled.div``

interface TileProps {
    collection: CollectionBranding
    isLobbyComponent?: boolean
}
export function Tile(props: TileProps) {
    const { id, title, description, backgroundImage } = props.collection
    const { isMobile } = useWindowDimensions()

    const className = (isMobile ? "isMobile" : "") + " " + (props.isLobbyComponent ? "lobby" : "")

    return (
        <TileRoot to={`${collectionRoute.replace(":id", id)}`} $pictureUrl={backgroundImage} className={className}>
            <div className={"bottom-overlay"}></div>
            {isMobile ? (
                <>
                    <MobileTextsContainer>
                        <Title className={className}> {title} </Title>
                        <Description className={className}>{description}</Description>
                    </MobileTextsContainer>
                </>
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <Title> {title} </Title>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Description>{description}</Description>
                        </div>
                    </div>
                </>
            )}
        </TileRoot>
    )
}
