import { ProfileResponseStatus } from "./LoginRegistrationSite"
import { syncFavorites as syncFavoritesBackend } from "../../backendServices/FavoritesServices"
import moment from "moment"
import { FavoriteContextMethods } from "../../globalStates/Favorites"
import { LoggendInUserContextMethods, User, ThirdPartyData } from "../../globalStates/LoggedInUser"
import { defaultLogger as logger } from "../../globalStates/AppState"
import branding from "../../branding/branding"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import {
    DataPrivacyDoc,
    loginWithPassword,
    cognitoLogin,
    registerProfile,
    loginWithSSO as loginWithToken,
    personalizeTicket
} from "../../backendServices/AuthenticationServices"
import { ProfileResponse, connectProfile, getMyProfileData } from "../../backendServices/SeriesOfTopicsUserServices"
import {
    getUserById,
    PresenceType,
    updateUserValues,
    createNewUser,
    UserResponse,
    createUnreadCounterUser
} from "../../backendServices/GraphQLServices"

export async function syncFavorites(favoritesContext: FavoriteContextMethods, userId: string) {
    try {
        const resp = await syncFavoritesBackend({
            profileId: userId,
            body: {
                currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // "2020-01-01 12:00:00",
                lastSyncTime: favoritesContext.getLastSyncTime()
            }
        })
        const temp: Array<any> = resp.content.favorites
        favoritesContext.setLastSyncTime(new Date())
        temp.forEach((item) => {
            if (!item.deleted && !favoritesContext.is("person", item.id)) {
                favoritesContext.add("person", item.id)
            }
            if (!item.deleted && !favoritesContext.is("organization", item.id)) {
                favoritesContext.add("organization", item.id)
            }
            if (!item.deleted && !favoritesContext.is("eventdate", item.id)) {
                favoritesContext.add("eventdate", item.id)
            }
            if (!item.deleted && !favoritesContext.is("product", item.id)) {
                favoritesContext.add("product", item.id)
            }
            if (!item.deleted && !favoritesContext.is("trademark", item.id)) {
                favoritesContext.add("trademark", item.id)
            }
            if (!item.deleted && !favoritesContext.is("sotuser", item.id)) {
                favoritesContext.add("sotuser", item.id)
            }
        })
    } catch (e) {
        // syncFavorites failed, logged in BackendServices
    }
}

export async function createAppSyncUser(user: User) {
    const appSyncUser = await getUserById(user.profileId)
    const userName = [user!.firstName, user!.middleName, user!.lastName].filter(Boolean).join(" ")
    const startPresenceType = PresenceType.AVAILABLE
    if ((appSyncUser as UserResponse)?.getUser) {
        await updateUserValues({
            id: user!.profileId,
            name: userName,
            pictureUrl: user!.logoUrl,
            presenceStatus: startPresenceType,
            lastConnected: new Date().toISOString()
        })
    } else {
        await createNewUser({
            id: user!.profileId,
            name: userName,
            pictureUrl: user!.logoUrl,
            presenceStatus: startPresenceType,
            lastConnected: new Date().toISOString()
        })
        await createUnreadCounterUser({ id: user!.profileId, requests: 0, contacts: 0, conversations: 0, schedules: 0 })
    }
}

export async function registerWithPassword(
    loggedInContext: LoggendInUserContextMethods,
    favoritesContext: FavoriteContextMethods,
    email: string,
    password: string,
    dataPrivacyDoc: DataPrivacyDoc
): Promise<ProfileResponseStatus> {
    if (!dataPrivacyDoc) {
        return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
    }

    const resp = await loginWithPassword(email, password, { dataPrivacyDocs: [dataPrivacyDoc] })
    const error = resp as BackendServiceError
    if (error.httpStatus) {
        const json = error.responseJson
        if (json?.errorCode === "thirdPartyUserNotFound") {
            return ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND
        } else if (json?.errorCode === "userCreationNotAllowed") {
            return ProfileResponseStatus.USER_CREATION_NOT_ALLOWED
        } else if (json?.errorCode === "loginBlocked") {
            return ProfileResponseStatus.LOGIN_BLOCKED
        }
        if (json?.emailInvalid) return ProfileResponseStatus.FAILURE_EMAIL_INVALID
        if (json?.dataPrivacyDocsOutdated) return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
        if (error.httpStatus === 401) return ProfileResponseStatus.WRONG_PASSWORD

        return ProfileResponseStatus.FAILURE
    } else {
        const profileResp = resp as ProfileResponse
        try {
            await cognitoLogin(profileResp.profile.profileId, profileResp.beConnectionToken, email)
        } catch (error: any) {
            // should never happen because we have a valid beConnectionToken here
            logger.error({
                message: "LoginRegistrationSite cognito login failed",
                errorMessage: error.message,
                errorStack: error.stack
            })
            return ProfileResponseStatus.FAILURE
        }
        const isTicketValid = !branding.configuration.ticketBarrierPageEnabled || profileResp.profile.type !== "none"
        loggedInContext.setUserState({
            jwtToken: profileResp.beConnectionToken,
            user: profileResp.profile,
            sessionAndTicketValid: isTicketValid
        })
        syncFavorites(favoritesContext, profileResp.profile.profileId)
        await createAppSyncUser(profileResp.profile)
        return ProfileResponseStatus.SUCCESS
    }
}

export async function registerWithSSO(
    loggedInContext: LoggendInUserContextMethods,
    favoritesContext: FavoriteContextMethods,
    token: string,
    dataPrivacyDoc: DataPrivacyDoc,
    thirdPartyData?: ThirdPartyData
): Promise<ProfileResponseStatus> {
    if (!dataPrivacyDoc) {
        return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
    }

    const resp = await loginWithToken(token, { dataPrivacyDocs: [dataPrivacyDoc] })
    const error = resp as BackendServiceError
    if (error.httpStatus) {
        const json = error.responseJson
        switch (json?.errorCode) {
            case "thirdPartyUserNotFound":
                return ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND
            case "userCreationNotAllowed":
                return ProfileResponseStatus.USER_CREATION_NOT_ALLOWED
            case "noTicket":
                return ProfileResponseStatus.NO_TCKET
            default:
                if (json?.dataPrivacyDocsOutdated) return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED

                return ProfileResponseStatus.FAILURE
        }
    } else {
        const profileResp = resp as ProfileResponse
        try {
            await cognitoLogin(profileResp.profile.profileId, profileResp.beConnectionToken, profileResp.profile.email)
        } catch (error: any) {
            // should never happen because we have a valid beConnectionToken here
            logger.error({
                message: "LoginRegistrationSite cognito login failed",
                errorMessage: error.message,
                errorStack: error.stack
            })
            return ProfileResponseStatus.FAILURE
        }
        const isTicketValid = !branding.configuration.ticketBarrierPageEnabled || profileResp.profile.type !== "none"
        loggedInContext.setUserState({
            jwtToken: profileResp.beConnectionToken,
            user: profileResp.profile,
            sessionAndTicketValid: isTicketValid,
            thirdPartyData: thirdPartyData
        })
        syncFavorites(favoritesContext, profileResp.profile.profileId)
        await createAppSyncUser(profileResp.profile)
        return ProfileResponseStatus.SUCCESS
    }
}

export async function register(
    loggedInContext: LoggendInUserContextMethods,
    email: string,
    dataPrivacyDoc: DataPrivacyDoc
): Promise<ProfileResponseStatus> {
    if (!dataPrivacyDoc) {
        return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
    }

    const resp = await registerProfile(email, { dataPrivacyDocs: [dataPrivacyDoc] })
    const error = resp as BackendServiceError

    if (error.httpStatus) {
        const json = error.responseJson
        if (json?.errorCode === "thirdPartyUserNotFound") {
            return ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND
        } else if (json?.errorCode === "userCreationNotAllowed") {
            return ProfileResponseStatus.USER_CREATION_NOT_ALLOWED
        } else if (json?.errorCode === "loginBlocked") {
            return ProfileResponseStatus.LOGIN_BLOCKED
        } else if (json?.errorCode === "maximumLoginAttempts") {
            return ProfileResponseStatus.MAXIMUM_LOGIN_ATTEMPTS
        }
        if (json?.emailInvalid) return ProfileResponseStatus.FAILURE_EMAIL_INVALID
        if (json?.dataPrivacyDocsOutdated) return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED

        return ProfileResponseStatus.FAILURE
    } else {
        const profileIdResp = resp as { profileId: string }
        loggedInContext.setUser({ email: email, profileId: profileIdResp.profileId })
        return ProfileResponseStatus.SUCCESS
    }
}

export async function connect(
    loggedInContext: LoggendInUserContextMethods,
    favoritesContext: FavoriteContextMethods,
    token: string
): Promise<ProfileResponseStatus> {
    const profileId = loggedInContext.user()?.profileId
    if (profileId === undefined) {
        return ProfileResponseStatus.FAILURE
    }

    const resp = await connectProfile(profileId, token.trim())
    if ((resp as BackendServiceError).httpStatus) {
        const errorResp = resp as BackendServiceError
        // Bad Request, token was input wrong > 3, registerProfile has to be called again to prepare the profile for connection and create a new token
        if (errorResp.httpStatus === 400) {
            if (errorResp.responseJson.errorCode === "maximumLoginAttempts") return ProfileResponseStatus.MAXIMUM_LOGIN_ATTEMPTS
        }
        return ProfileResponseStatus.WRONG_TOKEN
    } else {
        const profileResp = resp as ProfileResponse
        try {
            await cognitoLogin(profileId, profileResp.beConnectionToken, profileResp.profile.email)
        } catch (error: any) {
            // should never happen because we have a valid beConnectionToken here
            logger.error({ message: "LoginRegistrationSite ", errorMessage: error.message, errorStack: error.stack })
            return ProfileResponseStatus.FAILURE
        }
        const isTicketValid = !branding.configuration.ticketBarrierPageEnabled || profileResp.profile.type !== "none"
        loggedInContext.setUserState({
            jwtToken: profileResp.beConnectionToken,
            user: profileResp.profile,
            sessionAndTicketValid: isTicketValid
        })
        syncFavorites(favoritesContext, profileResp.profile.profileId)
        await createAppSyncUser(profileResp.profile)
        return ProfileResponseStatus.SUCCESS
    }
}

export async function personalizeTicketHelper(
    loggedInContext: LoggendInUserContextMethods,
    ticketCode: string
): Promise<ProfileResponseStatus> {
    const resp = await personalizeTicket(ticketCode)
    const error = resp as BackendServiceError
    if (error.httpStatus) {
        const json = error.responseJson
        if (json?.errorCode === "configurationIssue") {
            return ProfileResponseStatus.TICKET_CONFIGURATION_ISSUE
        } else if (json?.errorCode === "notValid") {
            return ProfileResponseStatus.TICKET_NOT_VALID
        } else if (json?.errorCode === "alreadyRedeemed") return ProfileResponseStatus.TICKET_ALREADY_REDEEMED

        return ProfileResponseStatus.TICKET_FAILURE
    } else {
        const profileResp = await getMyProfileData()
        if ((profileResp as any).httpStatus === 403) {
            loggedInContext.setUserState({ sessionAndTicketValid: false })
            return ProfileResponseStatus.TICKET_FAILURE
        } else {
            loggedInContext.setUserAndTicketValid(profileResp.content.profile, true)
            return ProfileResponseStatus.SUCCESS
        }
    }
}
