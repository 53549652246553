import { State, useState, createState } from "@hookstate/core"
import { debounce } from "lodash"
import { HTMLAttributes, useEffect, useState as useNativeState } from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import VizSensor from "react-visibility-sensor"
import { getMyIdFromLocalStorage } from "../globalStates/LoggedInUser"
import { defaultLogger as logger } from "../globalStates/AppState"
import { BackendServiceError, DynamoDBErrors } from "../backendServices/BackendServicesUtils"
import {
    getBatchPresenceByUserId,
    PresenceType,
    updateUserValues,
    UserBatchPresenceResponse
} from "../backendServices/GraphQLServices"

/* #region Types */
export interface PresenceIndicatorProps extends HTMLAttributes<HTMLDivElement> {
    right?: number
    top?: number
    userId: string
    size?: number
}

interface PresenceStatusProps {
    status: PresenceType
    color: string
}

const BadgePresenceDefault: PresenceStatusProps = {
    status: PresenceType.DEFAULT,
    color: "#B2B2B2"
}

const BadgeAvailable: PresenceStatusProps = {
    status: PresenceType.AVAILABLE,
    color: "#00B300"
}

const BadgeBusy: PresenceStatusProps = {
    status: PresenceType.BUSY,
    color: "#FFAA00"
}

const BadgeDoNotDisturb: PresenceStatusProps = {
    status: PresenceType.DONOTDISTURB,
    color: branding.dangerButtonColor
}

const BadgeOffwork: PresenceStatusProps = {
    status: PresenceType.OFFWORK,
    color: "#B2B2B2"
}

export enum EventType {
    INIT,
    EVENT_BEGIN,
    EVENT_END,
    SELECT,
    DONOTDISTURB_TOGGLE
}
/* #endregion */

/* #region Styles */

const StatusContainer = styled.div<PresenceIndicatorProps & { bgcolor: string }>`
    ${(props) => {
        let dynamicProperties = `background-color: ${props.bgcolor};`
        if (props.size) {
            dynamicProperties += `min-width:${props.size}px;`
            dynamicProperties += `width:${props.size}px;`
            dynamicProperties += `height:${props.size}px;`
        }
        if (props.right) dynamicProperties += `right:${props.right}px;`
        if (props.top) dynamicProperties += `top:${props.top}px;`

        return dynamicProperties
    }}
    position: absolute;
    border: solid 2px white;
    border-radius: 100%;
    color: white;
`
/* #endregion */

export default function PresenceIndicator(props: PresenceIndicatorProps) {
    const state = usePresenceState()
    const [visible, setVisible] = useNativeState(false)

    const userId = props.userId

    useEffect(() => {
        if (visible && branding.presenceConfiguration.usePresence) addInterest(userId)
        return () => removeInterest(userId)
    }, [userId, visible])

    if (!branding.presenceConfiguration.usePresence) return null

    const presenceProps = getPropsForPresence(state.getPresence(userId))
    return (
        <VizSensor onChange={setVisible} partialVisibility={true}>
            <StatusContainer {...props} bgcolor={presenceProps.color} />
        </VizSensor>
    )
}

/* #region Subscription */
const userIdsWhosePresenceStateWeAreInterestedIn: {
    [key in string]: number
} = {}

function addInterest(userId: string) {
    // If we already have subscriptions for this users presence, increase the count
    if (userIdsWhosePresenceStateWeAreInterestedIn[userId] > 0) userIdsWhosePresenceStateWeAreInterestedIn[userId]++
    // Otherwise add new subscription for it
    else userIdsWhosePresenceStateWeAreInterestedIn[userId] = 1

    // Request Presence update
    requestPresenceStatesDebounced()
}

function removeInterest(userId: string) {
    // if we only have one remaining subscription for this users presence, remove it from the data set
    if (userIdsWhosePresenceStateWeAreInterestedIn[userId] === 1) delete userIdsWhosePresenceStateWeAreInterestedIn[userId]
    // if we have subscriptions for this users presence, remove one
    else if (userIdsWhosePresenceStateWeAreInterestedIn[userId] > 1) userIdsWhosePresenceStateWeAreInterestedIn[userId]--
}

let debounceTime = branding.presenceConfiguration.minRequestDebounceMillis
let requestPresenceStatesDebounced = debounce(requestPresenceStates, debounceTime)

/* #endregion */

/* #region Data Updates */
async function requestPresenceStates() {
    let idsToFetch = Object.keys(userIdsWhosePresenceStateWeAreInterestedIn)
    // Nothing to do if there are no subscriptions
    if (idsToFetch.length === 0) return
    // There shouldn't be a case where there are more then 100 presence indicators on screen.
    // so we only request 100 at maximum. Everything over 100 is discard in order to prevent breaching the api limit
    handleResults(await getBatchPresenceByUserId(idsToFetch.slice(0, 100)))
}

function handleResults(result: UserBatchPresenceResponse[] | BackendServiceError) {
    let needsBackoff = false
    const newPresences: Presences = {}
    if ((result as BackendServiceError).httpStatus === 429) {
        needsBackoff = true
    } else {
        for (const presence of result as UserBatchPresenceResponse[]) {
            if (!presence) continue // continue for null values, those are not found users. e.g. when working locally without having initialized the other users
            newPresences[presence.id] = presence
        }
    }
    accessPresenceState.updatePresences(newPresences)
    if (needsBackoff) {
        increaseDebounce()
    } else {
        decreaseDebounce()
    }
}

function increaseDebounce() {
    debounceTime *= branding.presenceConfiguration.requestDebounceIncFactor
    requestPresenceStatesDebounced = debounce(requestPresenceStates, debounceTime)
}

function decreaseDebounce() {
    if (debounceTime === branding.presenceConfiguration.minRequestDebounceMillis) return
    debounceTime = Math.round(
        Math.max(
            branding.presenceConfiguration.minRequestDebounceMillis,
            debounceTime * branding.presenceConfiguration.requestDebounceDecFactor
        )
    )
    requestPresenceStatesDebounced = debounce(requestPresenceStates, debounceTime)
}
/* #endregion */

/* #region State */
type Presences = {
    [key in string]: UserBatchPresenceResponse
}

const getStartValues = (): Presences => {
    return {}
}
const state = createState<Presences>(getStartValues())

interface PresenceContext {
    getPresence: (userId: string) => PresenceType
    getMyPresence: () => PresenceType
    updateMyPresence: (event: EventType, presence?: PresenceType) => void
    updatePresences: (newValues: Presences) => void
}

// this presence saves the my presence state from before a audio/video call to reset it after it ends
let presenceBeforeEvent: PresenceType | undefined = undefined

const executeStateWrapper = (state: State<Presences>) => {
    return {
        getPresence: (userId: string) => {
            return state.value[userId]?.presenceStatus ?? PresenceType.DEFAULT
        },
        updatePresences: (newValues: Presences) => {
            state.set((prev) => {
                // Only iterate over new values. Because only those are visible and therefore updated
                for (const userId in newValues) {
                    const newValue = newValues[userId]
                    // If lastConnected is to far behind the current date, we change the presence to offline.
                    const newPresenceState =
                        new Date().getTime() - Date.parse(newValue.lastConnected) >
                        branding.presenceConfiguration.offlineAfterXMillis
                            ? PresenceType.OFFWORK
                            : newValue.presenceStatus
                    prev[userId] = {
                        id: newValue.id,
                        presenceStatus: newPresenceState,
                        lastConnected: newValue.lastConnected
                    }
                }
                return prev
            })
        },
        getMyPresence: () => {
            const myUserId: string | undefined = getMyIdFromLocalStorage()
            if (myUserId && state.value[myUserId]) return state.value[myUserId].presenceStatus
            else return PresenceType.AVAILABLE
        },
        updateMyPresence: (event: EventType, presence?: PresenceType) => {
            const myUserId: string | undefined = getMyIdFromLocalStorage()
            if (myUserId) {
                let newPresence: PresenceType = PresenceType.AVAILABLE
                const currentPresence = state.value[myUserId]?.presenceStatus
                if (event === EventType.INIT) {
                    newPresence = (localStorage.getItem("presenceBeforeRefresh") as PresenceType) ?? PresenceType.AVAILABLE
                } else if (event === EventType.SELECT && presence) {
                    newPresence = presence
                } else if (event === EventType.EVENT_BEGIN) {
                    presenceBeforeEvent =
                        currentPresence === PresenceType.DONOTDISTURB ? PresenceType.DONOTDISTURB : PresenceType.AVAILABLE
                    newPresence = currentPresence === PresenceType.DONOTDISTURB ? PresenceType.DONOTDISTURB : PresenceType.BUSY
                } else if (event === EventType.EVENT_END) {
                    newPresence = presenceBeforeEvent
                        ? presenceBeforeEvent!
                        : currentPresence === PresenceType.DONOTDISTURB
                        ? PresenceType.DONOTDISTURB
                        : PresenceType.AVAILABLE
                    presenceBeforeEvent = undefined
                } else if (event === EventType.DONOTDISTURB_TOGGLE && presence) {
                    if (presenceBeforeEvent === undefined) newPresence = presence
                    else {
                        if (presence === PresenceType.DONOTDISTURB) {
                            newPresence = presence
                            presenceBeforeEvent = presence
                        } else {
                            newPresence = PresenceType.BUSY
                            presenceBeforeEvent = PresenceType.AVAILABLE
                        }
                    }
                }

                ;(async () => {
                    const respUpdate = await updateUserValues({
                        id: myUserId,
                        presenceStatus: newPresence,
                        lastConnected: new Date().toISOString()
                    })

                    if ((respUpdate as BackendServiceError).httpStatus) {
                        logger.error(respUpdate)
                    } else if ((respUpdate as DynamoDBErrors)?.errors) {
                        ;(respUpdate as DynamoDBErrors).errors.map((error) => logger.error(error))
                    } else {
                        state.set((prev) => {
                            prev[myUserId] = {
                                id: myUserId,
                                presenceStatus: newPresence,
                                lastConnected: new Date().toISOString()
                            }
                            return prev
                        })
                    }
                })()
            }
        }
    }
}

export const accessPresenceState: PresenceContext = executeStateWrapper(state)
export const usePresenceState = (): PresenceContext => executeStateWrapper(useState(state))
/* #endregion */

/* #region HelperFunctions */
function getPropsForPresence(type: PresenceType): PresenceStatusProps {
    switch (type) {
        case PresenceType.AVAILABLE:
            return BadgeAvailable
        case PresenceType.BUSY:
            return BadgeBusy
        case PresenceType.DONOTDISTURB:
            return BadgeDoNotDisturb
        case PresenceType.OFFWORK:
            return BadgeOffwork
        default:
            return BadgePresenceDefault
    }
}
/* #endregion */
