import branding from "../branding/branding"
import { getActiveLanguage } from "../globalStates/LanguageState"
import {
    getMyEmailFromLocalStorage,
    getMyIdFromLocalStorage,
    getMyTypeFromLocalStorage,
    getThirdPartyDataFromLocalStorage
} from "../globalStates/LoggedInUser"
import { LoginWithSSOKoelnmesseThirdPartyData } from "../contentArea/loginregistration/ssoKoelnmesse/LoginWithSSOKoelnmesse"

declare global {
    interface Window {
        dataLayer: any[] | undefined
    }
}

export function track(data: any) {
    if (branding.gtmScriptDataLayer) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }
    if ("true" === localStorage.getItem("virtualguide-gtm-debug")) {
        console.log("GTM track", data)
    }
}

let lastNavigation: string | undefined
export function trackNavigation(pageTitle: string) {
    if (!pageTitle) return
    const newNavigation = window.location.pathname + pageTitle
    if (newNavigation === lastNavigation) return
    lastNavigation = newNavigation
    trackPageMetaData()
    trackPagePath(pageTitle)
}

async function trackPageMetaData() {
    const trackingValue = {
        event: "Page Meta",
        websiteType: "DEP",
        exhibitionNumber: getFairNumber(),
        language: getActiveLanguage().toLocaleUpperCase(),
        trafficType: getInternExtern(),
        customerType: getCustomerType(),
        loggedIn: getLoggedInState(), //Login Status des Nutzers
        environment: "HFX DEP",
        userId: (getThirdPartyDataFromLocalStorage() as LoginWithSSOKoelnmesseThirdPartyData)?.uid
    }
    track(trackingValue)
}

function trackPagePath(pageTitle: string) {
    let trackingParams = {
        event: "virtPagePath",
        pagePath: window.location.pathname,
        pageTitle: pageTitle
    }
    if (window.location.pathname.includes("/person/")) {
        trackingParams = {
            event: "virtPagePath",
            pagePath: "/person/",
            pageTitle: "Personenprofil"
        }
    }
    track(trackingParams)
}

export async function trackMeetingHeartbeatEvent(meetingKind: string, progress: number) {
    const trackingValue = {
        event: "gaEvent",
        eventCategory: "Heartbeat",
        eventAction: meetingKind,
        eventLabel: "Fortschritt " + progress
    }
    track(trackingValue)
}

function getLoggedInState(): "Eingeloggt" | "Ausgeloggt" {
    return getMyIdFromLocalStorage() ? "Eingeloggt" : "Ausgeloggt"
}

function getFairNumber() {
    return branding.gtmFairNumber
}

const internExternInfoMap: { [email in string]: boolean } = {}
function getInternExtern(): "intern" | "extern" | "" {
    const myEmail = getMyEmailFromLocalStorage()
    if (!myEmail) return ""
    let intern = false
    if (myEmail in internExternInfoMap) intern = internExternInfoMap[myEmail]
    else {
        for (const internEmail of branding.configuration.internEmailAdresses) {
            if (myEmail.match(internEmail)) {
                intern = true
                break
            }
        }
        internExternInfoMap[myEmail] = intern
    }

    return intern ? "intern" : "extern"
}

function getCustomerType(): "Aussteller" | "Besucher" | "Medienvertreter" | "Aussteller" | "Besucher" | "Other" {
    const myType = getMyTypeFromLocalStorage()
    if (!myType) {
        return "Other"
    }
    switch (myType) {
        case "speaker":
        case "staff":
            return "Aussteller"
        case "press":
            return "Medienvertreter"
        case "attendee":
        case "guest":
        case "none":
            return "Besucher"

        default:
            return "Other"
    }
}

export function trackCheckIn(kind: "Erfolgreich" | "Kein Ticket" | "Fehlermeldung") {
    // DO NOT translate those values.
    track({
        event: "gaEvent",
        eventCategory: "Check-in",
        eventAction: "DEP",
        eventLabel: kind,
        check_in_DEP: {
            action: kind
        }
    })
}
