import { lazy, StrictMode, Suspense, useEffect } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import * as serviceWorker from "./serviceWorker"

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "react-datepicker/dist/react-datepicker.css"
import "react-bootstrap/dist/react-bootstrap.min.js"

import GlobalErrorBoundary from "./contentArea/errorPages/GlobalErrorBoundary"
import CenteredLoader from "./ui/CenteredLoader"
import { BrowserRouter as Router, useRouteMatch } from "react-router-dom"
import queryString from "query-string"
import { getIamPartOf } from "./globalStates/IAmPartOf"
import { isEventAnnouncementPhase, isTicketSalePhase, isOnboardingPhase } from "./utils/EventPhaseChecker"
import useWindowDimensions from "./ui/WindowDimensionsHook"
import styled from "styled-components"
import { CenterLoginStyleOverlay, CenterLoginStyleRoot } from "./contentArea/loginregistration/LoginRegistrationSite"
import branding, { setBrandingLanguage } from "./branding/branding"
import { getActiveLanguage, useLanguageState } from "./globalStates/LanguageState"
import { useLoggedInState } from "./globalStates/LoggedInUser"
import { useAppState } from "./globalStates/AppState"
import { helpPageRoute, systemCheckPageRoute } from "./navigationArea/RoutePaths"
import MobilePreLoginApp from "./MobilePreLoginApp"
import MobileWebsiteCheck from "./MobileWebsiteCheck"
import MobileSystemCheck from "./systemCheck/MobileSystemCheck"

const SystemCheck = lazy(() => import("./systemCheck/SystemCheck"))
const PreLoginApp = lazy(() => import("./PreLoginApp"))
const App = lazy(() => import("./App"))

export function shouldShowPreLoginApp() {
    const queryParams: any = queryString.parse(window.location.search)
    return isEventAnnouncementPhase || isTicketSalePhase || (isOnboardingPhase && getIamPartOf(queryParams) !== "onboarding")
}

function Application() {
    const userLink = useLoggedInState()
    const appState = useAppState()

    useEffect(() => {
        setBrandingLanguage(getActiveLanguage() as any)
    }, [])

    // Importing the Jira desk support external script
    const createJsdWidget = () => {
        if (!branding.jiraSupportDeskData || !branding.jiraSupportDeskData.visible || document.getElementById("jsd-widget"))
            return
        function jiraHelpdesk(callback: { (): void; (): void }) {
            var jhdScript = document.createElement("script")
            jhdScript.type = "text/javascript"
            jhdScript.setAttribute("data-jsd-embedded", "true")
            jhdScript.setAttribute("data-key", branding.jiraSupportDeskData.key)
            jhdScript.setAttribute("data-base-url", "https://jsd-widget.atlassian.com")
            jhdScript.src = "https://jsd-widget.atlassian.com/assets/embed.js"
            jhdScript.onload = function () {
                callback()
                setTimeout(() => {
                    setJsdWidgetPosition()
                    setJsdWidgetStyle()
                }, 0)
            }
            document.getElementsByTagName("head")[0].appendChild(jhdScript)
        }

        jiraHelpdesk(function () {
            var DOMContentLoaded_event = document.createEvent("Event")
            DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true)
            window.document.dispatchEvent(DOMContentLoaded_event)
        })
    }

    useEffect(() => {
        createJsdWidget()
        // eslint-disable-next-line
    }, [])

    const setJsdWidgetStyle = () => {
        let jsdWidget = document.getElementById("jsd-widget")

        if (jsdWidget) {
            let doc = (jsdWidget as HTMLIFrameElement).contentDocument
            const style =
                "<style>#help-button{ height: 30px !important; width: 30px !important; display: flex; justify-content: center; align-items: center; font-size: 20px !important; line-height: 20px !important;} </style>"
            if (doc) doc.body.innerHTML = doc.body.innerHTML + style
        }
    }

    const setJsdWidgetPosition = () => {
        // Move widget on the communication area toggle
        let jsdWidget = document.getElementById("jsd-widget")
        const routesToHideWidgetFrom = [helpPageRoute]

        if (jsdWidget) {
            if (routesToHideWidgetFrom.includes(window.location.pathname)) {
                jsdWidget.style.visibility = "hidden"
            } else {
                jsdWidget.style.visibility = "visible"
                jsdWidget.style.right = (!userLink.isLoggedIn ? 0 : appState.isNetworkingOpen() ? 220 : 5) + "px"
                jsdWidget.style.bottom = (!userLink.isLoggedIn ? 0 : appState.isNetworkingOpen() ? 0 : 40) + "px"
            }
        }
    }

    useEffect(() => {
        setJsdWidgetPosition()
        // eslint-disable-next-line
    }, [appState.isNetworkingOpen(), window.location.pathname, userLink.isLoggedIn])

    const checkMatch = useRouteMatch(systemCheckPageRoute + "*")
    const { isMobile } = useWindowDimensions()
    const showMobileWebsiteCheck =
        isMobile &&
        branding.loginRegistrationSite.appDownloadModeEnabled &&
        !localStorage.getItem("virtualGuide-mobileWebsiteEnabled")

    if (!isDeviceAvailable(isMobile)) {
        return <NotSupportedScreenSize />
    } else if (showMobileWebsiteCheck) {
        return <MobileWebsiteCheck />
    } else if (checkMatch) {
        return isMobile ? <MobileSystemCheck /> : <SystemCheck />
    } else if (shouldShowPreLoginApp()) {
        return isMobile ? <MobilePreLoginApp /> : <PreLoginApp />
    } else {
        return <App />
    }
}

const NotSupportedDeviceScreen = styled(CenterLoginStyleRoot)``
const NotSupportedDeviceScreenText = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${branding.secondaryTextColor};
    font-family: ${branding.font1};
    white-space: pre-line;

    & .content-text {
        display: inline-block;
        text-align: center;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid #fff;
    }
`

const isDeviceAvailable = (isMobile: boolean) => {
    if (branding.loginRegistrationSite.enableMinimalResolutionCheck && isMobile) {
        return false
    }
    return true
}

const NotSupportedScreenSize = () => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    return (
        <NotSupportedDeviceScreen>
            <CenterLoginStyleOverlay>
                <NotSupportedDeviceScreenText>
                    <span className={"content-text"}>{strings.loginRegistrationSite.notSupportedScreenSize}</span>
                </NotSupportedDeviceScreenText>
            </CenterLoginStyleOverlay>
        </NotSupportedDeviceScreen>
    )
}

ReactDOM.render(
    <StrictMode>
        <Suspense fallback={<CenteredLoader>Loading...</CenteredLoader>}>
            <GlobalErrorBoundary>
                <Router>
                    <Application />
                </Router>
            </GlobalErrorBoundary>
        </Suspense>
    </StrictMode>,
    document.getElementById("virtualGuide")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
