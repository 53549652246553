import { useRef, useEffect } from "react"
import videojs, { VideoJsPlayer } from "video.js"
import styled from "styled-components"
import { keyframes } from "styled-components"
import CustomLogoImage from "./CustomLogoImage"
import { useAppState } from "../../globalStates/AppState"
import "./Video-js.css"
import { useLanguageState } from "../../globalStates/LanguageState"
import { getActiveLanguage } from "../../globalStates/LanguageState"
import CustomOverlayForLiveStreams from "./CustomOverlayForRemoteLivestreams"
import { RemoteLivestream } from "../../backendServices/Types"

/** livestream-capable video-player which uses the open source library video.js
video.js implements HLS-Streaming
the UI is styled by changing the default CSS-skin that video.js provides 
the live-prop switches between live-stream-player (true) or video-on-demand-player (false)
*/

/* ################### live stream player logic #######################################*/

export enum VideoMimeType {
    M3U8 = "application/x-mpegURL",
    MP4 = "video/mp4",
    MOV = "video/quicktime"
}

interface VideoSource {
    src: string
    type: VideoMimeType
}

export function determineSourceType(source: string, sourceIsStatic: boolean): VideoMimeType {
    if (source.endsWith(".mp4") || source.endsWith(".mov")) {
        // use MP4: it works for both .mp4- and .mov-files correctly
        return VideoMimeType.MP4
    } else if (source.endsWith(".m3u8")) {
        // live-streams
        return VideoMimeType.M3U8
    } else if (sourceIsStatic) {
        // static files: if they have no file ending, the player needs to be set to sourceIsStatic explicitly
        return VideoMimeType.MP4
    } else {
        return VideoMimeType.M3U8
    }
}

export const usePlayer = (
    src: string,
    controls: boolean,
    live: boolean,
    autoPlay: boolean = false,
    sourceIsStatic: boolean = false,
    disablePlayerStateIsSaved: boolean = false
) => {
    const strings = useLanguageState().getStrings()
    const appState = useAppState()
    let videoSources: VideoSource[] = []

    videoSources = [
        {
            src: src,
            type: determineSourceType(src, sourceIsStatic)
        }
    ]

    const options = {
        autoplay: autoPlay,
        controls: controls,
        preload: "auto",
        liveui: true,
        fluid: true,
        userActions: {
            hotkeys: true
        },
        html5: {
            vhs: {
                withCredentials: true,
                enableLowInitialPlaylist: true,
                overrideNative: true
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false
        },
        sources: videoSources
    }

    const videoRef = useRef(null)

    // instantiate and dispose of player only once
    // similar to componentDidMount / componentDidUpdate / componentWillUnmount
    // do something after render
    useEffect(() => {
        let audioTrackList: videojs.TrackList

        function addTrack(e: Event) {
            if (!audioTrackList) return
            // don't do anything, if only one audio track was added
            if (audioTrackList.length > 1) {
                /** if audio track was previously set in appState, choose this audio track */
                const audioTrackFromAppState = appState.videoPlayerStatus?.audioTrack
                if (audioTrackFromAppState) {
                    for (let i = 0; i < audioTrackList.length; i++) {
                        let track = audioTrackList[i]
                        if (track !== undefined) {
                            const trackAny = track as any
                            if (trackAny.label === audioTrackFromAppState) {
                                // setting enabled to true does not seem to trigger a change event, so we trigger it ourselves
                                // for more information see trigger function in events.js, where element does not seem to have dispatcher:
                                // https://github.com/videojs/video.js/blob/4238f5c1d88890547153e7e1de7bd0d1d8e0b236/src/js/utils/events.js#L440
                                trackAny.enabled = true
                                audioTrackList.trigger("change")
                            } else {
                                trackAny.enabled = false
                            }
                        }
                    }
                } else {
                    // take application language if it corresponds with one of the available channels
                    const languageFromAppState = getActiveLanguage()
                    // refers to the NAME parameter of the EXT-X-MEDIA tag, i.e. the audio channel language in the m3u8-manifest
                    let possibleLanguageStrings: string[] = []

                    if (languageFromAppState === "en") {
                        possibleLanguageStrings = ["english", "englisch", "en", "eng", "engl"]
                    } else if (languageFromAppState === "de") {
                        possibleLanguageStrings = ["deutsch", "de", "deu", "german", "ger"]
                    }
                    for (let i = 0; i < audioTrackList.length; i++) {
                        let track = audioTrackList[i]
                        if (track !== undefined) {
                            const trackAny = track as any
                            let shouldActivate = false
                            possibleLanguageStrings.forEach((langString) => {
                                if (trackAny.label.toLowerCase() === langString) {
                                    shouldActivate = true
                                }
                            })
                            if (shouldActivate) {
                                trackAny.enabled = true
                                audioTrackList.trigger("change")
                            } else {
                                trackAny.enabled = false
                            }
                        }
                    }
                }
            }
        }

        function assertOneTrack(e: Event) {
            if (!audioTrackList) return
            // prevent activating two audio tracks at the same time or none at all,
            // i.e. only one audio track is active at all times
            if (audioTrackList.length > 1) {
                let count = 0
                for (let i = 0; i < audioTrackList.length; i++) {
                    let track = audioTrackList[i]
                    if (track !== undefined) {
                        const trackAny = track as any
                        if (trackAny.enabled) {
                            count++
                        }
                    }
                }
                if (count !== 1) {
                    e.stopImmediatePropagation()
                }
            }
        }

        const vjsPlayer = videojs(videoRef?.current!, { ...options }, function (this: VideoJsPlayer) {
            /*  on player ready callback */
            // read volume level, mute-state and play/pause-state from appState, so it stays the same when user switches
            // between Video- and PiP-Player

            /** the following lines deal with the feature, that video.js-Player allows a state where volume is 0 but mute 
            is set to false and vice versa, this is probably due to the fact that muting can be toggled via two UI-Elements:
            volume-slider and mute-toggle-button,
            this is probably on purpose so the user can mute player via mute button and then switch back to the previous
            volume, when mute is turned off again */
            const tmpAppstate = appState.videoPlayerStatus
            if (tmpAppstate) {
                if (tmpAppstate.volume === 0) {
                    this.volume(0)
                    this.muted(true)
                    this.trigger("volumechange") // update UI-Element Volume-Bar
                } else if (tmpAppstate.isMuted === true) {
                    this.muted(true)
                    this.trigger("volumechange") // update UI-Element Volume-Bar
                } else {
                    if (tmpAppstate.volume) {
                        this.volume(tmpAppstate.volume)
                    }
                    this.muted(false)
                    this.trigger("volumechange") // update UI-Element Volume-Bar
                }
                if (tmpAppstate.isPaused !== undefined && !autoPlay && !disablePlayerStateIsSaved) {
                    if (tmpAppstate.isPaused) {
                        vjsPlayer.pause()
                    } else {
                        vjsPlayer.play()
                    }
                }
            }

            /** pick audio language channel if several are available */
            audioTrackList = vjsPlayer.tech({ IWillNotUseThisInPlugins: true })?.audioTracks() // { IWillNotUseThisInPlugins: true } suppresses warning about tech usage
            if (audioTrackList) {
                audioTrackList.addEventListener("addtrack", addTrack)
                audioTrackList.addEventListener("change", assertOneTrack)
            }
        }) // end player ready callback

        // load error is displayed using appstrings from branding.js
        videojs.addLanguage("de", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.videoPlayerStreamErrorMessage
        })

        videojs.addLanguage("en", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.videoPlayerStreamErrorMessage
        })

        // remove unnecessary items from the controlbar
        const controlBar = vjsPlayer.getChild("ControlBar")

        if (controlBar) {
            const pip = controlBar.getChild("PictureInPictureToggle")
            if (pip) {
                controlBar.removeChild(pip)
            }
            const remainingTimeDisplay = controlBar.getChild("RemainingTimeDisplay")
            if (remainingTimeDisplay) {
                controlBar.removeChild(remainingTimeDisplay)
            }
            const playbackRateMenuButton = controlBar.getChild("PlaybackRateMenuButton")
            if (playbackRateMenuButton) {
                controlBar.removeChild(playbackRateMenuButton)
            }

            const chaptersButton = controlBar.getChild("ChaptersButton")
            if (chaptersButton) {
                controlBar.removeChild(chaptersButton)
            }

            const descriptionsButton = controlBar.getChild("DescriptionsButton")
            if (descriptionsButton) {
                controlBar.removeChild(descriptionsButton)
            }

            const subtitlesButton = controlBar.getChild("SubsCapsButton")
            if (subtitlesButton) {
                controlBar.removeChild(subtitlesButton)
            }

            const seekToLive = controlBar.getChild("SeekToLive")
            if (seekToLive && !live) {
                controlBar.removeChild(seekToLive)
            }
        }

        // returned function is called as cleanup
        return () => {
            const audioTrackList = vjsPlayer.tech({ IWillNotUseThisInPlugins: true })?.audioTracks()
            let audioTrack: string | undefined = undefined
            if (audioTrackList) {
                for (let i = 0; i < audioTrackList.length; i++) {
                    let track = audioTrackList[i]
                    if (track !== undefined) {
                        const trackAny = track as any
                        if (trackAny.enabled) {
                            audioTrack = trackAny.label
                            break
                        }
                    }
                }
            }
            // write volume level, mute-state, play/pause-state and audio track (language) into appState,
            // so it stays the same when user switches between Video- and PiP-Player
            if (disablePlayerStateIsSaved) {
                appState.setVideoPlayerStatus({
                    volume: appState.videoPlayerStatus?.volume,
                    isMuted: appState.videoPlayerStatus?.isMuted,
                    isPaused: appState.videoPlayerStatus?.isPaused,
                    audioTrack: audioTrack
                })
            } else {
                appState.setVideoPlayerStatus({
                    volume: vjsPlayer.volume(),
                    isMuted: vjsPlayer.muted(),
                    isPaused: vjsPlayer.paused(),
                    audioTrack: audioTrack
                })
            }

            if (audioTrackList) {
                audioTrackList.removeEventListener("change", assertOneTrack)
                audioTrackList.removeEventListener("addtrack", addTrack)
            }

            vjsPlayer.dispose()
        }
    }, []) //eslint-disable-line

    return videoRef
} // end usePlayer

/** properties that are passed to VideoPlayerReal */
interface VideoPlayerRealProps {
    src: string
    title?: string
    controls: boolean
    live: boolean
    disablePlaying?: boolean
    remoteStreamLayout?: boolean
    remoteLivestream?: RemoteLivestream
    disablePlayerStateIsSaved?: boolean
    /** pass userAccessState.isUnlocked() here to update component when access is changed in backoffice
     * we don't actually do anything with this property here */
    rerenderOnUnlockedChanged?: boolean
    sourceIsStatic?: boolean
    autoPlay?: boolean
    onPlay?: (timeStamp: number) => void
    onPause?: (timeStamp: number) => void
    onSeeking?: () => void
    onSeeked?: (timeStamp: number) => void
    // pass userAccessState.isUnlocked() here to update component when access is changed in backoffice
    // we don't actually do anything with this property here
}

/**
 *
 * @param props properties that determine state of VideoPlayerReal
 * @returns livestream-capable video-player which uses the open source library video.js
 */
const VideoPlayerReal = (props: VideoPlayerRealProps) => {
    const playerRef = usePlayer(
        props.src,
        props.controls,
        props.live,
        props.autoPlay,
        props.sourceIsStatic,
        props.disablePlayerStateIsSaved
    )

    return (
        <VideoJSPlayerRoot disablePlaying={props.disablePlaying} remoteStreamLayout={props.remoteStreamLayout}>
            <div data-vjs-player>
                <video
                    onPlay={(e) => props.onPlay && props.onPlay(e.currentTarget.currentTime)}
                    onPause={(e) => props.onPause && props.onPause(e.currentTarget.currentTime)}
                    onSeeking={(e) => props.onSeeking && props.onSeeking()}
                    onSeeked={(e) => props.onSeeked && props.onSeeked(e.currentTarget.currentTime)}
                    ref={playerRef}
                    className="video-js"
                    data-isPip="false"
                    data-isStream={props.live}
                    title={props.remoteLivestream?.title ?? props.title}
                />
                {!props.remoteStreamLayout && <CustomLogoImage />}
            </div>
            {props.remoteStreamLayout && <CustomOverlayForLiveStreams remoteLivestream={props.remoteLivestream!} />}
        </VideoJSPlayerRoot>
    )
}

/* ################### UI styling #####################################################*/

// keyframes for fading animations (pause and play)

// pause animation
const screenFadePause = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
`

const logoFadePause = keyframes`
    from {
        opacity: 0.25;
        transform: translate(-50%, -50%) scale(2);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`

// play animation
const screenFadePlay = keyframes`
    from {
        opacity: 0.25;
    }

    to {
        opacity: 1;
    }
`

const logoFadePlay = keyframes`
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    to {
        opacity: 0.25;
        transform: translate(-50%, -50%) scale(2);
    }
`

interface VideoJSPlayerRootProps {
    disablePlaying?: boolean
    remoteStreamLayout?: boolean
}

export const VideoJSPlayerRoot = styled.div<VideoJSPlayerRootProps>`
    background-color: ${(props) => (props.remoteStreamLayout ? "transparent" : "#000")};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    ${(props) => (props.disablePlaying ? "pointer-events: none" : "")};
    ${(props) => (props.remoteStreamLayout ? "cursor: pointer" : "")};

    & .video-js {
        background-color: ${(props) => (props.remoteStreamLayout ? "transparent" : "#000")};
    }

    /* video player  */
    & div[data-vjs-player="true"] {
    }

    /* video element */
    & .vjs-tech {
        border-radius: ${(props) => (props.remoteStreamLayout ? "5px;" : "0;")};
    }

    /* paused: fade out to darkened screen and fade in logo when paused */
    & .vjs-paused .vjs-tech {
        animation: ${screenFadePause} 0.2s linear;
        opacity: 0.25;
    }

    & :not(.vjs-has-started) .vjs-logo {
        opacity: 0;
    }

    & .vjs-logo {
        cursor: default;
        pointer-events: none;
        /* center logo */
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /* logo overlays video */
        z-index: 800;
    }

    & .video-js.vjs-paused.vjs-has-started .vjs-logo {
        opacity: 1;
        animation: ${logoFadePause} 0.2s linear;
    }

    /* playing: reverse on-pause-animation when playback resumes*/
    & .video-js.vjs-playing .vjs-tech {
        animation: ${screenFadePlay} 0.2s linear;
    }

    & .video-js.vjs-playing.vjs-has-started .vjs-logo {
        animation: ${logoFadePlay} 0.2s linear;
        opacity: 0;
    }

    & .video-js:hover .vjs-big-play-button {
        background-color: transparent;
    }

    /* control bar */
    & .vjs-control-bar {
        height: 80px;
        width: auto;
        padding-top: 20px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100  &1+0,0+100 */
        background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
        border-radius: ${(props) => (props.remoteStreamLayout ? "5px;" : "0;")};
    }

    & .video-js.vjs-playing .vjs-control-bar,
    & .video-js.vjs-scrubbing .vjs-control-bar,
    & .video-js.vjs-seeking .vjs-control-bar,
    & .video-js.vjs-waiting .vjs-control-bar {
        display: ${(props) => (props.remoteStreamLayout ? "flex" : "")};
    }

    &
        :not(.video-js.vjs-playing):not(.video-js.vjs-scrubbing):not(.video-js.vjs-seeking):not(.video-js.vjs-waiting)
        .vjs-control-bar {
        display: ${(props) => (props.remoteStreamLayout ? "none" : "flex")};
    }

    & .video-js.vjs-playing + .remote-livestream-overlay,
    & .video-js.vjs-scrubbing + .remote-livestream-overlay,
    & .video-js.vjs-seeking + .remote-livestream-overlay,
    & .video-js.vjs-waiting + .remote-livestream-overlay {
        display: ${(props) => (props.remoteStreamLayout ? "none" : "flex")};
    }

    &
        :not(.video-js.vjs-playing):not(.video-js.vjs-scrubbing):not(.video-js.vjs-seeking):not(.video-js.vjs-waiting)
        + .remote-livestream-overlay {
        display: ${(props) => (props.remoteStreamLayout ? "flex" : "none")};
    }

    /* control buttons - icons */
    & .vjs-big-play-button {
        display: ${(props) => (props.remoteStreamLayout ? "none" : "")};
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        margin: center !important;
        height: 18% !important;
        width: 10% !important;
        border-style: none !important;
        /* center big play button */
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    & .vjs-button[title="Play"],
    & .vjs-button[title="Play Video"] {
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        margin-left: 32px !important;
        width: 23px !important;
    }

    & .vjs-button[title="Pause"] {
        background: url(/videoPlayerIcons/pause.svg) no-repeat;
        margin-left: 29px !important;
    }

    & .vjs-button[title="Picture-in-Picture"] {
        background: url(/videoPlayerIcons/minimize.svg) no-repeat;
        margin-left: 16px !important;
    }

    & .vjs-button[title="Exit Picture-in-Picture"] {
        background: url(/videoPlayerIcons/maximize.svg) no-repeat;
        margin-left: 16px !important;
    }

    & .vjs-button[title="Fullscreen"] {
        background: url(/videoPlayerIcons/fullscreen.svg) no-repeat;
        margin-right: 29px !important;
        margin-top: 1px !important;
        height: 38px !important;
        width: 38px !important;
    }

    & .vjs-button[title="Non-Fullscreen"] {
        background: url(/videoPlayerIcons/exit-fullscreen.svg) no-repeat;
        margin-right: 29px !important;
        margin-top: 1px !important;
        height: 38px !important;
        width: 38px !important;
    }

    & .vjs-button[title="Mute"] {
        background: url(/videoPlayerIcons/volume-3.svg) no-repeat;
    }

    & .vjs-mute-control[title="Unmute"] {
        background: url(/videoPlayerIcons/volume-mute.svg) no-repeat;
    }

    & .vjs-button[title="Replay"] {
        background: url(/videoPlayerIcons/replay.svg) no-repeat;
        margin-left: 32px !important;
    }

    & .vjs-button[title="Captions"] {
        background: url(/videoPlayerIcons/subtitles.svg) no-repeat;
    }

    & .vjs-theatermode-control {
        background: url(/videoPlayerIcons/theatre-mode.svg) no-repeat;
        height: 38px !important;
        width: 38px !important;
        margin-top: 1px !important;
    }

    /* animate volume icon for different levels */
    & .vjs-icon-volume-low {
        background: url(/videoPlayerIcons/volume-low.svg) no-repeat;
    }

    & .vjs-button.vjs-vol-1[title="Mute"] {
        background: url(/videoPlayerIcons/volume-1.svg) no-repeat;
    }
    & .vjs-button.vjs-vol-2[title="Mute"] {
        background: url(/videoPlayerIcons/volume-2.svg) no-repeat;
    }

    /* headphone button when different audio tracks available */
    & .vjs-button[title="Audio Track"] {
        background: url(/videoPlayerIcons/music-note.svg) no-repeat;
    }

    /* remove yellow border from buttons when hovered over or clicked */
    & .vjs-button:active,
    & .vjs-button:hover,
    & .vjs-button:focus,
    & .vjs-button:visited,
    & .vjs-big-play-button:active,
    & .vjs-big-play-button:hover,
    & .vjs-big-play-button:focus,
    & .vjs-big-play-button:visited,
    & .vjs-control-bar:focus {
        border-style: none !important;
        outline-style: none !important;
        background-color: transparent !important;
    }

    /* styling that all buttons in control bar have in common*/
    & .vjs-big-play-button,
    & .vjs-button[title="Play"],
    & .vjs-button[title="Play Video"],
    & .vjs-button[title="Pause"],
    & .vjs-button[title="Picture-in-Picture"],
    & .vjs-button[title="Exit Picture-in-Picture"],
    & .vjs-button[title="Fullscreen"],
    & .vjs-button[title="Mute"],
    & .vjs-mute-control[title="Unmute"],
    & .vjs-button[title="Non-Fullscreen"],
    & .vjs-button[title="Audio Track"],
    & .vjs-button.vjs-vol-1[title="Mute"],
    & .vjs-button.vjs-vol-2[title="Mute"],
    & .vjs-button[title="Audio Track"],
    & .vjs-button[title="Replay"],
    & .vjs-button[title="Captions"],
    & .vjs-theatermode-control {
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
        margin-top: 6.5px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 1px;
        padding: 0 0 0 0;
    }

    /* live button */
    & .vjs-seek-to-live-control {
        border: transparent !important;
        height: 31px;
        display: block;
        margin-top: 5px;
    }

    & .vjs-seek-to-live-control:focus {
        text-shadow: none;
        border-style: none !important;
        outline-style: none !important;
    }

    /* live button hover Effekte ?*/
    /*   & .vjs-seek-to-live-control:focus,
      & .vjs-seek-to-live-control:hover,
      & .vjs-seek-to-live-control:active,
      & .vjs-seek-to-live-control:visited
    {
        background: blue;
    } */

    /* live text */
    & .vjs-seek-to-live-text {
        user-select: none;
        font-size: 16px;
        overflow: hidden;
        margin-top: 10px;
    }

    & .vjs-seek-to-live-text:focus {
        outline: none;
    }

    /* red dot */
    & .vjs-icon-placeholder {
        font-size: 16px;
        overflow: hidden;
        vertical-align: -2px;
    }

    /* progress bar */
    & .vjs-progress-control {
        bottom: 10px;
    }

    /* white slide-bar */
    & .vjs-play-progress.vjs-slider-bar {
    }

    & .vjs-progress-holder {
    }

    /* white dot */
    & .vjs-load-progress {
    }

    /* little line that attaches to cursor as you hover the progress bar */
    & .vjs-mouse-display {
    }

    /* white progress bar and little circle at current position*/
    & .vjs-play-progress {
    }

    /* volume slider */
    & .vjs-volume-horizontal {
        margin-top: 5px;
    }
`

export default VideoPlayerReal
