import { Fragment, useState, useEffect } from "react"
import * as React from "react"
import { IconMeetings, IconLiveStream, IconNetworking, IconBusinessArea, IconProgram, IconCollections } from "../../ui/Icons"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import {
    conferencePageRoute,
    programPageRoute,
    networkingPageRoute,
    businessAreaPageRoute,
    relevantPeopleRoute,
    collectionOverviewRoute
} from "../../navigationArea/RoutePaths"
import { Exhibitor, Person, ShareTargetType } from "../../backendServices/Types"
import LiveEvent from "../../ui/LiveEvent"
import styled from "styled-components"
import { loadPersonsData } from "../../backendServices/PersonServices"
import branding from "../../branding/branding"
import MeetingRoomGroup from "../lounges/MeetingRoomGroup"
import { isLivePhase, isSoftOpeningPhase } from "../../utils/EventPhaseChecker"
import { getIamPartOf } from "../../globalStates/IAmPartOf"
import queryString from "query-string"
import PersonComponent from "../../ui/carousels/components/PersonComponent"
import { NavLink } from "react-router-dom"
import TileRow from "../../ui/TileRow"
import { TileWrapperSpeakers, MobileTileWrapperSpeakers } from "./ReceptionPageContent"
import DynamicBanner from "./DynamicBanner"
import { orderBy } from "lodash"
import VizSensor from "react-visibility-sensor"
import { MeetingRoomGroupType } from "../../conference/AudioVideoBranding"
import { ActionType } from "../entitiesActions/actionsUtils"
import { SimpleOrganization } from "../ExhibitorsPageContent"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import { Tile } from "../collection/CollectionOverview"
import { BasisPremiumType } from "../../branding/BasisPremiumBranding"
import { LobbyExhibitorsBasisPremiumType } from "./ReceptionPageContentBranding"
import { EntityCarousel } from "../../ui/carousels/EntityCarousel"
import { loadExhibitorsData } from "../../backendServices/ExhibitorServices"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { ContactItem, ContactListResponse, loadRelevantProfilesListData } from "../../backendServices/SeriesOfTopicsUserServices"
import { ChannelResponse, getChannels } from "../../backendServices/MeetingServices"

const IconContainer = styled.div`
    margin-top: 15px;
    margin-left: 85px;
    color: white;
    display: flex;
    justify-content: center;

    span {
        margin-left: 2px;
        margin-top: 0px;
    }
`

export const MobileShowfloorTile = styled(NavLink)<{ $background?: string }>`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 115px;
    width: 205px;
    color: white !important;
    ${(props) =>
        props.$background ? `background: url("${props.$background}");` : "background: url('/branding/no-profile.png');"}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; /* left top */
    padding: 25px;
    padding-left: 8px;
    border-radius: 5px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};

    ${IconContainer} {
        margin-top: -5px;
        //margin-top: 4px;
        margin-left: 0;
        margin-right: 20px;
    }

    .rs-row {
        position: initial;

        div {
            margin-top: 5px;
            width: 50px;
            height: 50px;
            display: none;
            img {
                width: 40px;
                height: 40px;
                margin-top: 3px;
            }
        }

        div:first-child {
            display: initial;
        }
    }

    &:active,
    &:link {
        text-decoration: none;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.receptionPage.lobbypageShowfloorTilesDarkenOverlay ?? "rgba(0,0,0, .5)"};
        border-radius: 5px;
    }

    & > * {
        z-index: 2;
    }
`

export function getPackageNumber(packageName: BasisPremiumType): number {
    if (packageName === BasisPremiumType.ULTIMATE) {
        return 3
    } else if (packageName === BasisPremiumType.PREMIUM) {
        return 2
    } else if (packageName === BasisPremiumType.STANDARD) {
        return 1
    } else if (packageName === BasisPremiumType.BASIC) {
        return 0
    } else {
        return -1
    }
}

export function getNextPackage(packageName: BasisPremiumType): BasisPremiumType {
    if (packageName === BasisPremiumType.ULTIMATE) {
        return BasisPremiumType.PREMIUM
    } else if (packageName === BasisPremiumType.PREMIUM) {
        return BasisPremiumType.STANDARD
    } else if (packageName === BasisPremiumType.STANDARD) {
        return BasisPremiumType.BASIC
    } else {
        return BasisPremiumType.NONE
    }
}
interface LobbyPageProps {
    eventKey: string
    refreshKey: number
    profileId: string
    onSetShowOrganizationModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetTarget: React.Dispatch<React.SetStateAction<SimpleOrganization | undefined>> // for organization
    onSetTargetId: React.Dispatch<React.SetStateAction<string>> // for other entity types
    onSetLink: React.Dispatch<React.SetStateAction<string>>
    onSetType: React.Dispatch<React.SetStateAction<ShareTargetType | undefined>>
    onSetAction: React.Dispatch<React.SetStateAction<ActionType | undefined>>
    onSetShowPrivacyPolicyModal: React.Dispatch<React.SetStateAction<boolean>>
    onSetShowGuestModal: React.Dispatch<React.SetStateAction<boolean>>
    isMobile: boolean
}

const LobbyPageContent: React.FunctionComponent<LobbyPageProps> = (props) => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const strings = languageState.getStrings()

    const userState = useLoggedInState()

    const [topSponsors, setTopSponsors] = useState<Exhibitor[]>()

    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])
    const [livestreams, setLiveStreams] = useState<ChannelResponse[]>([])
    const [persons, setPersons] = useState<Person[]>([])
    const [relevantUsers, setRelevantUsers] = useState<ContactItem[]>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const [nextPackage, setNextPackage] = useState<BasisPremiumType>(BasisPremiumType.NONE)

    const [meetingRoomGroups, setMeetingRoomGroups] = useState<MeetingRoomGroupType[]>([])
    const queryParams: any = queryString.parse(window.location.search)
    const componentOrder: string[] = branding.receptionPage.lobbyComponentOrder ?? [
        "SHOWFLOOR",
        "LOUNGES",
        "LIVE_SESSIONS",
        "SPEAKERS",
        "BANNER"
    ]

    const userAccessState = useUserRestrictedAreaAccess()

    const basisPremiumList = branding.receptionPage.lobbyExhibitorsBasisPremiumTypesList

    const loadPremiumOrStandardExhibitors = (basisPremium: BasisPremiumType, numResultRows?: number) => {
        const basisPremiumPackage: LobbyExhibitorsBasisPremiumType | undefined = basisPremiumList.find(
            (e: LobbyExhibitorsBasisPremiumType) => e.packageName === basisPremium
        )

        if (basisPremiumPackage as LobbyExhibitorsBasisPremiumType) {
            const params: any = {
                filterlist: "entity_orga",
                basispremium: getPackageNumber(basisPremium),
                startresultrow: 0,
                numresultrows: basisPremiumPackage?.numberOfExhibitors,
                order: "random",
                lang: lang
            }

            loadExhibitorsData(params, abortSignal, true).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                const exhibitorsTemp = exhibitors.filter((it) => !ex.some((e) => e.id === it.id))

                const respData = branding.receptionPage.lobbyExhibitorsShowTopSponsors
                    ? ex.filter((it) => !branding.receptionPage.lobbyTopSponsors.some((e) => e.id === it.id))
                    : ex

                setExhibitors(exhibitorsTemp.length === 0 ? respData : exhibitorsTemp.concat(respData))
                setNextPackage(getNextPackage(basisPremium))
            })
        } else {
            setNextPackage(getNextPackage(basisPremium))
            return
        }
    }

    useEffect(() => {
        const sponsoredExhibitorsMyPageList = branding.receptionPage.lobbyTopSponsors

        if (branding.receptionPage.lobbyExhibitorsShowTopSponsors && sponsoredExhibitorsMyPageList) {
            let sponsoredExhibitorsFilterList: string[] = []

            sponsoredExhibitorsMyPageList.forEach((sponsor) => {
                sponsoredExhibitorsFilterList.push("id_orga_" + sponsor.id)
            })

            let sponsorSlotParams: any = {
                filterlist: "entity_orga, " + sponsoredExhibitorsFilterList.join(", "),
                startresultrow: 0,
                order: "lexic",
                lang: lang
            }

            loadExhibitorsData(sponsorSlotParams, abortSignal).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                if (ex) {
                    ex.forEach((exhibitor: any) => {
                        exhibitor.order = sponsoredExhibitorsMyPageList.find((x) => x.id === exhibitor.id)?.order || -1
                    })

                    setTopSponsors((e) => orderBy(ex, ["order"], ["asc"]))
                }
            })
        }

        setIsLoaded(true)

        userAccessState.fetchUserAccessForAllVirtualCafes()
        setMeetingRoomGroups(strings.meetingRoomGroups)

        //eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        setNextPackage(BasisPremiumType.ULTIMATE)
    }, [isLoaded])

    useEffect(() => {
        if (nextPackage === BasisPremiumType.NONE) {
            return
        }
        loadPremiumOrStandardExhibitors(nextPackage)

        //eslint-disable-next-line
    }, [nextPackage])

    useEffect(() => {
        const speakerPersonFunctions = branding.configuration.speakerPersonFunctions.map((func) => `personfunc_${func}`)
        const eventDateParticipation = branding.configuration.eventDateParticipation ? "evtdpartcp_" : ""
        const defaultPersonParams: any = {
            filterlist: ["entity_pers", speakerPersonFunctions, eventDateParticipation],
            startresultrow: 0,
            numresultrows: 15,
            order: branding.programSpeakers.orderType
        }

        loadPersonsData(defaultPersonParams, abortSignal).then((response) => {
            setPersons(response.persons)
        })

        getChannels(branding.videoPageContentBranding.minutesBeforeSwitchingToTheNextEvent * 60 * 1000).then((resp) => {
            if ((resp as BackendServiceError).httpStatus) {
                // TODO ERROR
            } else {
                setLiveStreams(resp as ChannelResponse[])
            }
        })
        return () => {
            abortController.abort() // Cancel all loads on unmount // TODO verify
        }
        // eslint-disable-next-line
    }, [props.eventKey, lang, props.refreshKey])

    useEffect(() => {
        userState.isLoggedIn &&
            loadRelevantProfilesListData(props.profileId, {
                searchString: "",
                itemsPerPage: 25,
                page: 0
            }).then((data) => {
                let contactsTemp = (data as ContactListResponse).contacts?.filter(function (contact) {
                    return contact.sotUser.id !== userState.user()?.profileId
                })
                setRelevantUsers(contactsTemp)
            })

        // eslint-disable-next-line
    }, [props.profileId, lang])

    useEffect(() => {
        userAccessState.fetchUserAccessForAllEventDates()
    }, []) //eslint-disable-line

    return (
        <Fragment>
            {componentOrder.map((component, key) => {
                switch (component) {
                    case "SHOWFLOOR":
                        const allOrgas: Exhibitor[] = []
                        if (branding.receptionPage.lobbyExhibitorsShowTopSponsors && topSponsors) allOrgas.push(...topSponsors)
                        if (exhibitors) allOrgas.push(...exhibitors)

                        return (
                            <React.Fragment key={key}>
                                {allOrgas.length > 0 && <EntityCarousel type="organization" entities={allOrgas} />}
                            </React.Fragment>
                        )

                    case "LOUNGES":
                        return (
                            /* Virtual cafe */
                            <React.Fragment key={key}>
                                {meetingRoomGroups.length > 0 && (
                                    <TileRow
                                        icon={IconMeetings({
                                            fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                        })}
                                        iconVisible={true}
                                        title={strings.receptionPage.featuredCompanies}
                                        titleVisible={true}
                                        navLink={conferencePageRoute}
                                        navLinkTextVisible={true}
                                        hideShadows={props.isMobile}
                                        navLinkText={strings.receptionPage.virtualCaffeNavLinkText}
                                        height={props.isMobile ? "95px" : "220px"}
                                        scrollBar={strings.meetingRoomGroups && strings.meetingRoomGroups.length > 0}
                                        childWidth={props.isMobile ? 205 + 4 : 500 + 4} /* Basis + Grid Gap */
                                        childCount={meetingRoomGroups.length}
                                    >
                                        {meetingRoomGroups.map((meetingRoomGroup, index) => (
                                            <VizSensor key={index} partialVisibility={true}>
                                                {({ isVisible }) =>
                                                    isVisible ? (
                                                        <div style={{ marginRight: "0px" }}>
                                                            <MeetingRoomGroup
                                                                margin="0 20px 20px 0"
                                                                source="LOBBY"
                                                                meetingRoomGroupConfig={meetingRoomGroup}
                                                                roomType="virtualCafe"
                                                                isMobile={props.isMobile}
                                                                rerenderOnUnlockedChanged={userAccessState.isUnlocked(
                                                                    meetingRoomGroup
                                                                )}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: props.isMobile ? 205 : 500,
                                                                height: props.isMobile ? 95 : 280
                                                            }}
                                                        ></div>
                                                    )
                                                }
                                            </VizSensor>
                                        ))}
                                    </TileRow>
                                )}
                            </React.Fragment>
                        )
                    case "LIVE_SESSIONS":
                        return (
                            (isLivePhase || (isSoftOpeningPhase && getIamPartOf(queryParams) === "streamonboarding")) && (
                                /* IsLiveNow section */
                                <React.Fragment key={key}>
                                    {livestreams?.length! > 0 && (
                                        <TileRow
                                            icon={IconLiveStream({
                                                fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                            })}
                                            iconVisible={true}
                                            title={strings.receptionPage.liveSessions}
                                            titleVisible={true}
                                            navLink={programPageRoute}
                                            navLinkTextVisible={true}
                                            hideShadows={props.isMobile}
                                            navLinkText={strings.receptionPage.liveSessionsNavLinkText}
                                            scrollBar={livestreams && livestreams.length > 0}
                                            childWidth={(props.isMobile ? 135 : 480) + 10}
                                            childCount={livestreams?.length ?? 0}
                                        >
                                            {livestreams &&
                                                livestreams.map((livestream, index) => (
                                                    <VizSensor key={index} partialVisibility={true}>
                                                        {({ isVisible }) =>
                                                            isVisible ? (
                                                                <LiveEvent
                                                                    id={index.toString()}
                                                                    title={livestream.name ?? ""}
                                                                    thumbnailUrl={livestream.thumbnailUrl}
                                                                    isMobile={props.isMobile}
                                                                    channelId={livestream.channelId}
                                                                    channelIcon={IconLiveStream({ fill: "#fff" })}
                                                                    eventDate={livestream.currentEventDate}
                                                                    setShowGuestModal={props.onSetShowGuestModal}
                                                                    rerenderOnUnlockedChanged={userAccessState.isUnlocked(
                                                                        livestream.currentEventDate
                                                                    )}
                                                                />
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        width: props.isMobile ? 135 : 480,
                                                                        height: props.isMobile ? 120 : 280
                                                                    }}
                                                                ></div>
                                                            )
                                                        }
                                                    </VizSensor>
                                                ))}
                                        </TileRow>
                                    )}
                                </React.Fragment>
                            )
                        )
                    case "SPEAKERS":
                        /* Speakers */
                        return (
                            <React.Fragment key={key}>
                                {persons.length > 0 && <EntityCarousel type="person" entities={persons} />}
                            </React.Fragment>
                        )
                    case "PARTICIPANTS":
                        return (
                            /* Networking relevant contacts */
                            <React.Fragment key={key}>
                                {relevantUsers?.length! > 0 && (
                                    <TileRow
                                        key={key}
                                        icon={IconNetworking({
                                            fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                        })}
                                        iconVisible={true}
                                        title={strings.navigationArea.participantsItemTitle}
                                        titleVisible={true}
                                        navLink={relevantPeopleRoute}
                                        navLinkTextVisible={true}
                                        hideShadows={props.isMobile}
                                        navLinkText={strings.receptionPage.participantsNavLinkText}
                                        scrollBar={relevantUsers && relevantUsers.length > 0}
                                        childWidth={280 + 9}
                                        childCount={relevantUsers?.length ?? 0}
                                    >
                                        {relevantUsers && (
                                            <>
                                                {props.isMobile ? (
                                                    <MobileTileWrapperSpeakers $count={relevantUsers.length}>
                                                        {relevantUsers.map((person, index) => (
                                                            <VizSensor key={index} partialVisibility={true}>
                                                                {({ isVisible }) =>
                                                                    isVisible ? (
                                                                        <div style={{ width: 95, height: 95 }}>
                                                                            <PersonComponent
                                                                                userId={person.sotUser.id}
                                                                                person={person.sotUser.person}
                                                                                {...person.sotUser}
                                                                                setShowRecommendPersonModal={
                                                                                    props.onSetShowOrganizationModal
                                                                                }
                                                                                setTargetId={props.onSetTarget}
                                                                                setLink={props.onSetLink}
                                                                                setType={props.onSetType}
                                                                                type={"sotuser"}
                                                                            ></PersonComponent>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ width: 95, height: 95 }}></div>
                                                                    )
                                                                }
                                                            </VizSensor>
                                                        ))}
                                                    </MobileTileWrapperSpeakers>
                                                ) : (
                                                    <TileWrapperSpeakers $count={relevantUsers.length}>
                                                        {relevantUsers.map((person, index) => (
                                                            <VizSensor key={index} partialVisibility={true}>
                                                                {({ isVisible }) =>
                                                                    isVisible ? (
                                                                        <div style={{ width: 280, height: 280 }}>
                                                                            <PersonComponent
                                                                                userId={person.sotUser.id}
                                                                                {...person.sotUser}
                                                                                setShowRecommendPersonModal={
                                                                                    props.onSetShowOrganizationModal
                                                                                }
                                                                                setTargetId={props.onSetTarget}
                                                                                setLink={props.onSetLink}
                                                                                setType={props.onSetType}
                                                                                type={"sotuser"}
                                                                            ></PersonComponent>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ width: 280, height: 280 }}></div>
                                                                    )
                                                                }
                                                            </VizSensor>
                                                        ))}
                                                    </TileWrapperSpeakers>
                                                )}
                                            </>
                                        )}
                                    </TileRow>
                                )}
                            </React.Fragment>
                        )
                    case "BANNER": //this is for compatibility purposes, in branding you should use NETWORKING_BANNER or LEADFINDER_BANNER instead
                    case "NETWORKING_BANNER":
                        return (
                            <TileRow
                                key={key}
                                icon={IconNetworking({
                                    fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                })}
                                iconVisible={strings.receptionPage.tileRowIconVisible}
                                title={strings.receptionPage.networkingBannerTitle}
                                titleVisible={strings.receptionPage.tileRowTitleVisible}
                                navLink={
                                    networkingPageRoute +
                                    "?initialViewType=" +
                                    strings.networkingArea.initialViewTypeForLobbyNetworkingBanner
                                }
                                navLinkTextVisible={strings.receptionPage.tileRowNavLinkVisible}
                                hideShadows={props.isMobile}
                                navLinkText={strings.receptionPage.networkingNavLinkText}
                                childCount={1}
                                childWidth={0}
                                scrollBar={false}
                            >
                                <DynamicBanner
                                    isNetworkingBanner={true}
                                    navLink={
                                        networkingPageRoute +
                                        "?initialViewType=" +
                                        strings.networkingArea.initialViewTypeForLobbyNetworkingBanner
                                    }
                                    buttonText={strings.receptionPage.networkingButton}
                                    text1={strings.receptionPage.networkingText1}
                                    text2={strings.receptionPage.networkingText2}
                                />
                            </TileRow>
                        )

                    case "LEADFINDER_BANNER":
                        return (
                            <TileRow
                                key={key}
                                icon={IconBusinessArea({
                                    fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                })}
                                iconVisible={strings.receptionPage.tileRowLeadFinderIconVisible}
                                title={strings.receptionPage.leadFinderBannerTitle}
                                titleVisible={strings.receptionPage.tileRowLeadFinderTitleVisible}
                                navLink={businessAreaPageRoute}
                                navLinkTextVisible={strings.receptionPage.tileRowLeadFinderNavLinkVisible}
                                hideShadows={props.isMobile}
                                navLinkText={strings.receptionPage.leadfinderNavLinkText}
                                childCount={1}
                                childWidth={0}
                                scrollBar={false}
                            >
                                <DynamicBanner
                                    isLeadFinderBanner={true}
                                    navLink={businessAreaPageRoute}
                                    buttonText={strings.receptionPage.leadFinderButton}
                                    text1={strings.receptionPage.leadFinderText1}
                                    text2={strings.receptionPage.leadFinderText2}
                                />
                            </TileRow>
                        )

                    case "PROGRAM_BANNER":
                        return (
                            <TileRow
                                key={key}
                                icon={IconProgram({
                                    fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor,
                                    stroke: branding.sideIconBar.sideIconColorLight
                                })}
                                iconVisible={strings.receptionPage.tileRowProgramIconVisible}
                                title={strings.receptionPage.programBannerTitle}
                                titleVisible={strings.receptionPage.tileRowProgramTitleVisible}
                                navLink={programPageRoute}
                                navLinkTextVisible={strings.receptionPage.tileRowProgramNavLinkVisible}
                                hideShadows={props.isMobile}
                                navLinkText={strings.receptionPage.programNavLinkText}
                                childCount={1}
                                childWidth={0}
                                scrollBar={false}
                            >
                                <DynamicBanner
                                    isProgramBanner={true}
                                    navLink={programPageRoute}
                                    buttonText={strings.receptionPage.programButton}
                                    text1={strings.receptionPage.programText1}
                                    text2={strings.receptionPage.programText2}
                                />
                            </TileRow>
                        )
                    case "COLLECTIONS":
                        return (
                            <React.Fragment key={key}>
                                {strings.collectionBranding.collections && strings.collectionBranding.collections.length > 0 && (
                                    <TileRow
                                        icon={IconCollections({
                                            fill: branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor
                                        })}
                                        iconVisible={true}
                                        title={strings.receptionPage.collectionsBannerTitle}
                                        titleVisible={true}
                                        navLink={collectionOverviewRoute}
                                        navLinkTextVisible={strings.receptionPage.tileRowCollectionsNavLinkVisible}
                                        hideShadows={props.isMobile}
                                        navLinkText={strings.receptionPage.collectionsNavLinkText}
                                        height={props.isMobile ? "95px" : "220px"}
                                        scrollBar={
                                            strings.collectionBranding.collections &&
                                            strings.collectionBranding.collections.length > 0
                                        }
                                        childWidth={props.isMobile ? 205 + 4 : 500 + 4}
                                        childCount={strings.collectionBranding.collections.length}
                                    >
                                        {strings.collectionBranding.collections.map((collection, index) => (
                                            <Tile key={index} isLobbyComponent={true} collection={collection} />
                                        ))}
                                    </TileRow>
                                )}
                            </React.Fragment>
                        )
                    default:
                        return null
                }
            })}
        </Fragment>
    )
}

export default LobbyPageContent
