import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { Product } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import BadgeArea from "../../../ui/BadgeArea"
import BookmarkWithToggle from "../../../ui/BookmarkWithToggle"
import { InfoContentOrderType } from "../DetailPageBranding"
import { getSimpleCategories } from "./DetailPageSections"
import FocusImageMedia from "./FocusImageMedia"
import { filterMedia } from "./Media"

const ProductDetailInfoRoot = styled.div``

const ProductMedia = styled.div``

const ProductBasicInfo = styled.div``

const ReadMoreBtn = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
`

const ProductTeaser = styled.div`
    font-size: ${branding.organizationDetailPageContent.companyTeaserFontSize};
    font-weight: 400;
    white-space: pre-line;
`
interface ProductDetailInfoProps {
    product: Product | undefined
}

const MAX_DESCRIPTION_TEXT_LENGTH = 900
const ProductDetailInfo = (props: ProductDetailInfoProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const [productDescription, setProductDescription] = useState("")
    const [productImageInFocus, setProductImageInFocus] = useState("")
    const [showMoreBtnActive, setShowMoreBtnActive] = useState(false)
    const [showMoreBtnClicked, setShowMoreBtnClicked] = useState(false)

    useEffect(() => {
        if (props.product?.description?.text)
            props.product.description.text.length > MAX_DESCRIPTION_TEXT_LENGTH
                ? setShowMoreBtnActive(true)
                : setShowMoreBtnActive(false)
        else setShowMoreBtnActive(false)
    }, [props.product])

    useEffect(() => {
        setShowMoreBtnClicked(false)
    }, [lang])

    useEffect(() => {
        if (showMoreBtnClicked) {
            setProductDescription(props.product?.description.text || "")
        } else {
            let description = props.product?.description?.text?.slice(0, MAX_DESCRIPTION_TEXT_LENGTH)
            if (props.product?.description?.text && props.product.description.text?.length > MAX_DESCRIPTION_TEXT_LENGTH) {
                description += "..."
            }
            setProductDescription(description || "")
        }
        setProductImageInFocus(props.product?.pictureURL || "")
        // eslint-disable-next-line
    }, [showMoreBtnClicked, props.product])

    return (
        <ProductDetailInfoRoot>
            <Row>
                {props.product && (filterMedia(props.product.attachments, true).length > 0 || props.product?.pictureURL) && (
                    <Col xs={6}>
                        <ProductMedia>
                            <FocusImageMedia
                                attachments={filterMedia(props.product.attachments, true)}
                                imageInFocus={productImageInFocus}
                                organizationId={
                                    props.product.organizations.length ? props.product.organizations[0].id : undefined
                                }
                                organization={props.product.organizations.length ? props.product.organizations[0] : undefined}
                            />
                        </ProductMedia>
                    </Col>
                )}

                <Col
                    xs={
                        props.product && (filterMedia(props.product.attachments, true).length > 0 || props.product?.pictureURL)
                            ? 6
                            : 12
                    }
                >
                    <ProductBasicInfo>
                        <Col>
                            <Row>
                                <Col className="p-0" md={10}>
                                    <h3 style={{ fontSize: "22px", fontWeight: 800, marginBottom: "25px" }}>
                                        {props.product?.name}
                                    </h3>
                                </Col>
                                <Col className="p-0 text-right" md={2}>
                                    <BookmarkWithToggle
                                        newBookmarkItem={true}
                                        fontSize={"25px"}
                                        color={branding.sideIconBar.sideIconColorDark}
                                        favIconBasic={true}
                                        type="product"
                                        id={props.product?.id as string}
                                        name={props.product?.name as string}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <ProductTeaser>
                            {productDescription?.split("\\n")?.map((item, i) => (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            ))}
                        </ProductTeaser>
                        {showMoreBtnActive && (
                            <ReadMoreBtn onClick={() => setShowMoreBtnClicked(!showMoreBtnClicked)}>
                                {showMoreBtnClicked
                                    ? strings.productDetailPageContent.showLessText
                                    : strings.productDetailPageContent.showMoreText}
                            </ReadMoreBtn>
                        )}
                        <>
                            {branding.productDetailPageContent.infoContentOrder.includes(InfoContentOrderType.BADGES) && (
                                <BadgeArea
                                    categories={getSimpleCategories(
                                        props.product?.categories || [],
                                        branding.productDetailPageContent.showCategoryHierarchy
                                    )}
                                    marginTop="20px"
                                />
                            )}
                        </>
                    </ProductBasicInfo>
                </Col>
            </Row>
        </ProductDetailInfoRoot>
    )
}

export default ProductDetailInfo
