import styled from "styled-components"

interface TileLogoProps {
    url?: string
    alt: string
}
export function TileLogo(props: TileLogoProps) {
    const { url, alt } = props
    return <TileLogoRoot>{url ? <img src={url} alt={alt + " logo"} /> : <p>{alt.replace(/(.{80})..+/, "$1…")}</p>}</TileLogoRoot>;
}

const TileLogoRoot = styled.div`
    width: 70%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`
