import * as React from "react"
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import branding from '../branding/branding'

const closeIconTypes = {
    "edit-profile": '\u2715',
    "say-hello-modal": '\u2715',
    "calendar-entry-modal": '\u2715',
    "backoffice-staff-success": '\u2715'
}

const DateUpdateAlertRoot = styled(Modal) <{ color?: string, position: string, width?: number }>`
    width: ${props => props.width! > 50 ? "600px" : "400px"};
    top: ${props => props.position === 'top' ? 0 : '80%'};
    left: 50%;
    transform: translateX(-200px);
    align-items: center;
    justify-content: center;

    .modal-body{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0; 
    }

    .modal-content {
        padding: 0 20px; 
    }

    .control-label {
        color: ${props => props.color ?? branding.mainInfoColor};
        font-family: ${branding.font1};   
    }

    & .close{
        outline: 0;
        font-size: 30px;
        color: ${props => props.color ?? branding.mainInfoColor};
        width: 40px;
        background: none;
        border-style: none;
        opacity: 1;
        font-weight: lighter;
    }

    .close:not(:disabled):not(.disabled):focus,
    .close:not(:disabled):not(.disabled):hover{
        opacity: 1;
    }
`

const TitleImage = styled.div`
    font-size: 30px;
    margin-right: 20px;
`

const Title = styled.div<{ color?: string, titleLength?: number  }>`
    /* height: "${props => props.titleLength! > 30 ? "40px" : "20px"};" */
    height: 20px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: ${props => props.titleLength! > 50 ? "12px" : "15px"};
    white-space: normal;
    overflow: hidden;
    margin-top: 5px;
    font-family: ${branding.font1};
    color: ${props => props.color ?? branding.mainInfoColor};
`


interface DataUpdateAlertProps {
    close: () => void
    open?: () => void
    message: string
    show: boolean
    type: 'edit-profile' | 'say-hello-modal' | 'calendar-entry-modal' | 'backoffice-staff-success'
    titleImage: any
    color?: string
    timeoutSeconds?: number
    position: 'top' | 'bottom'
}


const DataUpdateAlert: React.FunctionComponent<DataUpdateAlertProps> = (props: DataUpdateAlertProps) => {
    const closeIcon = closeIconTypes[props.type]
    const timeout = (props.timeoutSeconds ?? 5) * 1000 //default timeout is 5 seconds

    //closing modal automatically after specified seconds
    setTimeout(() => {
        props.close()
    }, timeout)

    return (
        <DateUpdateAlertRoot delay={5000} show={props.show} animation={false} onHide={props.close} color={props.color} position={props.position} width={props.message.length}>
            <Modal.Body>
                {/* title image and message */}
                <div className="d-flex justify-content-start align-items-center">
                    <TitleImage>{props.titleImage}</TitleImage>
                    <Title titleLength={props.message.length}>{props.message}</Title>
                </div>

                {/* close button */}
                <div className="d-flex justify-content-end align-items-center">
                    <button type="button" className="close" onClick={() => props.close()} aria-label="Close" style={{fontSize: "18px", marginBottom: "15px", color: "#1D1D1B"}}>
                        <span aria-hidden="true">{closeIcon}</span>
                    </button>
                </div>
            </Modal.Body>
        </DateUpdateAlertRoot >
    )
}

export default DataUpdateAlert