import * as React from "react"
import styled from "styled-components"
import { Modal } from "react-bootstrap"
import branding from "../branding/branding"
import { IconClose, IconIndicatorErrorBlack } from "./Icons"

const ModalRoot = styled(Modal)`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    svg {
        color: ${branding.primaryColor};
    }

    .close {
        text-shadow: none;
        color: ${branding.mainInfoColor};
        margin: -1.8rem -1rem -1rem auto;
    }

    .modal-dialog {
        width: 300px;
        top: 80%;
        transform: translateY(-50%);
        left: -16%;
    }

    .modal-header-close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 26px;
        font-weight: 10;
        line-height: 1.66666667;
        color: ${branding.mainInfoColor};
        width: 30px;
        padding: 0 4px;
        background: none;
        border-style: none;
        margin-right: 0px;
        margin-top: -10px;
    }

    .modal-content {
        font-family: ${branding.font1};
        padding: 0 10px 0 10px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 15px 0 10px 0;
    }
    .modal-footer {
        justify-content: space-between;
        border: none;
        padding: 0px 8px;
    }

    .modal-body {
        padding: 5px 5px 25px 5px;
        font-size: 12px;
        line-height: 12px;
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 5px;
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 3px;
        color: ${branding.crsTabs.tabItemDefaultActiveStateColor};

        .injected-svg {
            width: 13px !important;
            height: 13px !important;
            margin-bottom: 3px !important;
        }
    }

    .btn-primary {
        font-family: ${branding.font1};
        width: 30%;
        display: inline;
        background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor ?? "#000"};
        color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
        /* border: 1px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
        border-radius: 5px; */

        :hover {
            background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important;
            color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;
        }
    }

    & .btn-primary:focus {
        outline: none !important;
    }

    .btn-secondary {
        display: inline;
        width: 30%;
        font-family: ${branding.font1};
        color: ${branding.sayHelloModal.submitBtnSecondaryTextColor ?? "#000"} !important;
        background-color: ${branding.sayHelloModal.submitBtnSecondaryBgColor ?? "#fff"};
        border: none;
        border-color: ${branding.sayHelloModal.submitBtnSecondaryBorderColor ?? "#fff"};
        border-radius: none;
    }

    & .btn-secondary:focus {
        outline: none !important;
    }
    .subtitleText {
        font-family: ${branding.font1};
        display: absolute;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        margin: 10px 15px 25px 15px;
    }

    .headerImage {
        margin-top: 5px;
        margin-left: 15px;
    }

    .customString {
        font-weight: bold;
    }

    .messageBox {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* margin-left: 8px; */
        font-family: ${branding.font1};
    }
`

interface DeviceFailureAlertProps {
    close: () => void

    title: string
    message: string
}

const DeviceFailureAlert: React.FunctionComponent<DeviceFailureAlertProps> = (props: DeviceFailureAlertProps) => {
    return (
        <ModalRoot backdrop="static" show={true} animation={false}>
            <div onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h3 className="modal-title">
                        {IconIndicatorErrorBlack({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })} {props.title}
                    </h3>
                    <button type="button" className="close" onClick={() => props.close()} aria-label="Close">
                        {IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "10", height: "10" })}
                    </button>
                </div>

                <div className="modal-body">{props.message}</div>
            </div>
        </ModalRoot>
    )
}

export default DeviceFailureAlert
