/* tslint:disable */
/* eslint-disable */
// this are specific queries

/*
 * created by MGust
 */
export const getPresenceByUser = /* GraphQL */ `
    query GetPresenceByUser($id: ID!) {
        getUser(id: $id) {
            id
            presenceStatus
            lastConnected
        }
    }
`

export const doesMeetingExist = /* GraphQL */ `
    query GetMeetingLight($id: ID!) {
        getMeeting(id: $id) {
            id
        }
    }
`

export const listMeetings = /* GraphQL */ `
    query GetMeetings($id: ID!, $limit: Int) {
        getUser(id: $id) {
            outgoingMeetings(limit: $limit, sortDirection: DESC) {
                items {
                    id
                    invitee {
                        id
                        name
                        pictureUrl
                    }
                    meeting {
                        id
                        start
                        end
                        participants(limit: 5) {
                            items {
                                invitee {
                                    id
                                    name
                                    pictureUrl
                                }
                                inviter {
                                    id
                                    name
                                    pictureUrl
                                }
                            }
                            nextToken
                        }
                    }
                    status
                    created
                }
                nextToken
            }
            incomingMeetings(limit: $limit, sortDirection: DESC) {
                items {
                    id
                    inviter {
                        id
                        name
                        pictureUrl
                    }
                    meeting {
                        id
                        start
                        end
                        participants(limit: 5) {
                            items {
                                invitee {
                                    id
                                    name
                                    pictureUrl
                                }
                                inviter {
                                    id
                                    name
                                    pictureUrl
                                }
                            }
                            nextToken
                        }
                    }
                    status
                    created
                }
                nextToken
            }
        }
    }
`

export const conversationsByMembers = /* GraphQL */ `
    query GetConversationsByMembersLight($memberId1: ID!, $memberId2: ID!) {
        getConversationsByMembers(memberId1: $memberId1, memberId2: $memberId2, limit: 5) {
            items {
                id
                members(limit: 2) {
                    items {
                        id
                        userId
                        isMuted
                    }
                }
            }
        }
    }
`

export const userConversationByUserAndConversation = /* GraphQL */ `
    query UserConversationsByUserAndConversationLight($userId: ID!, $conversationId: ID!) {
        userConversationsByUserAndConversation(userId: $userId, conversationId: { eq: $conversationId }, limit: 1) {
            items {
                id
                conversation {
                    id
                    name
                }
                isMuted
            }
        }
    }
`

export const messagesByConversationId = /* GraphQL */ `
    query MessagesByConversationIdLight($conversationId: ID!, $limit: Int, $nextToken: String) {
        messagesByConversationId(conversationId: $conversationId, sortDirection: DESC, limit: $limit, nextToken: $nextToken) {
            items {
                id
                authorId
                content
                createdAt
            }
            nextToken
        }
    }
`

export const getUserConversations = /* GraphQL */ `
    query GetConversations($userId: ID!, $callLimit: Int, $memberLimit: Int, $nextToken: String) {
        userConversationsByUser(userId: $userId, sortDirection: DESC, limit: $callLimit, nextToken: $nextToken) {
            items {
                id
                isMuted
                conversation {
                    id
                    type
                    name
                    userId
                    mostRecentMessage
                    members(limit: $memberLimit) {
                        items {
                            id
                            userId
                            user {
                                id
                                name
                                pictureUrl
                            }
                        }
                    }
                }
                mostRecentMessageCreatedAt
                lastReadMessageCreatedAt
            }
            nextToken
        }
    }
`

export const getUserConversationLight = /* GraphQL */ `
    query GetUserConversationLight($id: ID!) {
        getUserConversation(id: $id) {
            id
            userId
            conversationId
            isMuted
            isChatOpen
            mostRecentMessageCreatedAt
            lastReadMessageCreatedAt
            createdAt
            updatedAt
        }
    }
`

export const getUserConversationListEntry = /* GraphQL */ `
    query UserConversationListEntry($userId: ID!, $conversationId: ID!, $memberLimit: Int) {
        userConversationsByUserAndConversation(userId: $userId, conversationId: { eq: $conversationId }, limit: 1) {
            items {
                id
                isMuted
                mostRecentMessageCreatedAt
                lastReadMessageCreatedAt
                conversation {
                    id
                    type
                    name
                    mostRecentMessage
                    members(limit: $memberLimit) {
                        items {
                            id
                            userId
                            user {
                                id
                                name
                                pictureUrl
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`

export const conversationById = /* GraphQL */ `
    query GetConversationById($id: ID!, $limit: Int) {
        getConversation(id: $id) {
            id
            name
            description
            members(limit: $limit) {
                items {
                    id
                    isMuted
                    user {
                        id
                        name
                        pictureUrl
                    }
                }
            }
        }
    }
`

export const conversationNameAndTypeById = /* GraphQL */ `
    query GetConversationNameAndTypeById($id: ID!) {
        getConversation(id: $id) {
            name
            type
        }
    }
`

export const conversationParticipationIdById = /* GraphQL */ `
    query GetConversationParticipationById($id: ID!, $membersLimit: Int) {
        getConversation(id: $id) {
            members(limit: $membersLimit) {
                items {
                    userId
                }
            }
        }
    }
`

export const userNameAndPicture = /* GraphQL */ `
    query GetUserNameAndPicture($id: ID!) {
        getUser(id: $id) {
            name
            pictureUrl
        }
    }
`

/*********************************************************************************************
 * CALENDAR
 **********************************************************************************************/

export const getCalendarEntryParticipations = /* GraphQL */ `
    query CalendarEntryParticipationByUserAndStatusLight(
        $userIdStatus: String!
        $start: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCalendarEntryParticipationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        calendarEntryParticipationByUserAndStatusSorted(
            userIdStatus: $userIdStatus
            start: $start
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                calendarEntryId
                calendarEntry {
                    id
                    title
                    description
                    start
                    end
                    organizationId
                    userId
                    user {
                        id
                        name
                        pictureUrl
                    }
                    participants {
                        items {
                            id
                            status
                            userId
                            user {
                                id
                                name
                                pictureUrl
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`

export const getOnlineUsers = /* GraphQL */ `
    query getOnlineUsers($id: ID!) {
        getCurrentLocationCounts(id: $id) {
            id
            cnt
        }
    }
`

export const usersInCallsInLounge = /* GraphQL */ `
    query usersInCallsInLounge(
        $actionType: UserActionType
        $duration: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserActionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        byActionType(
            actionType: $actionType
            duration: $duration
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                param
                user {
                    id
                    name
                    pictureUrl
                }
            }
        }
    }
`

export const pageVisits = /* GraphQL */ `
    query pageVisits(
        $param: String
        $startTime: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserActionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userActionsByRoom(
            param: $param
            startTime: $startTime
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                startTime
                duration
                endTime
                source
            }
            nextToken
        }
    }
`

export const getCalendarEntryLight = /* GraphQL */ `
    query GetCalendarEntryLight($id: ID!, $limit: Int) {
        getCalendarEntry(id: $id) {
            id
            title
            description
            start
            end
            status
            userId
            user {
                id
                name
                pictureUrl
            }
            participants(limit: $limit) {
                items {
                    id
                    status
                    userId
                    user {
                        id
                        name
                        pictureUrl
                    }
                }
            }
        }
    }
`

export const listCalendarEntryByOrganization = /* GraphQL */ `
    query ListCalendarEntryByOrganization(
        $organizationIdStatus: String
        $start: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCalendarEntryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        calendarEntryByOrganizationAndStatusSorted(
            organizationIdStatus: $organizationIdStatus
            start: $start
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                title
                description
                start
                end
                userId
                organizationId
                user {
                    id
                    presenceStatus
                    name
                    pictureUrl
                }
                participants {
                    items {
                        id
                        status
                        userId
                        user {
                            id
                            name
                            pictureUrl
                        }
                    }
                }
            }
            nextToken
        }
    }
`

export const getUserLight = /* GraphQL */ `
    query GetUserLight($id: ID!) {
        getUser(id: $id) {
            id
            presenceStatus
            lastConnected
            name
            pictureUrl
            createdAt
            updatedAt
        }
    }
`
export const batchGetUserPresenceLight = /* GraphQL */ `
    query BatchGetUserPresenceLight($listIds: [String]) {
        batchGetUserPresence(listIds: $listIds) {
            id
            presenceStatus
            lastConnected
        }
    }
`
