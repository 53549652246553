import { Col, Row } from "react-bootstrap"
import { ActionMeta, OptionTypeBase, ValueType } from "react-select"
import branding from "../../../../branding/branding"
import { useLanguageState } from "../../../../globalStates/LanguageState"
import { IconCamera } from "../../../../ui/Icons"
import { useDevices } from "../../../hooks/useDevices"
import PreviewVideo from "../../PreviewVideo"
import { SelectThemeCustom, SettingsContentContainer, StyledSelect } from "../Settings"

export function VideoSettings() {
    const deviceSwitcherState = useDevices()
    const strings = useLanguageState().getStrings().audioVideoSettings

    return (
        <SettingsContentContainer>
            <Row className={"mb-2"}>
                <Col md={1}></Col>
                <Col md={11}>
                    <PreviewVideo />
                </Col>
            </Row>

            <Row>
                <Col md={1}>{IconCamera({ fill: branding.sideIconBar.sideIconColorDark })}</Col>

                <Col md={11}>
                    <StyledSelect
                        placeholder={strings.videoInputLabel}
                        isMulti={false}
                        isSearchable={false}
                        isClearable={false}
                        options={deviceSwitcherState.videoInputDevices()}
                        value={deviceSwitcherState.currentVideoInputDevice()}
                        onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                            if (value !== null && action.action === "select-option") {
                                const option = value as OptionTypeBase
                                deviceSwitcherState.setVideoInputDevice(option.deviceId, option.label)
                            }
                        }}
                        theme={SelectThemeCustom}
                    />
                </Col>
            </Row>
        </SettingsContentContainer>
    )
}
