import branding from "../branding/branding"

export enum PagesUsingTileView {
    SHOWFLOOR = "SHOWFLOOR",
    SCHEDULE = "SCHEDULE",
    BUSSINES_AREA = "BUSSINES_AREA"
}

export const getTileViewMinReqWidth = (page: PagesUsingTileView): number => {
    let defaultValue = 1350
    let config = branding.tileViewConfig
    let pageConfig = config.find((x) => x.page === page)
    if (pageConfig) {
        return pageConfig.minRequiredWidth
    } else {
        return defaultValue
    }
}
